import React from 'react';
import { Route } from 'react-router-dom';
import Codelists from './Codelists';
import Brands from './Brands';
import Brands2 from './Brands2';
import ProductsGroups from './ProductsGroups';
import Categories from './Categories';
import ShadeCategories from './ShadeCategories';
import Users from './Users';
import InnovatintImport from './InnovatintImport';
import ProductTypes from './ProductTypes';
import CustomerTypes from './CustomerTypes';
import News from './News';
import Blogs from './Blogs';
import NewsLetter from './NewsLetter';
import OrderSetting from './OrderSettings';
import Bonuses from './Bonuses';
import ReportTasks from './ReportTasks';
import PriceLists from './PriceLists';
import FileViewer from '../../Containers/PDFFileViewer';
import Transaltions from './Translations';
import PurchasePrices from './PurchasePrices';
import CashRegisters from './CashRegisters';
import Stocks from './Stocks';
import warehouseSoftwares from './WarehouseSoftware';
import FavoritProducts from './FavoritProducts';
import EmailTemplates from './EmailTemplates';
import PresetEmailTemplates from './PresetEmailTemplates';
import HelpdeskSettings from './HelpdeskSettings';
import TranslationsEntitiesCard from './TranslationsEntities';
import SecurityAlerts from './SecurityAlerts';
import Employers from './Employers';
import CompanyInfoTeamMembers from '../../Containers/CompanyInfoTeamMembers';
import PDFTemplates from './PDFTemplates';
import CRMNotifications from './CRMNotifications';
import CRMChatBotMessages from './CRMChatBotMessages';

function Settings(props) {
  // eslint-disable-next-line react/prop-types
  const { match } = props;
  return (
    <React.Fragment>
      <Route path={`${match.url}/pricelists`} component={PriceLists} />
      <Route path={`${match.url}/codelists`} component={Codelists} />
      <Route path={`${match.url}/brands`} component={Brands} />
      <Route path={`${match.url}/brands2`} component={Brands2} />
      <Route path={`${match.url}/products-groups`} component={ProductsGroups} />
      <Route path={`${match.url}/categories`} component={Categories} />
      <Route
        path={`${match.url}/shade-categories`}
        component={ShadeCategories}
      />
      <Route path={`${match.url}/productTypes`} component={ProductTypes} />
      <Route path={`${match.url}/customerTypes`} component={CustomerTypes} />
      <Route path={`${match.url}/users`} component={Users} />
      <Route
        path={`${match.url}/innovatint-import`}
        component={InnovatintImport}
      />
      <Route path={`${match.url}/bonuses-settings`} component={Bonuses} />
      <Route path={`${match.url}/report-tasks`} component={ReportTasks} />
      <Route
        path={`${match.url}/translations-entities`}
        component={TranslationsEntitiesCard}
      />
      <Route path={`${match.url}/translations`} component={Transaltions} />
      <Route path={`${match.url}/order-settings`} component={OrderSetting} />
      <Route path={`${match.url}/news`} component={News} />
      <Route path={`${match.url}/blogs`} component={Blogs} />
      <Route
        path={`${match.url}/favorit-products`}
        component={FavoritProducts}
      />
      <Route path={`${match.url}/newsletter`} component={NewsLetter} />
      <Route
        path={`${match.url}/preset-email-templates`}
        component={PresetEmailTemplates}
      />
      <Route path={`${match.url}/email-templates`} component={EmailTemplates} />
      <Route path={`${match.url}/pdf-templates`} component={PDFTemplates} />
      <Route path={`${match.url}/helpdesk`} component={HelpdeskSettings} />
      <Route path={`${match.url}/purchase-prices`} component={PurchasePrices} />
      <Route
        path={`${match.url}/settings/cash-registers`}
        component={CashRegisters}
      />
      <Route path={`${match.url}/stocks`} component={Stocks} />
      <Route
        path={`${match.url}/warehouse-softwares`}
        component={warehouseSoftwares}
      />

      <Route
        path={`${match.url}/fileviewer`}
        render={({ location }) => <FileViewer location={location} />}
      />
      <Route path={`${match.url}/security-alerts`} component={SecurityAlerts} />
      <Route path={`${match.url}/employers`} component={Employers} />
      <Route
        path={`${match.url}/company-info`}
        component={CompanyInfoTeamMembers}
      />
      <Route
        path={`${match.url}/crm-notifications`}
        component={CRMNotifications}
      />
      <Route
        path={`${match.url}/chatbot-messages`}
        component={CRMChatBotMessages}
      />
    </React.Fragment>
  );
}

export default Settings;
