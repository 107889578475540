import { findInRecents, __ } from './index';
import { recents } from '../ContentConfig/recents';
import { actions } from '../Store';
import API2 from '../API2';

export default async (title = null, url = window.location.pathname) => {
  try {
    let titleName = title;
    if (!title) {
      const recentName = findInRecents(url, recents);
      titleName = recentName ? recentName.name : '-';
    }
    const serverdata = {
      type: 'CRM',
      title: titleName,
      url,
    };
    await API2.postUserRecentAction({
      body: serverdata,
    });

    actions.addHistory(serverdata);
  } catch (e) {
    throw new Error(__('Stránku sa nepodarilo uložiť do histórie.'));
  }
};
