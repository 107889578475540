import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button, Message, Label, Loader, Checkbox } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import {
  getIdFromProps,
  __,
  formatDate,
  FORMAT_HUMANE_DATETIME,
  updateTranslation,
  translate,
  getPageIdFromProps,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ArticleEdit from '../../Components/ArticleEdit';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
  GrayHeader,
} from '../../Components/ReusableComponents';

import AdminAPI from '../../AdminAPI';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import ArticleCard from '../../Components/Article/ArticleCard';
import Article from '../../Components/Article';
import VideoUpLoader from '../../Components/VideoUpLoader';
import { LANGS } from '../../ContentConfig/languagesArray';
import NewsletterEditor from '../../Components/Editor/newsletterEditor';
import API2 from '../../API2';

const Wrapper = styled.div`
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: start;
  padding-left: ${rem(20)};
`;

const Space = styled.div`
  width: ${rem(20)};
`;

const SaveWrapper = styled.div`
  padding: ${rem(5)} ${rem(20)} ${rem(5)} ${rem(20)};
`;
const SettingsInfo = styled.div`
  display: flex;
  margin-bottom: ${rem(15)};
  font-weight: bold;
  margin-left: ${rem(4)};
`;
const TextInfo = styled.div`
  display: flex;
  margin-left: ${rem(4)};
  font-weight: 200;
`;
const CheckBoxesWrapper = styled.div`
  margin-left: ${rem(15)};
`;
const ButtonWrapper = styled.div`
  display: flex;
  float: right;
  margin-bottom: ${rem(30)};
`;

const TextEditorWrapper = styled.div`
  //max-width: ${rem(1400)};
  width: 100%;
`;

const MainWrapper = styled.div`
  padding-bottom: ${rem(100)};
`;

// const videoBasePath = `${process.env.REACT_APP_DOMAIN}/public/file`;

const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/news/{newsId}/attachments?lang={lang}`;

class NewsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.pageId = getPageIdFromProps(this.props) || this.props.type;

    this.state = {
      saveLoading: false,
      copyLoading: false,
      shouldUpdate: false,
      selectedLang: 'SK',
      showConfirmDialog: false,
      showSaveDialog: false,
      loading: true,
      showReview: false,
      article: {
        name: {
          value: '',
          translations: [],
        },
        perex: {
          value: '',
          translations: [],
        },
        owner: {
          _id: this.props.user._id,
          display_name: this.props.user.display_name,
        },
        text: {
          value: '',
          translations: [],
        },
        json_data: {
          value: '',
          translations: [],
        },
        html_data: {
          value: '',
          translations: [],
        },
        meta_title: {
          value: '',
          translations: [],
        },
        meta_description: {
          value: '',
          translations: [],
        },
        published: [],
        priority: 1,
        published_from: null,
        published_to: null,
        thumbnail: {},
        video_label: false,
        type: this.pageId,
        attachments: [],
      },
      newArticleId: null,
    };
  }

  async componentWillMount() {
    const id = getIdFromProps(this.props);
    if (id !== 'new') {
      await this.fetchData(id);
    } else {
      this.setState({ loading: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.shouldUpdate !== this.state.shouldUpdate) {
      return false;
    }

    return true;
  }
  getUploadParams = ({ file }) => {
    const { selectedLang } = this.state;

    const headers = AdminAPI.appendAuthHeaders();
    const formData = new FormData();
    formData.append('file', file);

    return {
      url: fileUploadURL
        .replace('{newsId}', getIdFromProps(this.props))
        .replace('{lang}', selectedLang),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };
  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 10,
      }).then((res) => {
        return res.users.map((item) => ({
          value: item._id,
          label: item.display_name,
        }));
      });
    } catch (e) {
      return [];
    }
  }
  async fetchData(id) {
    try {
      const article = await AdminAPI.getNewsAction(id);
      article.published_from =
        article.published_from && moment(article.published_from);
      article.published_to =
        article.published_to && moment(article.published_to);

      this.setState({
        article,
        loading: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie článku nemáte potrebné práva.'),
            loading: false,
          });
          break;
        case 404:
          this.setState({
            error: __('Článok systém sa nenašiel'),
            loading: false,
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
            loading: false,
          });
          break;
      }
    }
  }

  async saveData() {
    const { article } = this.state;
    const id = getIdFromProps(this.props);
    try {
      // article.published_from =
      //   article.published_from && new Date(article.published_from);
      // article.published_to =
      //   article.published_to && new Date(article.published_to);

      article.videos = article.videos
        ? article.videos.filter((v) => v.link && v.link.length > 1)
        : [];

      const params = {};
      params.body = article;
      params.body.owner_id = article.owner._id;
      params.body.type = this.pageId;
      if (id === 'new') {
        const newArticle = await AdminAPI.postNewsAction(params);
        newArticle.published_from =
          newArticle.published_from && moment(article.published_from);
        newArticle.published_to =
          newArticle.published_to && moment(article.published_to);
        this.setState(
          {
            article: newArticle,
          },
          () => {
            this.props.history.push(
              `/${this.props.firm}/news/${this.pageId}/${newArticle._id}`,
            );
          },
        );
      } else {
        const newArticle = await AdminAPI.putNewsAction(
          getIdFromProps(this.props),
          params,
        );
        newArticle.published_from =
          newArticle.published_from && moment(newArticle.published_from);
        newArticle.published_to =
          newArticle.published_to && moment(newArticle.published_to);
        this.setState({
          article: newArticle,
        });
      }
      this.setState({
        success: __('Dáta úspešne uložené'),
      });

      setTimeout(() => {
        this.setState({ success: false });
      }, 1500);
    } catch (e) {
      this.setState({
        error: __('Pri ukladaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        saveLoading: false,
        showSaveDialog: false,
      });
    }
  }
  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    const { newsletterInfo } = this.state;

    if (status === 'preparing') {
      window.addEventListener('beforeunload', this.handler);
    }

    if (status === 'done') {
      window.removeEventListener('beforeunload', this.handler);

      const res = await AdminAPI.getNewsAction(getIdFromProps(this.props));

      newsletterInfo.attachments = res.attachments;

      this.setState(
        {
          newsletterInfo,
        },
        () => remove(),
      );
    }
  };
  async deleteNews() {
    this.setState({
      deleteLoading: true,
    });
    try {
      await AdminAPI.deleteNewsAction(getIdFromProps(this.props));
      this.setState({
        showConfirmDialog: false,
      });
      this.props.history.push(`/${this.props.firm}/news/${this.pageId}`);
    } catch (e) {
      this.setState({
        deleteError: __('Pri mazaní dát sa vyskytla chyba'),
      });
    } finally {
      this.setState({
        deleteLoading: false,
      });
    }
  }
  async deleteFile(fullPath) {
    const { article } = this.state;

    try {
      const result = await AdminAPI.deleteNewsAttachmentAction(
        getIdFromProps(this.props),
        encodeURIComponent(fullPath),
      );

      article.attachments = result.files || [];

      this.setState({
        article,
      });
    } catch (e) {
      this.setState({
        error: __('Prílohu sa nepodarilo odstrániť'),
      });
    }
  }

  async downloadFile(item) {
    return API2.getNewsAttachmentAction(
      getIdFromProps(this.props),
      item.fullPath,
    );
  }

  async uploadImage(image) {
    this.setState({
      tittleImageLoading: true,
    });
    const { article } = this.state;
    try {
      if (getIdFromProps(this.props) === 'new') {
        await this.saveData();
      }
      if (image) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = async () => {
          const newFile = {
            name: image.name,
            mimeType: image.type,
            base64: reader.result.replace('data:application/pdf;base64,', ''),
          };
          const params = {};
          params.body = newFile;
          const newfile = await AdminAPI.postTitleImageAction(
            getIdFromProps(this.props),
            params,
          );

          article.thumbnail = newfile;

          this.setState({
            article,
            tittleImageLoading: false,
          });
        };

        reader.onerror = (error) => {
          this.setState({
            error: __('Pri odosielaní dát nastala chyba.'),
          });
        };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async uploadImages(images) {
    const { article } = this.state;
    try {
      if (getIdFromProps(this.props) === 'new') {
        await this.saveData();
      }
      if (images) {
        images.map(async (i) => {
          const reader = new FileReader();
          reader.readAsDataURL(i);
          reader.onload = async () => {
            const newFile = {
              name: i.name,
              mimeType: i.type,
              base64: reader.result.replace('data:application/pdf;base64,', ''),
            };
            const params = {};
            params.body = newFile;
            const data = await AdminAPI.postArticleImageAction(
              article._id,
              params,
            );
            if (!article.images) {
              article.images = [];
            }
            article.images.push(data);
            this.setState({ article });
          };
          reader.onerror = (error) => {
            this.setState({
              error: __('Pri odosielaní dát nastala chyba.'),
            });
          };
          return i;
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async deleteImage(path) {
    const { article } = this.state;
    try {
      await AdminAPI.deleteNewsArticleImageAction(
        article._id,
        encodeURIComponent(path),
      );
      article.images = article.images.filter((i) => i.fullPath !== path);
      this.setState({ article });
    } catch (e) {
      this.setState({
        error: __('Pri mazaní dát nastala chyba.'),
      });
    }
  }

  updatePublished(lang) {
    const { article } = this.state;
    this.setState({
      error: false,
    });
    if (article.published.indexOf(lang) >= 0) {
      article.published.splice(article.published.indexOf(lang), 1);
    } else {
      article.published.push(lang);
    }
    this.setState({
      article,
    });
  }

  // updatePublishedEshop(lang) {
  //   const { article } = this.state;
  //   this.setState({
  //     error: false,
  //   });
  //   if (article.published_eshop.indexOf(lang) >= 0) {
  //     article.published_eshop.splice(article.published_eshop.indexOf(lang), 1);
  //   } else {
  //     article.published_eshop.push(lang);
  //   }
  //   this.setState({
  //     article,
  //   });
  // }

  handleTextChange(text) {
    const { article, selectedLang } = this.state;

    article.text = updateTranslation(article.text, selectedLang, text);

    this.setState({
      article,
      shouldUpdate: !this.state.shouldUpdate,
    });
  }

  handleSaveEditor(jsonData, htmlData) {
    const { article, selectedLang } = this.state;

    article.json_data = updateTranslation(
      article.json_data,
      selectedLang,
      JSON.stringify(jsonData),
    );
    article.html_data = updateTranslation(
      article.html_data,
      selectedLang,
      htmlData,
    );

    this.setState({
      article,
      showSaveDialog: true,
      // shouldUpdate: !this.state.shouldUpdate,
    });
  }

  addNewLink(lang) {
    const { article } = this.state;
    if (!article.videos) {
      article.videos = [];
    }
    article.videos.push({
      lang,
      link: '',
    });
    this.setState({ article });
  }

  handleVideoChange(e, index, selectedLang) {
    const { article } = this.state;
    article.videos.filter((v) => v.lang === selectedLang)[index].link =
      e.target.value;
    this.setState({ article });
  }
  async handleChangeTypeOnClick(type) {
    const { article } = this.state;

    this.setState({
      changeTypeLoading: true,
    });
    let newType = 'news';
    if (type === undefined || type !== 'blogs') {
      newType = 'blogs';
    }
    const params = {};
    params.body = { type: newType };
    try {
      const result = await AdminAPI.putArticleTypeAction(article._id, params);

      article.type = result.type;

      this.setState({
        article,
        changeTypeLoading: false,
        success: __('Článok sa podarilo úspešne presunúť.'),
      });
    } catch (e) {
      this.setState({
        error: __('Typ článku sa nepodarilo zmeniť'),
        changeTypeLoading: false,
      });
    }
  }

  async deleteVideo(index, selectedLang) {
    const { article } = this.state;
    try {
      await API2.deleteNewsVideoAction(
        encodeURIComponent(
          article.videos.filter((v) => v.lang === selectedLang)[index].link,
        ),
        article._id,
      );
      article.videos.splice(index, 1);
      this.setState({ article });
    } catch (e) {
      this.setState({
        error: __('Pri mazaní dát nastala chyba.'),
      });
    }
  }

  // async uploadVideo(videos, index, selectedLang) {
  //   const { article } = this.state;
  //   if (getIdFromProps(this.props) === 'new') {
  //     await this.saveData();
  //   }
  //   this.setState({
  //     uploadLoading: index,
  //   });
  //   try {
  //     const postedFiles = await Promise.all(
  //       videos.map((item) => {
  //         return API2.postNewsVideoAction(getIdFromProps(this.props), {
  //           video: item,
  //         });
  //       }),
  //     );
  //     postedFiles.forEach((i) => {
  //       article.videos[index] = `${videoBasePath}/${encodeURIComponent(
  //         i.fullPath,
  //       )}`;
  //     });
  //     this.setState({
  //       article,
  //       uploadLoading: -1,
  //     });
  //   } catch (e) {
  //     switch (e.response.status) {
  //       case 403:
  //         this.setState({
  //           error: __('Nemáte potrebné práva pre pridávanie dokumentov'),
  //         });
  //         break;
  //       case 404:
  //         this.setState({
  //           error: __('Služba sa nenašla.'),
  //         });
  //         break;
  //       default:
  //         this.setState({
  //           error: __('Pri odosielaní dát nastala chyba.'),
  //         });
  //     }
  //   }
  //   return false;
  // }

  selectLangImage(lang, path) {
    const { article } = this.state;

    if (!article.images.find((i) => i.fullPath === path).firms) {
      article.images.find((i) => i.fullPath === path).firms = [];
    }

    if (
      article.images.find((i) => i.fullPath === path).firms.indexOf(lang) > -1
    ) {
      article.images
        .find((i) => i.fullPath === path)
        .firms.splice(
          article.images.find((i) => i.fullPath === path).firms.indexOf(lang),
          1,
        );
    } else {
      article.images.find((i) => i.fullPath === path).firms.push(lang);
    }

    this.setState({
      article,
    });
  }

  async createNewsCopy(id) {
    this.setState({
      copyLoading: true,
    });

    const result = await AdminAPI.postNewsCopyAction(id);
    try {
      this.setState({
        copyLoading: false,
        newArticleId: result._id,
      });
    } catch (e) {
      this.setState({
        error: __('Kópiu článku sa nepodarilo vytvoriť'),
      });
    }
  }

  redirectToCopy(id) {
    this.props.history.push(`/${this.props.firm}/news/${this.pageId}/${id}`);
    window.location.reload();
  }

  getTitle(type) {
    switch (type) {
      case 'news':
        return __('Nastavenie portálového článku');
      case 'blogs':
        return __('Nastavenie blogového článku');
      case 'news-dejmark':
        return __('Nastavenie Dejmark článku');
      default:
        return __('Nastavenie portálového článku');
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { article, copyLoading, changeTypeLoading } = this.state;
    return (
      <ControllBar history={history} name={article.name.value || 'Nový'}>
        {article._id !== 'new' && (
          <ControlBarButton
            small
            primary
            showIcon={false}
            loading={copyLoading}
            onClick={() => this.createNewsCopy(article._id)}
            icon="plus"
          >
            {__('Vytvoriť kópiu')}
          </ControlBarButton>
        )}
        <ControlBarButton
          primary
          small
          loading={changeTypeLoading}
          onClick={() => this.handleChangeTypeOnClick(article.type)}
          icon="edit"
        >
          {article.type === undefined || article.type === 'news'
            ? __('Presunúť do blogových článkov')
            : __('Presunúť do Noviniek Portál')}
        </ControlBarButton>
        <ControlBarButton
          primary
          small
          icon={this.state.showReview ? 'edit' : 'search'}
          onClick={() =>
            this.setState({
              showReview: !this.state.showReview,
            })
          }
        >
          {this.state.showReview ? __('Editovací mód') : __('Náhľad článku')}
        </ControlBarButton>
        <ControlBarButton
          small
          danger
          onClick={() =>
            this.setState({
              showConfirmDialog: true,
            })
          }
          icon="delete"
        >
          {__('Odstrániť')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      error,
      success,
      saveLoading,
      loading,
      article,
      tittleImageLoading,
      showConfirmDialog,
      showSaveDialog,
      deleteError,
      deleteLoading,
      showReview,
      selectedLang,
      newArticleId,
    } = this.state;
    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    if (showReview) {
      return (
        <MainWrapper>
          {this.renderControlBar()}
          <GrayHeader>{__('Karta článku')}</GrayHeader>
          <ArticleCard
            name={article.name}
            perex={article.perex}
            video_label={article.video_label}
            firm={selectedLang}
            color={article.color}
            thumbnail={article.thumbnail}
          />
          <GrayHeader>{__('Nahľad článku')}</GrayHeader>
          <Article
            name={article.name}
            perex={article.perex}
            firm={selectedLang}
            thumbnail={article.thumbnail}
            videos={article.videos || []}
            updated={article.updated_date}
            content={translate(
              selectedLang,
              article.text.translations,
              article.text.value,
            )}
          />
        </MainWrapper>
      );
    }

    return (
      <MainWrapper>
        <PopUpWrapper
          display={newArticleId}
          message={__('Kópia článku')}
          onClose={() => this.setState({ newArticleId: null })}
          statusName={newArticleId}
          onEscClose={() => this.setState({ newArticleId: null })}
        >
          <ConfirmDialog
            message={__(
              'Kópia článku bola vytvorená. Chcete prejsť na kópiu článku?',
            )}
            onDismiss={() => this.setState({ newArticleId: null })}
            onConfirm={() => this.redirectToCopy(newArticleId)}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() =>
            this.setState({
              showConfirmDialog: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Prajete si odstániť článok ?')}
            onDismiss={() =>
              this.setState({
                showConfirmDialog: false,
              })
            }
            onConfirm={() => this.deleteNews()}
            // dismissMessage={error}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showSaveDialog}
          message={__('Publikácia článku')}
          onClose={() =>
            this.setState({
              showSaveDialog: false,
            })
          }
        >
          <SaveWrapper>
            <Label>{__('Nastavenie článku')}</Label>
            <SettingsInfo>
              {`Článok bude publikovaný od ${
                article.published_from
                  ? formatDate(article.published_from, FORMAT_HUMANE_DATETIME)
                  : 'neurčita'
              } do ${
                article.published_to
                  ? formatDate(article.published_to, FORMAT_HUMANE_DATETIME)
                  : 'neurčita'
              }`}
            </SettingsInfo>
            <TextInfo>
              {`${
                article.published.length === 0
                  ? __(
                      'Článok je aktuálne nastavený na nepublikovať. Ak si prajete aby bol článok publikovaný v nastavenom čase zaškrtnite:',
                    )
                  : __(
                      `Článok je aktuálne nastavený na publikovať (${article.published.join()}). Ak si prajete aby nebol článok publikovaný v nastavenom čase odškrtnite:`,
                    )
              }`}
            </TextInfo>
            <CheckBoxesWrapper>
              <Label>{__('Publikovaný: ')}</Label>

              {LANGS.map((l) => {
                return (
                  <Label>
                    <Space />
                    <Checkbox
                      checked={article.published.indexOf(l) > -1}
                      onChange={() => {
                        this.updatePublished(l);
                      }}
                    />
                    {l}
                  </Label>
                );
              })}
            </CheckBoxesWrapper>
            <ButtonWrapper>
              <Button
                onClick={() =>
                  this.setState(
                    {
                      saveLoading: true,
                    },
                    () => {
                      this.saveData();
                    },
                  )
                }
                primary
                loading={saveLoading}
              >
                {__('Uložiť')}
              </Button>
            </ButtonWrapper>
          </SaveWrapper>
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <GrayHeader>{this.getTitle(article.type)}</GrayHeader>
        <Wrapper>
          <ArticleEdit
            history={this.props.history}
            onUsersFetch={(query) => this.fetchUsers(query)}
            onFileDelete={(fullPath) => this.deleteFile(fullPath)}
            onFileDownload={(item) => this.downloadFile(item)}
            getUploadParams={(e, view) => this.getUploadParams(e, view)}
            onError={(fileError) => this.setState(fileError)}
            handleChangeStatus={(e) => this.handleChangeStatus(e)}
            selectLangImage={(lang, path) => this.selectLangImage(lang, path)}
            article={article}
            deleteImage={(path) => this.deleteImage(path)}
            addImages={(images) => this.uploadImages(images)}
            selectedLang={selectedLang}
            changeLang={(lang) =>
              this.setState({
                selectedLang: lang,
              })
            }
            uploadTitleImage={(image) => this.uploadImage(image)}
            tittleImageLoading={tittleImageLoading}
            onChange={(a) => this.setState({ article: a })}
          />
        </Wrapper>
        <GrayHeader>{`${__(
          'Videá - na záver článku',
        )} - ${selectedLang}`}</GrayHeader>
        <VideoWrapper>
          <VideoUpLoader
            videos={
              article.videos
                ? article.videos.filter((v) => v.lang === selectedLang)
                : []
            }
            addNewLink={() => this.addNewLink(selectedLang)}
            onChange={(e, index) =>
              this.handleVideoChange(e, index, selectedLang)
            }
            onDelete={(index) => this.deleteVideo(index, selectedLang)}
            // uploadVideo={(videos, index) =>
            //   this.uploadVideo(videos, index, selectedLang)
            // }
            // uploadLoading={uploadLoading}
          />
        </VideoWrapper>

        <GrayHeader>{`${__('Obsah')} - ${selectedLang}`}</GrayHeader>
        <Wrapper>
          <TextEditorWrapper>
            {/* <TextEditorNews
              onChange={text => this.handleTextChange(text)}
              text={translate(
                selectedLang,
                article.text.translations,
                article.text.value,
              )}
            /> */}

            <NewsletterEditor
              keyProps={selectedLang}
              selectedLang={selectedLang}
              jsonData={
                article.json_data &&
                translate(
                  selectedLang,
                  article.json_data.translations,
                  article.json_data.value,
                )
              }
              htmlData={translate(
                selectedLang,
                article.text.translations,
                article.text.value,
              )}
              firm={this.props.firm}
              onSave={(data) =>
                this.handleSaveEditor(data.json_data, data.html_data)
              }
            />
          </TextEditorWrapper>
        </Wrapper>
        {/* <ButtonRelativeWrapper>
          <Button
            style={{ zIndex: 100 }}
            onClick={() =>
              this.setState({
                showSaveDialog: true,
              })
            }
            primary
            loading={saveLoading}
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper> */}
      </MainWrapper>
    );
  }
}

NewsDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  type: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
    display_name: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(NewsDetail);
