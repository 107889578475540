import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'oxyrion-ui/lib/Button';
import Icon from 'oxyrion-ui/lib/Icon';
import { Tr, Td } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { rem } from 'polished';
import Input from 'oxyrion-ui/lib/Input';
import { __ } from '../../Utils';

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  max-width: ${rem(95)};
  margin-right: ${rem(10)};
`;

const DesktopButton = styled(Button)`
  display: none;
  ${({ theme }) => theme.media.m`
  display: flex;
`};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: flex
    ${({ theme }) => theme.media.m`
  display: none;
`};
`;

class AddNewTurnoverDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: '',
    };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.over !== this.props.over) {
      this.setState({ to: this.props.over });
    }
  }

  handleOnChange(field, value) {
    this.setState({ [field]: value });
  }

  handlePercentInput(value) {
    if (value > 100 || value < 0) {
      this.setState({ percent: value });
    } else {
      this.setState({ percent: value });
    }
  }

  checkData() {
    const { amount, percent, q1, q2, q3, q4 } = this.state;

    if (Number(q1) + Number(q2) + Number(q3) + Number(q4) !== Number(amount)) {
      return false;
    }

    return (
      amount !== '' &&
      percent !== '' &&
      q1 !== '' &&
      q2 !== '' &&
      q3 !== '' &&
      q4 !== ''
    );
  }

  submit() {
    const { onSubmit } = this.props;
    const { amount, percent, q1, q2, q3, q4 } = this.state;

    if (this.checkData()) {
      onSubmit(amount, percent, q1, q2, q3, q4);
      this.setState({
        amount: '',
        percent: '',
        q1: '',
        q2: '',
        q3: '',
        q4: '',
      });
    }
  }

  render() {
    const { amount, percent, q1, q2, q3, q4 } = this.state;

    return (
      <Tr>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              //error={fromError}
              value={amount}
              placeholder={__('obratova zľava')}
              onChange={(e) => {
                this.handleOnChange('amount', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              //error={toError}
              value={percent}
              placeholder={__('Zlava')}
              onChange={(e) => {
                this.handlePercentInput(e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              //error={fromError}
              value={q1}
              placeholder={__('Q1')}
              onChange={(e) => {
                this.handleOnChange('q1', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              //error={fromError}
              value={q2}
              placeholder={__('Q2')}
              onChange={(e) => {
                this.handleOnChange('q2', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              //error={fromError}
              value={q3}
              placeholder={__('Q3')}
              onChange={(e) => {
                this.handleOnChange('q3', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <ValueInputWrapper>
            <StyledInput
              width="100%"
              type="number"
              //error={fromError}
              value={q4}
              placeholder={__('Q4')}
              onChange={(e) => {
                this.handleOnChange('q4', e.target.value);
              }}
            />
          </ValueInputWrapper>
        </Td>
        <Td>
          <DesktopButton
            primary
            small
            disabled={
              amount === '' ||
              percent === '' ||
              q1 === '' ||
              q2 === '' ||
              q3 === '' ||
              q4 === ''
            }
            loading={this.props.buttonLoading}
            onClick={() => this.submit()}
          >
            {__('Pridať')}
          </DesktopButton>
          <StyledIcon
            name="plus"
            onClick={() => this.submit()}
            color="#ee6500"
          />
        </Td>
      </Tr>
    );
  }
}

AddNewTurnoverDiscount.propTypes = {
  buttonLoading: PropTypes.bool,
};

AddNewTurnoverDiscount.defaultProps = {
  buttonLoading: false,
};

export default AddNewTurnoverDiscount;
