import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button, ThemeAdmin } from 'oxyrion-ui/lib';
import TableV3 from '../../Components/TableV3';
import { connect } from '../../Store';
import {
  formatPrice,
  getCurrencyByFirm,
  getDiscountPercents,
  getPriceWithoutVat,
  getPriceWithVat,
  __,
  findVatRateByCompanyId,
} from '../../Utils';
import {
  ButtonRelativeWrapper,
  DHeader,
  P,
} from '../../Components/ReusableComponents';
import LanguageSwitch from '../../Components/LanguageSwitch';
import OwnStateInput from '../../Components/OwnStateInput';
import AdminAPI from '../../AdminAPI';
import OwnStateTextArea from '../../Components/OwnStateTextArea';

const PriceWrapper = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const Price = styled.div`
  margin-left: ${rem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(12)};
`;

class Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      activeLanguage: 'SK',
      price: 0,
      variants: [],
      saveLoading: false,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      variants: this.normalizeData(props.variants, props.sales),
    });
  }

  normalizeData(data, salesData) {
    const { vats } = this.props;

    return data.map((item) => {
      return Object.assign(item, {
        translations: item.translations.map((trans) => {
          const { sales, inventory } =
            salesData.find((s) => s._id === item._id) &&
            salesData.find((s) => s._id === item._id).translations[trans.lang];

          return Object.assign(trans, {
            price: {
              amount:
                getPriceWithoutVat(
                  findVatRateByCompanyId(trans.lang, vats),
                  Number(sales.price),
                ) || 0,
              currency: trans.variantPrice.currency,
            },
            price_vat: {
              amount: (sales && Number(sales.price)) || 0,
              currency: trans.variantPrice.currency,
            },
            discount:
              getDiscountPercents(
                getPriceWithoutVat(
                  findVatRateByCompanyId(trans.lang, vats),
                  Number(sales.price),
                ),
                trans.variantPrice.amount,
              ) || 0,
            quantity: inventory.quantity || 0,
            salesId: sales && sales._id ? sales._id : null,
            note: sales.note || '',
          });
        }),
      });
    });
  }

  createColumns() {
    const { activeLanguage } = this.state;

    return [
      {
        accessor: 'No',
        Header: () => <DHeader>{__('Číslo výrobku')}</DHeader>,
        Cell: (props) => {
          return <P>{props.value}</P>;
        },
        maxWidth: 100,
      },
      {
        accessor: 'name',
        Header: () => <DHeader>{__('Názov výrobku')}</DHeader>,
        Cell: (props) => {
          return <P>{props.value}</P>;
        },
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Popis')}</DHeader>,
        Cell: (props) => {
          return (
            <InputWrapper>
              <OwnStateTextArea
                placeholder={__('Popis')}
                onChange={() => {}}
                onBlur={(value) =>
                  this.handleTextChange(value, 'note', props.original._id)
                }
                value={
                  props.value.find((t) => t.lang === activeLanguage)
                    ? props.value.find((t) => t.lang === activeLanguage).note
                    : ''
                }
              />
            </InputWrapper>
          );
        },
        maxWidth: 220,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Pôvodná cena bez DPH')}</DHeader>,
        Cell: (props) => {
          return (
            <P>
              {formatPrice(
                props.value.find((t) => t.lang === activeLanguage)
                  ? props.value.find((t) => t.lang === activeLanguage)
                      .variantPrice
                  : 0,
              )}
            </P>
          );
        },
        maxWidth: 180,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Pevná cena bez DPH')}</DHeader>,
        Cell: (props) => {
          return (
            <PriceWrapper>
              <OwnStateInput
                type="number"
                style={{ width: rem(100) }}
                placeholder={__('0')}
                onChange={() => {}}
                onBlur={(e) =>
                  this.handlePriceChange(e, 'price', props.original._id)
                }
                value={
                  props.value.find((t) => t.lang === activeLanguage)
                    ? props.value.find((t) => t.lang === activeLanguage).price
                        .amount
                    : 0
                }
              />

              <Price>{getCurrencyByFirm(activeLanguage)}</Price>
            </PriceWrapper>
          );
        },
        maxWidth: 180,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Pevná cena s DPH')}</DHeader>,
        Cell: (props) => {
          return (
            <PriceWrapper>
              <OwnStateInput
                type="number"
                key={`${props.original._id}${activeLanguage}`}
                style={{ width: rem(100) }}
                placeholder={__('0')}
                onChange={() => {}}
                onBlur={(e) =>
                  this.handlePriceChange(e, 'price_vat', props.original._id)
                }
                value={
                  props.value.find((t) => t.lang === activeLanguage)
                    ? props.value.find((t) => t.lang === activeLanguage)
                        .price_vat.amount
                    : 0
                }
              />

              <Price>{getCurrencyByFirm(activeLanguage)}</Price>
            </PriceWrapper>
          );
        },
        maxWidth: 180,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Percentuálna zľava')}</DHeader>,
        Cell: (props) => {
          return (
            <P>
              {`${
                props.value.find((t) => t.lang === activeLanguage)
                  ? props.value.find((t) => t.lang === activeLanguage).discount
                  : 0
              } %`}
            </P>
          );
        },
        maxWidth: 150,
      },
      {
        accessor: 'translations',
        Header: () => <DHeader>{__('Množstvo WH010')}</DHeader>,
        Cell: (props) => {
          return (
            <P>
              {props.value.find((t) => t.lang === activeLanguage)
                ? props.value.find((t) => t.lang === activeLanguage).inventory
                    .quantity || 0
                : 0}
            </P>
          );
        },
        maxWidth: 150,
      },
    ];
  }

  changeLanguageForVariants(id) {
    this.setState({
      activeLanguage: id,
    });

    return true;
  }

  async saveSaleProducts() {
    const { variants } = this.state;
    const { vats } = this.props;

    this.setState({
      saveLoading: true,
    });

    const postSalesProducts = variants.reduce((acumulator, variant) => {
      return acumulator.concat(
        variant.translations
          .filter((f) => parseFloat(f.discount) > 0 && !f.salesId)
          .map((trans) => ({
            product_nav_id: variant._id,
            name: variant.name,
            type: 'sale',
            discount_percent: 0,
            default_price: getPriceWithVat(
              findVatRateByCompanyId(trans.lang, vats),
              trans.variantPrice.amount,
            ),
            price: parseFloat(trans.price_vat.amount),
            firm: trans.lang,
            note: trans.note,
            // valid_from: moment().toISOString(),
            // valid_to: settings.valid_to,
          })),
      );
    }, []);

    const params = {};

    const putSalesProducts = variants.reduce((acumulator, variant) => {
      return acumulator.concat(
        variant.translations
          .filter((f) => f.salesId)
          .map((trans) => ({
            _id: trans.salesId,
            product_nav_id: variant._id,
            name: variant.name,
            discount_percent: 0,
            type: 'sale',
            default_price: getPriceWithVat(
              findVatRateByCompanyId(trans.lang, vats),
              trans.variantPrice.amount,
            ),
            price: parseFloat(trans.price_vat.amount),
            firm: trans.lang,
            note: trans.note,
            // valid_from: moment().toISOString(),
            // valid_to: settings.valid_to,
          })),
      );
    }, []);

    try {
      if (postSalesProducts && postSalesProducts.length > 0) {
        params.body = {
          sale_products: postSalesProducts,
        };

        params.type = 'sale';

        await AdminAPI.postSaleProductsAction(params);
      }

      if (putSalesProducts && putSalesProducts.length > 0) {
        params.body = {
          sale_products: putSalesProducts,
        };
        await AdminAPI.putSalesProductAction(params);
      }

      // this.props.onSuccesSave();
      this.setState({
        saveLoading: false,
      });
    } catch (e) {
      // this.props.onErrorSave();
      console.log(e);
    }
  }

  handleTextChange(value, label, rowId) {
    const { variants, activeLanguage } = this.state;

    if (label === 'note') {
      const _variants = [...variants];

      const varinatsIdx = _variants.findIndex((i) => i._id === rowId);
      const transIdx = _variants[varinatsIdx].translations.findIndex(
        (t) => t.lang === activeLanguage,
      );

      _variants[varinatsIdx].translations[transIdx][label] = value;

      this.setState({
        variants: _variants,
      });
    }
  }

  handlePriceChange(e, label, rowId) {
    const { variants, activeLanguage } = this.state;
    const { vats } = this.props;

    if (label === 'price' || label === 'price_vat') {
      const _variants = [...variants];

      const varinatsIdx = _variants.findIndex((i) => i._id === rowId);
      const transIdx = _variants[varinatsIdx].translations.findIndex(
        (t) => t.lang === activeLanguage,
      );

      if (label === 'price') {
        _variants[varinatsIdx].translations[transIdx][label].amount = Number(
          e.target.value,
        );

        if (Number(e.target.value) >= 0) {
          _variants[varinatsIdx].translations[transIdx].price_vat.amount =
            getPriceWithVat(
              findVatRateByCompanyId(activeLanguage, vats),
              Number(e.target.value),
            );

          _variants[varinatsIdx].translations[transIdx].discount =
            getDiscountPercents(
              Number(e.target.value),
              _variants[varinatsIdx].translations[transIdx].variantPrice.amount,
            );
        }
      } else if (label === 'price_vat') {
        _variants[varinatsIdx].translations[transIdx][label].amount = Number(
          e.target.value,
        );

        if (Number(e.target.value) >= 0) {
          _variants[varinatsIdx].translations[transIdx].price.amount =
            getPriceWithoutVat(
              findVatRateByCompanyId(activeLanguage, vats),
              Number(e.target.value),
            );

          _variants[varinatsIdx].translations[transIdx].discount =
            getDiscountPercents(
              _variants[varinatsIdx].translations[transIdx].variantPrice.amount,
              getPriceWithoutVat(
                findVatRateByCompanyId(activeLanguage, vats),
                Number(e.target.value),
              ),
            );
        }
      }

      this.setState({
        variants: _variants,
      });
    }
  }

  tableBackgroundColor(index, selected) {
    if (index === selected) {
      return ThemeAdmin.color.primary;
    }
    if (index % 2 === 0) {
      return 'white';
    }
    return '#eee';
  }

  render() {
    const { loading } = this.props;

    const { variants, activeLanguage, saveLoading } = this.state;

    return (
      <React.Fragment>
        <LanguageSwitch
          onChange={(id) => {
            return this.changeLanguageForVariants(id);
          }}
          activeId={activeLanguage}
        />
        {variants && variants.length > 0 && (
          <TableV3
            // getTrProps={(state, rowInfo) => {
            //   if (rowInfo && rowInfo.row) {
            //     return {
            //       onClick: () => {
            //         this.setState(
            //           {
            //             selected: rowInfo.index,
            //           },
            //           () => changeSelectedVariant(rowInfo.index),
            //         );
            //       },
            //       style: {
            //         background: this.tableBackgroundColor(
            //           rowInfo.index,
            //           selected,
            //         ),
            //         color: rowInfo.index === selected ? 'white' : 'black',
            //         cursor: 'pointer',
            //       },
            //     };
            //   }
            //   return {};
            // }}
            columns={this.createColumns()}
            minWidth={10}
            loading={loading}
            noDataText={__('Žiadne varianty')}
            data={variants}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              if (column && column.sortable) {
                return {
                  onClick: () => {},
                };
              }
              return {};
            }}
            className="-highlight -striped"
          />
        )}
        <ButtonRelativeWrapper>
          <Button
            loading={saveLoading}
            onClick={() => this.saveSaleProducts()}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

Sales.propTypes = {
  // changeSelectedVariant: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  sales: PropTypes.object,
  onSuccesSave: PropTypes.func.isRequired,
  onErrorSave: PropTypes.func.isRequired,
  variants: PropTypes.arrayOf({
    name: PropTypes.string,
    No: PropTypes.string,
    published: PropTypes.shape({
      CRM: PropTypes.bool,
      B2B: PropTypes.bool,
      xFarby: PropTypes.bool,
      xBarvy: PropTypes.bool,
      xFarben: PropTypes.bool,
      xFestek: PropTypes.bool,
    }),
  }),
};

Sales.defaultProps = {
  loading: false,
  sales: {},
  variants: [],
  vats: [],
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Sales);
