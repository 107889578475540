import React from 'react';
import BaseTable from '../BaseTable';
import PropTypes from 'prop-types';
import tableHeaders from '../../ContentConfig/crmChatBot';
import { connect } from '../../Store';
import {
  __,
  FORMAT_HUMANE_DATETIME,
  formatDate,
  renderLink,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';
import moment from 'moment';

class CRMChatBotMessagesList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Komunikácia s inteligentným asistentom v CRM');
    this.showBulkOperations = false;
    this.accessors = ['user_name', 'assistant_type', 'last_message_date'];
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      lastSearch: '',
    };

    this.handleNotificationOnClick = this.handleNotificationOnClick.bind(this);
  }

  translateChannelType(type) {
    switch (type) {
      case 'chatbot':
        return 'CRM Asistent';
      case 'products_chatbot':
        return 'Asistent predaja farieb';
      default:
        return 'Chatbot';
    }
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, selectedFilter } = this.state;
      const { firm } = this.props;

      this.setState({ loading: true });

      const params = Object.assign(
        this.buildParamsByFilter(selectedFilter),
        parameters,
        { sorter },
      );

      const res = await AdminAPI.getChatChannelsAction(firm, params);

      const content = this.normalizeColumns(res.items);

      this.setState({
        content,
        loading: false,
        limit: res.limit,
        offset: res.offset,
        total: res.total,
        nextOffset: res.next_offset,
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  }

  handleNotificationOnClick(id) {
    const { history, firm } = this.props;

    history.push(`/${firm}/chatbot-messages/${id}`);
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        user_name: _shapeData(
          renderLink(`./chatbot-messages/${item._id}`, `${item.user_name}`),
          () => this.handleNotificationOnClick(item._id),
        ),
        assistant_type: _shapeData(
          this.translateChannelType(item.channel_type),
        ),
        last_message_date: _shapeData(
          formatDate(moment(item.last_message_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar
        history={history}
        name={__('Komunikácia s inteligentným asistentom v CRM')}
      ></ControllBar>
    );
  }

  render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}

CRMChatBotMessagesList.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CRMChatBotMessagesList);
