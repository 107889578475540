import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import { rem } from 'polished';
import { Loader, Message } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import {
  getIdFromProps,
  __,
  formatDate,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import SettingsBox from '../../Components/SettingsBox';
import moment from 'moment';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${rem(20)};
  max-width: ${rem(500)};
  margin-bottom: ${rem(20)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  // margin-right: ${rem(16)};
  display: flex;
`;

const ErrorWrapper = styled.div`
  width: 80%;
  margin: ${rem(30)} auto;
`;

class CRMChatBotChannelMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saveLoading: false,
      success: false,
      activeLang: 'SK',
      data: {},
      oxyrionUserSelect: null,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({
      loading: true,
    });

    const channelId = getIdFromProps(this.props);
    try {
      const data = await AdminAPI.getChatChannelMessagesAction(
        this.props.firm,
        channelId,
        { limit: 100, offset: 0, sorter: 'created_date' },
      );

      this.setState({
        data: data.items,
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return (
      <ControllBar
        history={history}
        name={`${__('Detail komunikácie v kanále')}`}
      />
    );
  }

  render() {
    const { loading, data } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    if (data?.length === 0) {
      return (
        <React.Fragment>
          {this.renderControlBar()}
          <ErrorWrapper>
            <Message
              error
              message={__('Nenašli sa žiadne správy v komunikácii')}
            />
          </ErrorWrapper>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <ContentWrapper>
          {data.map((m) => (
            <div style={{ paddingTop: rem(16) }}>
              <SettingsBox
                addDisabled
                title={`${m.user.name || 'Asistent'} (${formatDate(moment(m.created_date), FORMAT_HUMANE_DATETIME)})`}
              >
                <Row>{m.message.text}</Row>
              </SettingsBox>
            </div>
          ))}

          <Space />
        </ContentWrapper>
      </React.Fragment>
    );
  }
}

CRMChatBotChannelMessages.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CRMChatBotChannelMessages);
