export default {
  user: {},
  loggingOut: false,
  firm: 'SK',
  year: new Date().getFullYear(),
  history: '/',
  aclList: {},
  urlList: [],
  locations: [],
  emptyLocations: false,
  settings: {},
  userHistory: [],
  vatRate: 20,
};
