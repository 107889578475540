import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import Img from 'react-image';
import { rem } from 'polished';
import { Button, Message, Label, Loader, Icon } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  StyledInput,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import SearchAndOrderItems from '../../Components/SearchAndOrderItems';
import LanguageSwitch from '../../Components/LanguageSwitch';
import SettingsBox from '../../Components/SettingsBox';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';
import ReactDragListView from 'react-drag-listview';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  //width: 100%;
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  margin-bottom: ${rem(20)};
  margin-right: ${rem(20)};
  flex-direction: column;
`;

const InputWrapper = styled.div`
  width: ${rem(500)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  // margin-right: ${rem(16)};
  display: flex;
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const SearchableAndOrderSelectWrapper = styled.div`
  width: ${rem(1000)};
`;

const DragWrapper = styled.div`
  display: flex;
  position: relative;

  .drag_and_drop_wrapper {
    background: #f7f7f7;
    border-radius: ${rem(4)};
    padding: ${rem(8)};
    margin-bottom: ${rem(20)};
    overflow: auto;
    flex-direction: column;
    min-width: ${rem(210)};
  }
`;

const DragItem = styled.div`
  border-radius: 4px;
  padding: ${rem(10)};
  background: white;
  margin-right: ${rem(12)};
  margin-bottom: ${rem(8)};
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  position: relative;

  .picture {
    display: flex;
  }
  .description {
    padding-left: ${rem(8)};
    padding-right: ${rem(16)};
  }

  .description-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .icon-wrapper {
    cursor: pointer;
  }
`;

const PopUpInsideWrapper = styled.div`
  padding: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

const ButtonWrapper = styled.div`
  padding-top: ${rem(12)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

const Photo = styled(Img)`
  width: ${rem(64)};
  // border-radius: 100%;
`;

const COUNTRIES_TO_PUBLISH = ['SK', 'CZ', 'EN'];
class CompanyInfoTeamMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saveLoading: false,
      teamLoading: false,
      showAddTeam: false,
      success: false,
      activeLang: 'SK',
      teamMembers: [],
      empolyersInTeam: [],
      employers: [],
      data: {
        employer_section: null,
        employer: null,
        newTeamName: '',
        teamName: '',
      },
    };
  }

  componentWillMount() {
    this.loadData();
  }

  getTranslatedData(data, lang) {
    if (!data) {
      return '';
    }

    const obj = data.find((d) => d.lang === lang);
    return obj ? obj.value : '';
  }

  handleLocalChange(field, lang, value) {
    const { data } = this.state;

    if (!data[field]) {
      data[field] = [];
    }

    if (!data[field].find((d) => d.lang === lang)) {
      data[field].push({
        lang,
        value,
      });
    } else {
      data[field].find((d) => d.lang === lang).value = value;
    }

    this.setState({
      data,
      error: false,
    });
  }

  async handleChange(field, value) {
    const { data, activeLang, employers } = this.state;

    if (value) {
      if (field === 'employer_section') {
        data[field] = value;
        data.teamName = this.getTranslatedData(value.raw.title, activeLang);

        let _e =
          value &&
          value.raw &&
          this.getTranslatedData(value.raw.members, activeLang)
            ? employers
                .filter((f) =>
                  this.getTranslatedData(
                    value.raw.members,
                    activeLang,
                  ).includes(f.value),
                )
                .sort((a, b) => {
                  return (
                    this.getTranslatedData(
                      value.raw.members,
                      activeLang,
                    ).indexOf(a.value) -
                    this.getTranslatedData(
                      value.raw.members,
                      activeLang,
                    ).indexOf(b.value)
                  );
                })
                .map((t, index) => Object.assign(t, { id: index }))
            : [];

        this.setState({
          data,
          empolyersInTeam: _e,
        });
      } else if (field === 'employer') {
        data[field] = value;

        this.setState({
          data,
        });
      } else {
        data[field] = value;
        this.setState({ data });
      }
    } else {
      data[field] = null;
      this.setState({
        data,
      });
    }
  }

  async handleChangeActiveLang(lang) {
    const { data, employers } = this.state;

    this.setState(
      {
        activeLang: lang,
      },
      () => {
        // if (
        //   teamMembers[0] &&
        //   this.getTranslatedData(teamMembers[0].title, lang)
        // ) {
        //   this.handleChange('employer_section', {
        //     label: this.getTranslatedData(teamMembers[0].title, lang),
        //     value: this.getTranslatedData(teamMembers[0].title, lang),
        //     raw: teamMembers[0],
        //   });
        // } else {
        //   this.handleChange('employer_section', null);
        // }
      },
    );

    data.teamName = this.getTranslatedData(
      data.employer_section.raw.title,
      lang,
    );

    this.setState({
      data,
      empolyersInTeam:
        data.employer_section &&
        data.employer_section.raw &&
        this.getTranslatedData(data.employer_section.raw.members, lang)
          ? employers
              .filter((f) =>
                this.getTranslatedData(
                  data.employer_section.raw.members,
                  lang,
                ).includes(f.value),
              )
              .map((t, index) => Object.assign(t, { id: index }))
          : [],
    });

    return true;
  }

  handleEmployersOrderChange = (fromIndex, toIndex) => {
    const _data = [...this.state.empolyersInTeam];
    const item = _data.splice(fromIndex, 1)[0];
    _data.splice(toIndex, 0, item);

    const { teamMembers, data, activeLang } = this.state;

    this.setState({
      empolyersInTeam: _data,
    });

    const team = teamMembers.find((tm) =>
      tm.title.find((t) => t.value === data.employer_section.value),
    );

    if (team) {
      const member = team.members.find((tm) => tm.lang === activeLang);

      if (member && member.value) {
        member.value = _data.map((i) => i.value);
      }
    }

    try {
      const params = {};
      params.body = {
        team_members: teamMembers,
      };

      AdminAPI.putCompanyBaseInfoAction(params);
    } catch (e) {
      console.log(e);
    }
  };

  async addEmployerToTeam() {
    const { teamMembers, activeLang, data, employers } = this.state;

    try {
      this.setState({
        saveLoading: true,
      });

      const team = teamMembers.find((tm) =>
        tm.title.find((t) => t.value === data.employer_section.value),
      );

      if (team) {
        if (data.employer) {
          const member = team.members.find((tm) => tm.lang === activeLang);

          if (member && member.value) {
            member.value.push(data.employer.value);
          } else {
            team.members.push({
              lang: activeLang,
              value: [data.employer.value],
            });
          }
        }

        const title = team.title.find((tm) => tm.lang === activeLang);

        if (title && title.value) {
          title.value = data.teamName;
        } else {
          team.title.push({
            lang: activeLang,
            value: data.teamName,
          });
        }
      }

      const params = {};
      params.body = Object.assign({
        team_members: teamMembers.map((t) => ({
          title: t.title,
          members: t.members,
        })),
      });

      await AdminAPI.putCompanyBaseInfoAction(params);

      this.setState({
        empolyersInTeam:
          (employers &&
            employers
              .filter((f) =>
                this.getTranslatedData(team.members, activeLang).includes(
                  f.value,
                ),
              )
              .map((t, index) => Object.assign(t, { id: index }))) ||
          [],
        success: __('Dáta boli úspešne uložené'),
        saveLoading: false,
        teamMembers,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        saveLoading: false,
      });
    }
  }

  async addTeam() {
    const { teamMembers, data } = this.state;

    try {
      this.setState({
        teamLoading: true,
      });

      const params = {};
      params.body = {
        team_members: teamMembers
          .map((t) => ({
            title: t.title,
            members: t.members,
          }))
          .concat([
            {
              title: [{ lang: 'SK', value: data.newTeamName }],
              members: [{ lang: 'SK', value: [] }],
            },
          ]),
      };

      const response = await AdminAPI.putCompanyBaseInfoAction(params);

      data.newTeamName = '';

      this.loadData();

      this.setState({
        success: __('Dáta boli úspešne uložené'),
        teamMembers:
          response.team_members.map((t, index) =>
            Object.assign(t, { id: index }),
          ) || [],
        teamLoading: false,
        showAddTeam: false,
        data,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        teamLoading: false,
        showAddTeam: false,
      });
    }
  }

  async deleteTeamMember(memberToDelete) {
    const { teamMembers, data, activeLang } = this.state;

    const sectionIndex = teamMembers.findIndex((section) =>
      section.title.some(
        (title) =>
          title.lang === activeLang &&
          title.value === data.employer_section.value,
      ),
    );

    const findMemberIndex = teamMembers[sectionIndex].members.findIndex(
      (item) => item.lang === activeLang,
    );

    const filteredMembers = teamMembers[sectionIndex].members[
      findMemberIndex
    ].value.filter((memberId) => memberId !== memberToDelete.value);

    teamMembers[sectionIndex].members[findMemberIndex].value = filteredMembers;

    try {
      const params = {};

      params.body = Object.assign({
        team_members: teamMembers.map((t) => ({
          title: t.title,
          members: t.members,
        })),
      });

      AdminAPI.putCompanyBaseInfoAction(params);

      this.loadData();
      this.setState({
        success: __('Dáta boli úspešne uložené'),
        teamMembers,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async loadData() {
    const { activeLang } = this.state;
    try {
      this.setState({
        loading: true,
      });

      const response = await AdminAPI.getCompanyBaseInfoAction({});
      const employers = await this.fetchEmployers(undefined, '', 1000);

      this.setState({
        employers,
      });

      const defaultSelectedTeam =
        response.team_members &&
        response.team_members[0] &&
        this.getTranslatedData(response.team_members[0].title, activeLang);

      if (defaultSelectedTeam) {
        await this.handleChange('employer_section', {
          label: defaultSelectedTeam,
          value: defaultSelectedTeam,
          raw: response.team_members[0],
        });
      }

      this.setState({
        loading: false,
        teamMembers:
          response.team_members.map((t, index) =>
            Object.assign(t, { id: index }),
          ) || [],
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
        loading: false,
      });
    }
  }

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 15,
      }).then((res) => {
        return res.users.map((item) => ({
          value: item._id,
          label: item.display_name,
          raw: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchEmployers(lang, query, limit = 15) {
    try {
      return await AdminAPI.getEmployersAction(this.props.firm, {
        limit,
        offset: 0,
        q: query,
        publish: lang,
      }).then((res) => {
        return res.items.map((item) => ({
          value: item._id,
          raw: item,
          label: `${item.name} ${item.surname}`,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchSections(query, limit = 15) {
    try {
      return await AdminAPI.getCompanyBaseInfoAction({
        limit,
        offset: 0,
        q: query,
      }).then((res) => {
        return res.team_members.map((item) => ({
          value: this.getTranslatedData(item.title, 'SK'),
          raw: item,
          label: this.getTranslatedData(item.title, 'SK'),
        }));
      });
    } catch (e) {
      return [];
    }
  }

  handleDragEnd = (fromIndex, toIndex) => {
    const data = [...this.state.teamMembers];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    this.setState({
      teamMembers: data,
    });

    try {
      const params = {};
      params.body = {
        team_members: data.map((t) => ({
          title: t.title,
          members: t.members,
        })),
      };
      AdminAPI.putCompanyBaseInfoAction(params);
    } catch (e) {
      console.log(e);
    }
  };

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar history={history} name={__('Štruktúra zamestnancov')}>
        <ControlBarButton
          primary
          small
          onClick={() =>
            this.setState({
              showAddTeam: true,
            })
          }
          showIcon
        >
          {__('Pridať oddelenie')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      loading,
      error,
      success,
      saveLoading,
      data,
      activeLang,
      teamLoading,
      showAddTeam,
      teamMembers,
      empolyersInTeam,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showAddTeam}
          message={__('Pridať nové oddelenie')}
          small
          onClose={() =>
            this.setState({
              showAddTeam: false,
            })
          }
        >
          <PopUpInsideWrapper>
            <StyledInput
              style={{ width: '100%' }}
              value={data.newTeamName}
              placeholder={__('Názov')}
              onChange={(e) => this.handleChange('newTeamName', e.target.value)}
            />
            <ButtonWrapper>
              <Button
                small
                disabled={!data.newTeamName}
                loading={teamLoading}
                onClick={() => this.addTeam()}
                primary
              >
                {__('Uložiť')}
              </Button>
            </ButtonWrapper>
          </PopUpInsideWrapper>
        </PopUpWrapper>

        <Wrapper>
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát sa vyskytla chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
        </Wrapper>

        <ContentWrapper>
          <Space />
          <SettingsBox addDisabled>
            <Row>
              <InputWrapper>
                <StyledLabel>{__('Zvolené oddelenie')}</StyledLabel>
                <SearchableAndOrderSelectWrapper>
                  <SearchAndOrderItems
                    style={{ width: rem(430) }}
                    value={data.employer_section}
                    loadOptions={(query) => {
                      return this.fetchSections(query, 20);
                    }}
                    handleOnChange={(e) =>
                      this.handleChange('employer_section', e)
                    }
                    placeholder={__('Vyberte oddelenie')}
                    isMulti={false}
                    clearable={false}
                  />
                </SearchableAndOrderSelectWrapper>
              </InputWrapper>

              <InputWrapper>
                <StyledLabel>{__('Zoradenie oddelení: ')}</StyledLabel>

                <DragWrapper>
                  <ReactDragListView
                    lineClassName="drag_and_drop_wrapper"
                    onDragEnd={this.handleDragEnd}
                    nodeSelector={'div'}
                    handleSelector={'.drag-handle'}
                  >
                    {teamMembers.map((item, index) => (
                      <DragItem className="drag-handle" key={index}>
                        {this.getTranslatedData(item.title, 'SK')}
                      </DragItem>
                    ))}
                  </ReactDragListView>
                </DragWrapper>
              </InputWrapper>
            </Row>
          </SettingsBox>

          <div>
            <Row>
              <LanguageSwitch
                onChange={(lang) => this.handleChangeActiveLang(lang)}
                activeId={activeLang}
                values={COUNTRIES_TO_PUBLISH}
              />
            </Row>

            <SettingsBox addDisabled>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(380) }}
                    value={data.teamName}
                    placeholder={__('Názov')}
                    onChange={(e) =>
                      this.handleChange('teamName', e.target.value)
                    }
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Pridať zamestnanca')}</StyledLabel>
                  <SearchableAndOrderSelectWrapper>
                    <SearchAndOrderItems
                      key={activeLang}
                      value={data.employer}
                      loadOptions={(query) => {
                        return this.fetchEmployers(activeLang, query, 20);
                      }}
                      handleOnChange={(e) => this.handleChange('employer', e)}
                      placeholder={__('Vyberte zamestnanca')}
                    />
                  </SearchableAndOrderSelectWrapper>
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Zamestnanci na oddelení: ')}</StyledLabel>
                  <DragWrapper>
                    <ReactDragListView
                      lineClassName="drag_and_drop_wrapper"
                      onDragEnd={this.handleEmployersOrderChange}
                      nodeSelector={'div'}
                      handleSelector={'.drag-handle'}
                    >
                      {empolyersInTeam.map((item, index) => (
                        <DragItem className="drag-handle" key={index}>
                          <div className="picture">
                            <Photo
                              src={
                                item.raw &&
                                item.raw.profile_picture &&
                                item.raw.profile_picture.fullPath
                                  ? `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/64x0/${item.raw.profile_picture.fullPath}`
                                  : '/images/icons/avatar.svg'
                              }
                            />
                          </div>
                          <div className="description-wrapper">
                            <div className="description">{item.label}</div>
                            <div
                              className="icon-wrapper"
                              onClick={() => this.deleteTeamMember(item)}
                            >
                              <Icon name={'close'} size="l" color="red" />
                            </div>
                          </div>
                        </DragItem>
                      ))}
                    </ReactDragListView>
                  </DragWrapper>
                </InputWrapper>
              </Row>

              <Space />
              <Button
                disabled={!data.employer_section}
                loading={saveLoading}
                onClick={() => this.addEmployerToTeam()}
                primary
              >
                {__('Uložiť')}
              </Button>
            </SettingsBox>
          </div>
        </ContentWrapper>
      </React.Fragment>
    );
  }
}

CompanyInfoTeamMembers.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CompanyInfoTeamMembers);
