import React, { Component } from 'react';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loader } from 'oxyrion-ui/lib';
import { connect } from './Store';
import Router from './Routes';
import { FlexBox } from './Components/ReusableComponents';

const LoaderWrapper = styled(FlexBox)`
  margin: 29% 0;
`;

const Wrapepr = styled.div`
  max-width: ${({ theme }) => theme.maxWidth || 'auto'};
  margin: auto;
`;

class App extends Component {
  getLoggingOutContainer = () => {
    return (
      <LoaderWrapper>
        <Loader size="xl" />
      </LoaderWrapper>
    );
  };

  render() {
    const { user, loggingOut, firm, year, settings, userHistory } = this.props;

    return (
      <React.Fragment>
        {loggingOut ? (
          this.getLoggingOutContainer()
        ) : (
          <BrowserRouter>
            <Wrapepr>
              <Router
                user={user}
                firm={firm}
                year={year}
                settings={settings}
                userHistory={userHistory}
              />
            </Wrapepr>
          </BrowserRouter>
        )}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  user: PropTypes.object.isRequired,
  loggingOut: PropTypes.bool.isRequired,
  firm: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  settings: PropTypes.shape({}).isRequired,
  userHistory: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  loggingOut: state.loggingOut,
  firm: state.firm,
  year: state.year,
  aclList: state.aclList,
  settings: state.settings,
  userHistory: state.userHistory,
});

export default connect(mapStateToProps)(App);
