import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Input, Button, FileInput, Label } from 'oxyrion-ui/lib';
import Icon from 'oxyrion-ui/lib/Icon';
import { Tr, Td } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from '../../Utils';
import LanguageSwitch from '../LanguageSwitch';
import CustomAPI from '../../CustomAPI';

const StyledInput = styled(Input)`
  margin-right: ${rem(10)};
`;

const StyledInputSmall = styled(StyledInput)`
  max-width: ${rem(30)};
  padding: 0;
`;

const DesktopButton = styled(Button)`
  display: none;
  ${({ theme }) => theme.media.m`
  display: flex;
`};
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: flex
    ${({ theme }) => theme.media.m`
  display: none;
`};
`;

// const CustomSelect = styled(Select)`
//   width: 100%;
//   margin: ${rem(10)} 0;
//   padding: 0;
//   font-size: ${rem(11)};
//   select {
//     height: ${rem(40)};
//   }
// `;

const ColorPickerWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 5px;
`;

const ColorPickerHolder = styled.div`
  cursor: pointer;
  border-radius: ${rem(4)};
  border: #eee 1px solid;
  top: ${rem(5)};
  left: ${rem(2)};
  height: ${rem(36)};
  width: ${rem(36)};
`;

const Image = styled.img`
  margin-top: ${rem(3)};
  z-index: 100;
  height: ${rem(36)};
  width: ${rem(36)};
  border-radius: ${rem(4)};
  border: #eee 1px solid;
`;

const RGBWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(14)};
`;

const RelativeTd = styled.div`
  position: relative;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: ${rem(8)};
  gap: ${rem(2)};
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledReactMultiSelectCheckboxes = styled(ReactMultiSelectCheckboxes)`
  z-index: 100;

  .css-1r4vtzz {
    width: 100%;
  }
`;

const LanguageSwitchWrapper = styled.div`
  padding: 0 ${rem(12)};
  margin-bottom: ${rem(10)};
`;

const acceptedMimeTypes = ['image/png', 'image/jpg', 'image/jpeg'];
class AddNewColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      RGB_R: undefined,
      RGB_G: undefined,
      RGB_B: undefined,
      colorGroup: undefined,
      showPicker: false,
      file: { fileValue: '', file: null, fileError: false },
      activeLang: 'SK',
      translations: [{ lang: 'SK', value: '' }],
    };
  }

  componentWillReceiveProps(props) {
    if (props.shadeToEdit !== this.props.shadeToEdit) {
      if (props.shadeToEdit) {
        if (props.shadeToEdit.image_path) {
          this.setState({
            id: props.shadeToEdit.color_code,
            basecodes: props.shadeToEdit.basecodes,
            name: props.shadeToEdit.color_name,
            RGB_R: props.shadeToEdit.color.R,
            RGB_G: props.shadeToEdit.color.G,
            RGB_B: props.shadeToEdit.color.B,
            colorGroup: props.shadeToEdit.color_group,
            image_path: props.shadeToEdit.image_path,
            image_name: props.shadeToEdit.image_name,
            file: {
              file: {
                fullPath: props.shadeToEdit.image_path,
                name: props.shadeToEdit.image_name,
              },
              fileValue: props.shadeToEdit.image_name,
            },
            translations: props.shadeToEdit.color_name_translations
              ? props.shadeToEdit.color_name_translations
              : [{ lang: 'SK', value: props.shadeToEdit.color_name }],
          });
        } else {
          this.setState({
            id: props.shadeToEdit.color_code,
            name: props.shadeToEdit.color_name,
            RGB_R: props.shadeToEdit.color.R,
            RGB_G: props.shadeToEdit.color.G,
            basecodes: props.shadeToEdit.basecodes,
            RGB_B: props.shadeToEdit.color.B,
            colorGroup: props.shadeToEdit.color_group,
            file: { fileValue: '', file: null, fileError: false },
            translations: props.shadeToEdit.color_name_translations
              ? props.shadeToEdit.color_name_translations
              : [{ lang: 'SK', value: props.shadeToEdit.color_name }],
            image_path: '',
            image_name: '',
          });
        }
      }

      return true;
    }
  }

  checkData() {
    const { id } = this.state;
    let { idError } = this.state;
    const { shades } = this.props;

    let valid = true;

    if (id.length <= 0) {
      valid = false;
      idError = true;
    }

    // if (name.length <= 0) {
    //   valid = false;
    //   nameError = true;
    // }

    if (!this.props.shadeToEdit && shades.find(s => s.color_code === id)) {
      valid = false;
      idError = true;
    }

    this.setState({
      idError,
      // nameError,
    });

    return valid;
  }

  submit() {
    const { onSubmit } = this.props;

    this.setState({
      idError: false,
      nameError: false,
    });

    const {
      id,
      name,
      RGB_R,
      RGB_G,
      RGB_B,
      colorGroup,
      file,
      basecodes,
      translations,
    } = this.state;

    if (this.checkData()) {
      const data = {
        id,
        color_name: name,
        RGB_B: RGB_B >= 0 ? RGB_B : 255,
        RGB_G: RGB_G >= 0 ? RGB_G : 255,
        RGB_R: RGB_R >= 0 ? RGB_R : 255,
        basecodes,
        colorGroup,
        translations,
      };

      if (file && file.file && file.file.fullPath) {
        Object.assign(data, {
          image_name: file.file.name,
          image_path: file.file.fullPath,
        });
      }

      onSubmit(data);

      this.setState({
        id: '',
        name: '',
        RGB_R: '',
        RGB_G: '',
        RGB_B: '',
        basecodes: [],
        colorGroup: null,
        showPicker: false,
        idError: false,
        nameError: false,
        file: { fileValue: '', file: null, fileError: false },
        translations: [{ lang: 'SK', value: '' }],
      });
    }
  }

  togglePicker() {
    this.setState({ showPicker: !this.state.showPicker });
  }

  handleOnChange(field, value) {
    let {
      id,
      name,
      RGB_R,
      RGB_G,
      RGB_B,
      colorGroup,
      basecodes,
      translations,
      activeLang,
    } = this.state;
    if (field === 'id') {
      id = value;
    } else if (field === 'name') {
      const newTranslation = translations.find(
        item => item.lang === activeLang,
      );

      if (!newTranslation) {
        translations.push({ lang: activeLang, value: value });
      } else {
        translations = translations.map(item =>
          item.lang === activeLang ? { ...item, value } : item,
        );
      }

      if (activeLang === 'SK') {
        name = value;
      }
    } else if (
      field === 'RGB_R' &&
      !isNaN(value) &&
      value.length <= 3 &&
      Number(value) < 256
    ) {
      RGB_R = value;
    } else if (
      field === 'RGB_G' &&
      !isNaN(value) &&
      value.length <= 3 &&
      Number(value) < 256
    ) {
      RGB_G = value;
    } else if (
      field === 'RGB_B' &&
      !isNaN(value) &&
      value.length <= 3 &&
      Number(value) < 256
    ) {
      RGB_B = value;
    } else if (field === 'colorGroup') {
      colorGroup = value;
    } else if (field === 'color') {
      RGB_R = value.rgb.r;
      RGB_G = value.rgb.g;
      RGB_B = value.rgb.b;
    } else if (field === 'basecodes') {
      if (value && value.length > 0) {
        basecodes = value.map(b => b.label);
      } else {
        basecodes = [];
      }
    }

    this.setState({
      id,
      name,
      RGB_R,
      RGB_G,
      RGB_B,
      basecodes,
      colorGroup,
      translations,
    });
  }

  discard() {
    const { discard } = this.props;

    discard();

    this.setState({
      id: '',
      name: '',
      RGB_R: '',
      RGB_G: '',
      RGB_B: '',
      basecodes: [],
      colorGroup: 'white',
      showPicker: false,
      idError: false,
      nameError: false,
    });
  }

  async fileOnChange(filesInput) {
    if (filesInput.length) {
      const f = filesInput[0];
      if (!acceptedMimeTypes.includes(f.type)) {
        this.setState({
          file: {
            file: {
              fileError: true,
            },
          },
        });
      } else {
        try {
          console.log('a---', {
            body: {
              image: f,
            },
          });

          const result = await CustomAPI.postColorCardTextureImageAction(
            this.props.firm,
            {
              body: {
                image: f,
              },
            },
          );
          const file = {
            fileValue: result.name,
            file: result,
            fileError: false,
            errors: [],
          };
          this.setState({
            file,
          });
        } catch (e) {
          console.log('e---', e);
        }
      }
    }
  }

  removeFile() {
    this.setState({
      file: { fileValue: '', file: null, fileError: false },
    });
  }

  render() {
    const {
      id,
      name,
      basecodes,
      RGB_R,
      RGB_G,
      RGB_B,
      // colorGroup,
      RGBError,
      idError,
      nameError,
      showPicker,
      file,
      activeLang,
      translations,
    } = this.state;

    // const { colorTypes } = this.props;

    console.log('ile.fileValue---', file);

    return (
      <>
        <LanguageSwitchWrapper>
          <LanguageSwitch
            onChange={lang => {
              this.setState({
                activeLang: lang,
              });
              return true;
            }}
            activeId={activeLang}
          />
        </LanguageSwitchWrapper>
        <Tr
          style={{
            border: this.props.shadeToEdit ? '2px #1d71b7 solid' : 'none',
          }}
        >
          <Td>
            <Row>
              <Label>{__('ID / kód farby')}</Label>
              <StyledInput
                width="100%"
                error={idError}
                value={id}
                placeholder={__('ID / kód farby')}
                onChange={e => {
                  this.handleOnChange('id', e.target.value);
                }}
              />
            </Row>
          </Td>
          <Td>
            <Row>
              <Label>{__('Názov odtieňa')}</Label>
              <StyledInput
                width="100%"
                value={
                  translations.find(item => item.lang === activeLang)
                    ? translations.find(item => item.lang === activeLang).value
                    : ''
                }
                placeholder={__('Názov odtieňa')}
                error={nameError}
                onChange={e => {
                  this.handleOnChange('name', e.target.value);
                }}
              />
            </Row>
          </Td>

          <Td>
            <Row>
              <Label>{__('Báza')}</Label>
              <StyledReactMultiSelectCheckboxes
                style={{
                  width: '100%',
                }}
                options={this.props.bases}
                key={name}
                value={
                  basecodes &&
                  basecodes.map(base => {
                    return {
                      value: base,
                      label: base,
                    };
                  })
                }
                placeholderButtonLabel={__('Báza')}
                onChange={e => this.handleOnChange('basecodes', e)}
              />
            </Row>
          </Td>

          <Td>
            <Row>
              <Label>{__('Obrázok: ')}</Label>
              <FileInput
                style={{ minWidth: rem(200) }}
                item={file.fileValue}
                onChange={e => this.fileOnChange(e.target.files)}
                onRemoveFile={() => this.removeFile()}
              />
            </Row>
          </Td>

          <Td>
            <Row>
              <Label>{__('RGB: ')}</Label>
              <RGBWrapper>
                <StyledInputSmall
                  // type="number"
                  min={0}
                  max={255}
                  value={RGB_R}
                  placeholder={__('R')}
                  error={RGBError}
                  onChange={e => {
                    this.handleOnChange('RGB_R', e.target.value);
                  }}
                />

                <StyledInputSmall
                  // type="number"
                  min={0}
                  max={255}
                  value={RGB_G}
                  placeholder={__('G')}
                  error={RGBError}
                  onChange={e => {
                    this.handleOnChange('RGB_G', e.target.value);
                  }}
                />

                <StyledInputSmall
                  // type="number"
                  min={0}
                  max={255}
                  value={RGB_B}
                  placeholder={__('B')}
                  error={RGBError}
                  onChange={e => {
                    this.handleOnChange('RGB_B', e.target.value);
                  }}
                />

                {file && file.file && file.file.fullPath ? (
                  <RelativeTd>
                    <Image
                      src={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0/${file.file.fullPath}`}
                    />
                  </RelativeTd>
                ) : (
                  <RelativeTd>
                    <ColorPickerHolder
                      style={{
                        backgroundColor: `rgba(${RGB_R}, ${RGB_G}, ${RGB_B}, 1)`,
                      }}
                      onClick={() => this.togglePicker()}
                    />
                    {showPicker && (
                      <ColorPickerWrapper
                        onMouseLeave={() => this.togglePicker()}
                      >
                        <ChromePicker
                          onChange={e => this.handleOnChange('color', e)}
                          color={{
                            r: RGB_R || 0,
                            g: RGB_G || 0,
                            b: RGB_B || 0,
                            a: 1,
                          }}
                        />
                      </ColorPickerWrapper>
                    )}
                  </RelativeTd>
                )}
              </RGBWrapper>
            </Row>
          </Td>

          <Td />
          <Td>
            <Row>
              <Label></Label>
              <ButtonsWrapper>
                <DesktopButton
                  primary
                  small
                  loading={this.props.buttonLoading}
                  onClick={() => this.submit()}
                >
                  {this.props.shadeToEdit ? __('Upraviť') : __('Pridať')}
                  <StyledIcon
                    name="plus"
                    onClick={() => this.submit()}
                    color="white"
                  />
                </DesktopButton>

                {this.props.shadeToEdit && (
                  <DesktopButton
                    small
                    danger
                    loading={this.props.buttonLoading}
                    onClick={() => this.discard()}
                  >
                    {__('Zrušiť')}
                    <StyledIcon name="plus" color="white" />
                  </DesktopButton>
                )}
              </ButtonsWrapper>
            </Row>
          </Td>
        </Tr>
      </>
    );
  }
}

AddNewColor.propTypes = {
  buttonLoading: PropTypes.bool,
  shadeToEdit: PropTypes.shape(),
  discard: PropTypes.func.isRequired,
  colorTypes: PropTypes.arrayOf(PropTypes.shape()),
  firm: PropTypes.string,
  bases: PropTypes.arrayOf(PropTypes.string),
  shades: PropTypes.arrayOf({}).isRequired,
};

AddNewColor.defaultProps = {
  buttonLoading: false,
  shadeToEdit: undefined,
  colorTypes: [],
  bases: [],
  firm: 'SK',
};

export default AddNewColor;
