import React from 'react';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/brands';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getPageIdFromProps,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import API2 from '../../API2';

class BrandsList extends BaseTable {
  constructor(props) {
    super({ ...props });
    this.headers = tableHeaders;
    this.segmentName = __('Bannery');
    this.accessors = ['name'];
    this.setSorter('name');
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearchValue: '',
      activeMessage: 'Všetky',
      activeCountry: 'Všetky',
      activePublishedApps: 'Všetky',
      showAlert: true,
    };

    this.pageId = getPageIdFromProps(this.props);
  }

  componentDidUpdate() {
    if (this.pageId !== getPageIdFromProps(this.props)) {
      this.pageId = getPageIdFromProps(this.props);
      this.init();
    }
  }

  async fetchData(parameters = {}) {
    const { lastSearchValue, activeCountry, sorter, activePublishedApps } =
      this.state;

    try {
      this.setState({
        loading: true,
      });
      const params = Object.assign({}, parameters, { sorter });
      if (!params.q && params.q !== '') {
        params.q = lastSearchValue;
      }

      const newData = await API2.getBrandsAction(this.props.firm);

      this.setState({
        content: this.normalizeColumns(newData.fullBrands),
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  handleSyncOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/brands2/${id}`);
  }

  normalizeColumns(data) {
    const { firm } = this.props;
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || value === 0 ? value : placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      console.log(item);
      acumulator.push({
        name: _shapeData(
          renderLink(
            `/${firm}/brands2/${item._id}`,
            `${item.brand || '-'}`,
            () => this.handleSyncOnClick(item._id),
          ),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }
  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== undefined ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }
  renderControlBar() {
    const { history } = this.props;
    const { lastSearchValue } = this.state;
    return (
      <ControllBar
        history={history}
        name={`${__('Značky')}`}
        defaultValue={lastSearchValue}
        onChange={(val) => this.loadSearchResult(val)}
      />
    );
  }

  filterPublishedAppsChange(app) {
    this.setState(
      {
        activePublishedApps: __(app),
      },
      () => {
        this.fetchData();
      },
    );
  }

  handleCountryChange(activeCountry) {
    this.setState(
      {
        activeCountry: __(activeCountry),
      },
      () => {
        this.fetchData();
      },
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BrandsList);
