/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(
        function (parameterName) {
          const parameter = parameters.$queryParameters[parameterName];
          queryParameters[parameterName] = parameter;
        },
      );
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          } else if (responseContentType.includes('application/pdf')) {
            return response.blob();
          } else if (responseContentType.includes('text/plain')) {
            return response.text();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
     * Upload Opportunity photo.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postColorCardTextureImageAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/colorcardsTexture';
    let queryParameters = {};
    let formData = new FormData();

    // Nastavenie URL s companyId
    path = path.replace('{companyId}', companyId);

    // Pridanie súboru do FormData
    if (parameters['body'] && parameters['body'].image) {
      formData.append('image', parameters['body'].image);
    } else {
      throw new Error('Missing required image in body parameter.');
    }

    // Zlúčenie query parameterov
    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    // Hlavičky
    let headers = this.appendAuthHeaders(new Headers());
    headers.append('Accept', 'application/json');

    // Špeciálna manipulácia na nastavenie Content-Type
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: formData,
    };

    // Poslanie požiadavky
    return fetch(`${this.baseUrl}${path}`, requestOptions).then((response) => {
      if (!response.ok) {
        return response.json().then((err) => {
          throw new Error(JSON.stringify(err));
        });
      }
      return response.json();
    });
  }

  /**
     * Upload pricelist.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} companyId - Company identifier
       * @param {} parameters.body - 
     */
  postPricelistAction(companyId, parameters = {}) {
    let path = '/crm/{companyId}/pricelists';
    let queryParameters = {};
    let headers = new Headers();
    let formData = new FormData();

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{companyId}', companyId);

    if (parameters['body'] && parameters['body'].file) {
      formData.append('file', parameters['body'].file);
      formData.append('name', parameters['body'].name);
      formData.append('dateFrom', parameters['body'].dateFrom);
    } else {
      throw new Error('Missing required file in body parameter.');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    // Špeciálna manipulácia na nastavenie Content-Type
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: formData,
    };

    // Poslanie požiadavky
    return fetch(`${this.baseUrl}${path}`, requestOptions).then((response) => {
      if (!response.ok) {
        return response.json().then((err) => {
          throw new Error(JSON.stringify(err));
        });
      }
      return response.json();
    });
  }
}

export default new Api();
export { Api };
