import { __ } from '../Utils';

export default [
  {
    name: __('Používateľské meno'),
    clickable: true,
    sorterValue: 'display_name',
  },
  { name: __('Meno'), clickable: true, sorterValue: 'forename' },
  { name: __('Priezvisko'), clickable: true, sorterValue: 'surname' },
  { name: __('Email'), clickable: true, sorterValue: 'email' },
  { name: __('Rola'), clickable: true, sorterValue: 'role' },
  { name: __('Kód(y) predajcu'), clickable: false, sorterValue: '' },
  {
    name: __('Aktívne push notifikácie'),
    clickable: false,
    sorterValue: '',
  },
  {
    name: __('Status'),
    clickable: true,
    sorterValue: 'deactivated',
  },
];
