import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Loader, Select, Label, Input, Icon, Button } from 'oxyrion-ui/lib';
import { connect } from '../../../Store';
import {
  LoaderWrapper,
  ButtonRelativeWrapper,
  AnimatedFormMessageWrapper,
} from '../../../Components/ReusableComponents';
import AdminAPI from '../../../AdminAPI';
import { __ } from '../../../Utils';
import SearchableSelect from '../../../Components/SearchableSelect';
import Message from 'oxyrion-ui/lib/Message';
import API2 from '../../../API2';

const MainWrapper = styled.div`
  padding-bottom: ${rem(100)};
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 200px;
  padding: 10px;
`;

const ProductsWrapper = styled.div`
  margin-left: ${rem(4)};
  width: ${rem(800)};
`;

const ProductRow = styled.div`
  margin-bottom: ${rem(8)};
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(24)};
  width: ${rem(50)};
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const SearchSelectWrapper = styled.div`
  width: ${rem(400)};
`;

// const RefreshButton = styled.div`
//   cursor: pointer;
//   margin-top: ${rem(4)};
//   font-size: ${rem(12)};
//   :hover {
//     text-decoration: underline;
//   }
// `;

const filters = [
  {
    value: 'all',
    label: __('Všetky'),
  },
  {
    value: 'assigned',
    label: __('Priradené'),
  },
  {
    value: 'not_assigned',
    label: __('Nepriradené'),
  },
];

class ProductsLinker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: 'all',
      loading: true,
      saveDisabled: true,
      assigns: [],
      catalogProductChangedIds: [],
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  getProductsByFilter(filter, assigns) {
    if (filter === 'all') {
      return assigns;
    } else if (filter === 'assigned') {
      return assigns.filter(p => p.innovatint_products_ids.length);
    } else if (filter === 'not_assigned') {
      return assigns.filter(p => !p.innovatint_products_ids.length);
    }

    return assigns;
  }

  async fetchData(saveSuccess) {
    try {
      const result = await Promise.all([
        API2.getAvatintProductsAction(this.props.firm, {
          limit: 1000,
        }),
        AdminAPI.getInnovatintProductsAssignsAction(),
      ]);

      const innovatintProducts = result[0].products || [];
      const assigns = result[1];

      const normalizedAssigns = assigns.map(a =>
        Object.assign(a, {
          innovatint_products_ids: a.innovatint_products_ids.map(id => {
            const innovatintProduct = innovatintProducts.find(
              product => product.avatint_product_id === id,
            );

            return {
              label: innovatintProduct.name,
              value: innovatintProduct.avatint_product_id,
            };
          }),
        }),
      );

      this.setState({
        assigns: normalizedAssigns,
        selectedFilter: 'all',
        loading: false,
        saveLoading: false,
        saveDisabled: true,
        success: saveSuccess && __('Dáta boli uložené'),
        catalogProductChangedIds: [],
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo načítať'),
      });
    }
  }

  fetchProducts(query, catalogProductId) {
    try {
      return API2.getProductExternalProductsAction(
        this.props.firm,
        catalogProductId,
        {
          q: query,
          limit: 30,
        },
      ).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: item.name,
          raw: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async saveData() {
    const { assigns, catalogProductChangedIds } = this.state;

    const assignsCopy = assigns.slice();

    // this.setState({
    //   saveLoading: true,
    // });
    try {
      const dataToSave = assignsCopy
        .filter(
          a => catalogProductChangedIds.indexOf(a.catalog_product.value) > -1,
        )
        .map(ch =>
          Object.assign(ch, {
            innovatint_products_ids: ch.innovatint_products_ids
              ? ch.innovatint_products_ids.map(i => i.value)
              : [],
          }),
        );

      const params = {};
      params.body = dataToSave;

      await AdminAPI.postInnovatintProductsAssignsAction(params);

      this.fetchData(true);
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
      });
    }
  }

  handleProductChange(catalogProductId, catalogProduct) {
    const { assigns, catalogProductChangedIds } = this.state;

    assigns.find(
      a => a.catalog_product.value === catalogProductId,
    ).innovatint_products_ids = catalogProduct;

    catalogProductChangedIds.push(catalogProductId);

    this.setState({
      assigns,
      saveDisabled: false,
      catalogProductChangedIds,
      success: false,
      error: false,
    });
  }

  render() {
    const {
      loading,
      selectedFilter,
      assigns,
      saveLoading,
      saveDisabled,
      error,
      success,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }
    return (
      <MainWrapper>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <SelectWrapper>
          <Label>{__('Filter')}</Label>
          <Select
            size="s"
            value={selectedFilter}
            onChange={e => {
              this.handleFilterChange(e.target.value);
            }}
          >
            {filters.map(c => {
              return (
                <option selected={c.value === selectedFilter} value={c.value}>
                  {c.label}
                </option>
              );
            })}
          </Select>
        </SelectWrapper>
        <ProductsWrapper>
          {this.getProductsByFilter(selectedFilter, assigns).map(p => (
            <ProductRow key={p.catalog_product.value}>
              <InputWrapper>
                <Input value={p.catalog_product.label} disabled />
              </InputWrapper>
              <IconWrapper>
                <Icon name="arrow-right" />
              </IconWrapper>
              <SearchSelectWrapper>
                <SearchableSelect
                  loadOptions={query => {
                    return this.fetchProducts(query, p.catalog_product.value);
                  }}
                  isMulti
                  value={p.innovatint_products_ids}
                  placeholder={__('Externé produkty')}
                  handleOnChange={e =>
                    this.handleProductChange(p.catalog_product.value, e)
                  }
                />
              </SearchSelectWrapper>
            </ProductRow>
          ))}
        </ProductsWrapper>
        <ButtonRelativeWrapper>
          <Button
            onClick={() => {
              this.setState({ saveLoading: true }, () => this.saveData());
            }}
            disabled={saveDisabled}
            loading={saveLoading}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </MainWrapper>
    );
  }
}

ProductsLinker.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductsLinker);
