import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Select from 'oxyrion-ui/lib/Select';
import BaseTable from '../BaseTable';
import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../ContentConfig/productsPublication';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { __, renderLink, formatDate } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import SearchableSelect from '../../Components/SearchableSelect';
import OwnStateInput from '../../Components/OwnStateInput';
import { LANGS } from '../../ContentConfig/languagesArray';
import productsCanals from '../../ContentConfig/productsCanals';

const SearchWrapper = styled.div`
  width: ${rem(300)};
  color: black;
  margin-right: ${rem(16)};
`;

const SelectWrapper = styled.div`
  margin-right: ${rem(20)};
  width: ${rem(110)};
`;
const InputWrapper = styled.div``;
const CategoryTitle = styled.div`
  margin-right: ${rem(12)};
`;

class ProductsPublication extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Publikácia produktov');
    this.accessors = [
      'name',
      'brand',
      'subbrand',
      'preferred_order',
      'created_date',
    ];
    this.showBulkOperations = true;
    this.fixedHeader = true;
    this.setSorter('name');
    this.state = {
      ...this.state,
      activeMessage: 'Všetky',
      categories: [],
      checkedRows: [],
      selectedCategory: null,
      selectedLang: 'SK',
      channelId: 'xFarby',
      publishedStatus: 'Všetky',
    };
  }

  handleProductOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/products/${id}`);
  }

  async handleRowOnChange(id) {
    const { checkedRows, selectedCategory, selectedLang, channelId } =
      this.state;

    try {
      if (checkedRows.includes(id)) {
        await AdminAPI.deleteProductPublicationAction(
          id,
          selectedCategory.value,
          {
            channelId,
            lang: selectedLang,
          },
        );
        this.setState({
          checkedRows: checkedRows.filter((f) => f !== id),
        });
      } else {
        await AdminAPI.putProductPublicationAction(id, selectedCategory.value, {
          channelId,
          lang: selectedLang,
        });
        this.setState({ checkedRows: [...checkedRows, id] });
      }
    } catch (e) {
      this.setState({
        error: __('Publikáciu sa nepodarilo upraviť'),
      });
    }
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  async fetchData(parameters = {}) {
    try {
      const {
        sorter,
        active,
        selectedLang,
        publishedStatus,
        selectedCategory,
        channelId,
      } = this.state;

      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (selectedCategory !== null) {
        params.lang = selectedLang;
        params.category = selectedCategory.value;
        params.published = publishedStatus === 'Publikované';
        params.channelId = channelId;
      }

      const [newData, { categories }] = await Promise.all([
        AdminAPI.getCatalogProductsAction(params),
        AdminAPI.getCategoriesAction('xFarby', {
          lang: selectedLang,
        }),
      ]);

      const normalizedCategories = this.normalizeCategories(categories);
      const content = this.normalizeColumns(newData.items);

      let newSelectedCategory = selectedCategory;

      if (!newSelectedCategory) {
        newSelectedCategory = normalizedCategories &&
          normalizedCategories[0] && {
            value: normalizedCategories[0]._id,
            label: `${normalizedCategories[0].name} ${
              normalizedCategories[0].parent
                ? ` - ${normalizedCategories[0].parent.name}`
                : ''
            }`,
          };
      }

      const checkedRows = this.getCheckedRows(
        content,
        selectedLang,
        newSelectedCategory,
      );

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
        checkedRows,
        categories: normalizedCategories,
        selectedCategory: newSelectedCategory,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeCategories(categories) {
    return categories.map((c) =>
      Object.assign({}, c, {
        parent: c.parent && categories.find((c1) => c1._id === c.parent),
      }),
    );
  }

  getCheckedRows(content, lang, selectedCategory) {
    const checkedRows = [];

    content.forEach((c) => {
      const langSelected =
        c.selectedCategories.find((s) => s.lang === lang) &&
        c.selectedCategories.find((s) => s.lang === lang).selected;

      if (langSelected && langSelected.indexOf(selectedCategory.value) > -1) {
        checkedRows.push(c.id);
      }
    });

    return checkedRows;
  }

  handleCategoryChange(selectedCategory) {
    const { content, selectedLang } = this.state;

    const checkedRows = this.getCheckedRows(
      content,
      selectedLang,
      selectedCategory,
    );

    this.setState(
      {
        checkedRows,
        selectedCategory,
      },
      () => this.fetchData(),
    );
  }

  async handleLangChange(lang) {
    try {
      const result = await AdminAPI.getCategoriesAction('xFarby', {
        lang,
      });

      const normalizedCategories = this.normalizeCategories(
        result.categories || [],
      );

      const selectedCategory = normalizedCategories &&
        normalizedCategories[0] && {
          value: normalizedCategories[0]._id,
          label: `${normalizedCategories[0].name} ${
            normalizedCategories[0].parent
              ? ` - ${normalizedCategories[0].parent.name}`
              : ''
          }`,
        };

      this.setState(
        {
          selectedCategory,
          selectedLang: lang,
        },
        () => this.fetchData(),
      );
    } catch (e) {
      console.log(e);
    }
  }

  handleChannelChange(channelId) {
    this.setState(
      {
        channelId,
      },
      () => {
        this.fetchData();
      },
    );
  }

  handleTypeChange(status, publishedStatus) {
    this.setState(
      { active: status, sorter: 'name', publishedStatus: __(publishedStatus) },
      () => {
        this.setSorter('name');
        this.fetchData();
      },
    );
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map((header) => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }
  async saveProductOrder(id, order) {
    try {
      const params = {};
      params.body = {
        preferred_order: order > 0 ? order : null,
      };
      await AdminAPI.putCatalogProductPreferredOrder(id, params);
    } catch (e) {
      console.log(e);
    }
  }
  changeProductOrder(id, order) {
    this.saveProductOrder(id, order);
  }
  orderInput = (productId, order) => {
    return {
      value: (
        <InputWrapper>
          <OwnStateInput
            key={productId}
            value={order || ''}
            placeholder={__('Poradie')}
            name={productId}
            type="number"
            onChange={(value) => {
              this.changeProductOrder(productId, Number(value));
            }}
            // onBlur={e => {
            //   this.saveProductOrder(productId, e.target.value);
            // }}
          />
        </InputWrapper>
      ),
      handleOnClick: null,
    };
  };
  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(
          renderLink(`./products/${item._id}`, `${item.name || '-'}`),
          () => this.handleProductOnClick(item._id),
        ),

        brand: _shapeData(item.brand),
        subbrand: _shapeData(item.subbrand),
        preferred_order: this.orderInput(item._id, item.preferred_order),
        created_date: _shapeData(formatDate(item.created_date)),
        selectedCategories:
          item.selectedCategories.find(
            (s) => s.chanel === this.state.channelId,
          ) &&
          item.selectedCategories.find((s) => s.chanel === this.state.channelId)
            .values,
        _id: item._id,
      });
      return acumulator;
    }, []);
    return result;
  }



  searchCategories(query) {
    const { categories } = this.state;

    return categories
      .filter((c) => c.name.toLowerCase().includes(query.toLowerCase()))
      .map((c) => ({
        value: c._id,
        label: `${c.name} ${c.parent ? ` - ${c.parent.name}` : ''}`,
      }));
  }

  renderControlBar() {
    const { history } = this.props;
    const {
      lastSearchValue,
      selectedCategory,
      selectedLang,
      publishedStatus,
      channelId,
    } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Publikácia produktov')}
        defaultValue={lastSearchValue}
        onChange={(val) => this.loadSearchResult(val)}
      >
        <CategoryTitle>{__('Publikované pre')}:</CategoryTitle>
        <SelectWrapper>
          <Select
            size="s"
            value={channelId}
            onChange={(e) => this.handleChannelChange(e.target.value)}
          >
            {productsCanals.map((c) => {
              return (
                <option selected={c === channelId} value={c}>
                  {c}
                </option>
              );
            })}
          </Select>
        </SelectWrapper>
        <CategoryTitle>{__('Jazyk')}:</CategoryTitle>
        <SelectWrapper>
          <Select
            size="s"
            value={selectedLang}
            onChange={(e) => this.handleLangChange(e.target.value)}
          >
            {LANGS.map((l) => {
              return (
                <option selected={l === selectedLang} value={l}>
                  {l}
                </option>
              );
            })}
          </Select>
        </SelectWrapper>
        <CategoryTitle>{__('Kategória')}:</CategoryTitle>
        <SearchWrapper>
          <SearchableSelect
            value={selectedCategory}
            loadOptions={(query) => this.searchCategories(query)}
            placeholder={__('Zvoľte katalogový produkt')}
            handleOnChange={(e) => this.handleCategoryChange(e)}
            label={__('Použité produkty')}
            keyProps={JSON.stringify(selectedCategory)}
            isClearable={false}
          />
        </SearchWrapper>
        <ControlButton name={publishedStatus}>
          <LinkWrap>
            <div
              onClick={(e) => this.handleTypeChange(true, e.target.innerHTML)}
            >
              {__('Všetky')}
            </div>
            <div
              onClick={(e) => this.handleTypeChange(true, e.target.innerHTML)}
            >
              {__('Publikované')}
            </div>
          </LinkWrap>
        </ControlButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProductsPublication);
