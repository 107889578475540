import React, { Component } from 'react';
import Message from 'oxyrion-ui/lib/Message';
import baseTheme from 'oxyrion-ui/lib/Themes/baseTheme';
import Icon from 'oxyrion-ui/lib/Icon';
import { rem } from 'polished';
import { Tbody, Thead, Tr, Th, Td, Table } from 'oxyrion-ui/lib/Table';
import styled from 'styled-components';
import { __ } from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  DivIconWrapper,
} from '../../Components/ReusableComponents';
import CheckAccess from '../../ACL/CheckAccess';
import { connect } from '../../Store';
import customerTypes from '../../ContentConfig/customerTypes';
import LanguageSwitch from '../../Components/LanguageSwitch';
import API2 from '../../API2';
import AddNewTurnoverDiscount from '../../Components/AddNewTurnoverDiscount';

const Wrapper = styled.div`
  padding: 0 ${rem(15)};
  margin: ${rem(30)} 0;
  td,
  th {
    padding-left: 0;
  }
  tbody tr:last-of-type {
    border-bottom: none;
  }
`;

const TableWrapper = styled.div`
  margin: ${rem(10)};
`;

const CustomTh = styled(Th)`
  padding-right: ${rem(10)};
  :last-of-type {
    width: 100%;
  }
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
`;

class TurnoverDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLang: 'SK',
      unregisteredDiscounts: [],
      firmDiscount: [],
      customerDiscounts: [],
      from: 0,
      customerFrom: 0,
      over: 0,
      buttonLoading: false,
      unregisteredButtonLoading: false,
      customerButtonLoading: false,
      unregisteredDiscountError: '',
      discountError: '',
      customerDiscountError: '',
      customerTypesCompany: customerTypes
        .filter((c) => c.type === 'company')
        .map((type) => {
          return type.name;
        }),
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  async fetchData(params = {}) {
    try {
      const { selectedLang } = this.state;
      const parameters = Object.assign({}, params, {
        type: 'turnover_discount',
        sorter: 'created_date',
      });
      const result = await API2.getDiscountsAction(selectedLang, parameters);

      const customerDiscounts = [];

      result.discounts.forEach((discount) => {
        customerDiscounts.push(discount);
      });

      this.setState({
        customerDiscounts,
        discountError: '',
      });
    } catch (e) {
      this.setState({
        discountError: __('Nepodarilo sa načítať zľavy'),
        customerDiscounts: [],
      });
    }
  }

  async addCustomerDiscount(amount, percent, q1, q2, q3, q4) {
    try {
      const { selectedLang } = this.state;

      this.setState({
        customerButtonLoading: true,
        customerDiscountError: '',
      });
      const dateNowAddYear = new Date();
      dateNowAddYear.setFullYear(dateNowAddYear.getFullYear() + 1);

      const params = {
        name: 'unnamed',
        valid_from: new Date().toISOString(),
        valid_to: dateNowAddYear.toISOString(),
        // purchase_from: {
        //   amount: Number(from),
        //   currency:
        //     selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        // },
        purchase_to: {
          amount: Number(amount),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        purchase_q1: {
          amount: Number(q1),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        purchase_q2: {
          amount: Number(q2),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        purchase_q3: {
          amount: Number(q3),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        purchase_q4: {
          amount: Number(q4),
          currency:
            selectedLang === 'SK' || selectedLang === 'AT' ? 'EUR' : 'CZK',
        },
        discount: Number(percent),
        type: 'turnover_discount',
        customer_types: [
          {
            type: 'B2B',
          },
        ],
      };

      await API2.postDiscountAction(selectedLang, { body: params });
    } catch (e) {
      this.setState({ discountError: __('Nepodarilo sa pridať zľavu') });
    }

    setTimeout(() => {
      this.fetchData();
      this.setState({
        customerButtonLoading: false,
      });
    }, 1000);
  }

  async removeCustomerDiscount(id) {
    try {
      const { selectedLang } = this.state;
      await API2.deleteDiscountAction(selectedLang, id);

      setTimeout(() => {
        this.fetchData();
      }, 500);
    } catch (e) {
      this.setState({
        customerDiscountError: __('Nepodarilo sa vykonať akciu'),
      });
    }
  }

  changeLanguage(id) {
    this.setState(
      {
        selectedLang: id,
      },
      () => {
        this.fetchData();
      },
    );

    return true;
  }

  render() {
    const {
      customerDiscounts,
      customerButtonLoading,
      customerDiscountError,
      selectedLang,
    } = this.state;
    return (
      <React.Fragment>
        <Wrapper>
          <Row>
            <LanguageSwitch
              onChange={(id) => {
                return this.changeLanguage(id);
              }}
              activeId={selectedLang}
            />
          </Row>
          {/* <H2 margin={`0 0 0 ${rem(10)}`}>{__('Súkromná osoba')}</H2> */}
          <TableWrapper>
            <AnimatedFormMessageWrapper display={customerDiscountError}>
              <Message error message={customerDiscountError} />
            </AnimatedFormMessageWrapper>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Obratová zľava')}</Th>
                  <Th>{__('Zľava v %')}</Th>
                  <Th>{__('Q1 obrat')}</Th>
                  <Th>{__('Q2 obrat')}</Th>
                  <Th>{__('Q3 obrat')}</Th>
                  <Th>{__('Q4 obrat')}</Th>
                  <CustomTh />
                </Tr>
              </Thead>
              <Tbody>
                {customerDiscounts.map((item) => (
                  <Tr>
                    <Td>{item.purchase_to.amount}</Td>
                    <Td>{`${item.discount} %`}</Td>
                    <Td>{item.purchase_q1.amount}</Td>
                    <Td>{item.purchase_q2.amount}</Td>
                    <Td>{item.purchase_q3.amount}</Td>
                    <Td>{item.purchase_q4.amount}</Td>
                    <Td>
                      <CheckAccess
                        operation="deleteDiscountAction"
                        Component={
                          <DivIconWrapper
                            onClick={() =>
                              this.removeCustomerDiscount(item._id)
                            }
                          >
                            <Icon name="close" color={baseTheme.color.danger} />
                          </DivIconWrapper>
                        }
                      />
                    </Td>
                  </Tr>
                ))}
                <AddNewTurnoverDiscount
                  //from={customerFrom}
                  onSubmit={(amount, percent, q1, q2, q3, q4) =>
                    this.addCustomerDiscount(amount, percent, q1, q2, q3, q4)
                  }
                  buttonLoading={customerButtonLoading}
                />
              </Tbody>
            </Table>
          </TableWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(TurnoverDiscount);
