import { __ } from '../Utils';

export default [
  {
    name: __('Meno používatela'),
    clickable: false,
  },
  {
    name: __('Asistent'),
    clickable: false,
  },
  {
    name: __('Dátum poslednej správy'),
    clickable: false,
  },
];
