import React from 'react';
import styled from 'styled-components';
import { Select, Button } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import { __ } from '../../../Utils';
import { connect } from '../../../Store';
import SearchableSelect from '../../../Components/SearchableSelect';
import {
  searchColorCards,
  searchUltraLiteCatalogProducts,
} from '../../../Utils/ApiSearchFunctions';
import API2 from '../../../API2';

const ExportRow = styled.div`
  padding: ${rem(24)};

  .label {
    font-size: ${rem(16)};
    font-weight: bold;
    margin-bottom: ${rem(12)};
  }

  .row {
    display: flex;
    align-items: center;
  }

  .space {
    width: ${rem(12)};
    height: ${rem(12)};
  }

  .search-select-wrapper {
    width: ${rem(300)};
  }
`;

class Exports extends React.Component {
  state = {
    lang: 'SK',
    exportLoading: false,
    product: null,
    colorcard: null,
  };

  async downloadFile() {
    const { lang, product, colorcard } = this.state;

    try {
      this.setState({
        exportLoading: true,
      });

      const file = await API2.getProductCatalogTintingPricesFileAction(
        lang,
        product.value,
        colorcard.value,
      );

      window.location.href = file.url;
      this.setState({
        exportLoading: false,
      });
    } catch (e) {
      this.setState({
        error: true,
      });
    }
  }

  handleOnChange(field, value) {
    if (field === 'product') {
      this.setState({
        product: value,
      });
    } else if (field === 'colorcard') {
      this.setState({
        colorcard: value,
      });
    }
  }

  render() {
    const { lang, exportLoading, product, colorcard } = this.state;

    return (
      <div>
        <ExportRow>
          <div className="row">
            <Select
              style={{ width: '300px' }}
              size="s"
              onChange={(e) =>
                this.setState({
                  lang: e.target.value,
                })
              }
            >
              <option selected={lang === 'SK'} value="SK">
                SK
              </option>
              <option selected={lang === 'CZ'} value="CZ">
                CZ
              </option>
            </Select>

            <div className="space" />
            <div className="search-select-wrapper">
              <SearchableSelect
                value={product}
                loadOptions={(query) => {
                  return searchUltraLiteCatalogProducts(
                    query,
                    null,
                    lang,
                    true,
                  );
                }}
                placeholder={__('Vyhľadať produkt')}
                name={product}
                handleOnChange={(e) => this.handleOnChange('product', e)}
              />
            </div>

            <div className="space" />
            <div className="search-select-wrapper">
              <SearchableSelect
                value={colorcard}
                loadOptions={(query) => {
                  return searchColorCards(query, lang);
                }}
                placeholder={__('Vyhľadať vzorkovník')}
                name={product}
                handleOnChange={(e) => this.handleOnChange('colorcard', e)}
              />
            </div>
            <div className="space" />
            <Button
              loading={exportLoading}
              onClick={() => this.downloadFile()}
              small
              primary
              disabled={
                !(product && product.value && colorcard && colorcard.value)
              }
            >
              {__('Exportovať')}
            </Button>
          </div>
        </ExportRow>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Exports);
