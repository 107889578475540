import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import Button from 'oxyrion-ui/lib/Button';
import { rem } from 'polished';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import React from 'react';
import styled from 'styled-components';
import ReactTable from 'react-table';
import AdminAPI from '../../AdminAPI';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Wrapper = styled.div`
  overflow: auto;
  margin-top: ${rem(12)};
  margin-bottom: ${rem(12)};
`;

const StyledHeader = styled.div`
  display: flex;
  margin-left: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #434245;
  margin-top: 15px;
`;

const StyledTd = styled.div`
  display: flex;
  margin: 8px;
  margin: 8px;
  font-size: 11px;
`;

const LabeledCheckboxWrapper = styled.div`
  margin-right: ${rem(8)};
`;

const LabeledCheckboxWrapperPopup = styled.div`
  margin-bottom: ${rem(8)};
`;

const TableWrapper = styled.div`
  min-width: ${rem(1600)};
`;

const ShowAllVariants = styled.div`
  color: ${(props) => props.theme.color.primary};
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;

const VariantsWrapper = styled.div`
  min-height: ${rem(480)};
  max-height: ${rem(1000)};
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: ${rem(20)};
  padding-bottom: ${rem(0)};
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${rem(20)};
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const acceptedMimeTypesToUpload = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

class OnExternalStock extends React.Component {
  state = {
    products: [],
    changedProducts: [],
    error: null,
  };

  componentDidMount() {
    this.fetchProducts();
  }

  async fetchProducts() {
    this.setState({
      loading: true,
    });

    try {
      const { items: products } = await AdminAPI.getAllCatalogProductsAction({
        sorter: 'name',
      });

      this.setState({
        loading: false,
        products,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: __('Produkty sa nepodarilo načítať'),
      });
    }
  }

  async handleExportButton() {
    try {
      this.setState({
        exportLoading: true,
      });

      const result = await AdminAPI.getExportProductsExternalStocksAction();

      this.setState({
        showDownloadDialog: true,
        downloadId: result.url,
        exportLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Produkty sa nepodarilo vyexportovať'),
      });
    }
  }

  getUploadParams = ({ file }) => {
    const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/external-stock/import`;
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();
    formData.append('file', file);

    return {
      url: fileUploadURL,
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };

  onCloseUploadPopUp = () => {
    this.fetchProducts();
    this.setState({ showImportDialog: false });
  };

  handleVariantCheckboxClick(productId, variantId, value) {
    const { products, changedProducts } = this.state;

    changedProducts.push(productId);

    const variant = products
      .find((p) => p._id === productId)
      .variants.find((v) => v._id === variantId);

    if (variant && variant.supplier_on_stock) {
      variant.supplier_on_stock.on_stock = value;
    } else {
      variant.supplier_on_stock = {
        on_stock: value,
      };
    }

    this.setState({
      products,
      changedProducts,
    });
  }

  async handleSaveButton() {
    try {
      const { products, changedProducts } = this.state;

      this.setState({
        saveLoading: true,
      });

      const _products = products
        .filter((p) => changedProducts.includes(p._id))
        .reduce((acum, curr) => {
          const d = curr.variants.map((v) => ({
            product_id: v._id,
            on_stock: v.supplier_on_stock
              ? v.supplier_on_stock.on_stock
              : false,
          }));

          return acum.concat(d);
        }, []);

      await AdminAPI.putProductsStockStatusAction({
        body: {
          products: _products,
        },
      });

      this.setState({
        saveLoading: false,
        error: false,
        success: __('Dáta úspešne uložené'),
      });
    } catch (e) {
      this.setState({
        saveLoading: false,
        success: false,
        error: __('Dáta sa nepodarilo uložiť'),
      });
    }
  }

  downloadFileWithId() {
    window.open(this.state.downloadId, '_blank');
    this.setState({
      downloadId: undefined,
      showDownloadDialog: false,
    });
  }

  createColumns() {
    const result = [
      {
        accessor: 'name',
        Header: () => <StyledHeader>{__('Názov produktu')}</StyledHeader>,
        maxWidth: 300,
        Cell: (props) => {
          return <StyledTd>{props.value}</StyledTd>;
        },
      },
      {
        accessor: 'variants',
        Header: () => <StyledHeader>{__('Varianty')}</StyledHeader>,
        Cell: (props) => {
          return (
            <StyledTd>
              {props.value.slice(0, 5).map((v) => (
                <LabeledCheckboxWrapper>
                  <LabeledCheckbox
                    labelText={
                      v.name
                        .toLowerCase()
                        .replace(props.original.name.toLowerCase(), '') ||
                      v.name
                    }
                    onChange={(e) =>
                      this.handleVariantCheckboxClick(
                        props.original._id,
                        v._id,
                        e.target.checked,
                      )
                    }
                    checked={
                      (v.supplier_on_stock &&
                        v.supplier_on_stock.on_stock === true) ||
                      false
                    }
                  />
                </LabeledCheckboxWrapper>
              ))}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'variants',
        Header: () => <StyledHeader />,
        maxWidth: 200,
        Cell: (props) => {
          return (
            <StyledTd>
              {/* {props.value.length > 5 ? ( */}
              <ShowAllVariants
                onClick={() =>
                  this.setState({
                    productVariantsToShow: props.original._id,
                  })
                }
              >
                {`${__('Zobraziť všetky varianty')} (${props.value.length})`}
              </ShowAllVariants>
              {/* ) : (
                `${props.value.length} variant`
              )} */}
            </StyledTd>
          );
        },
      },
    ];

    return result;
  }

  useCustomheader(columns) {
    return columns.map((i) => ({
      Header: () => <div>{i.name}</div>,
      ...i,
    }));
  }

  render() {
    const { history } = this.props;

    const {
      products,
      loading,
      error,
      success,
      productVariantsToShow,
      saveLoading,
      showDownloadDialog,
      exportLoading,
      showImportDialog,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <div>
        <PopUpWrapper
          display={showImportDialog}
          small
          message={__('Import excel dokumentu')}
          onClose={() => this.onCloseUploadPopUp()}
        >
          <Dropzone
            files={[]}
            getUploadParams={(e) => this.getUploadParams(e)}
            onChangeStatus={this.handleChangeStatus}
            accept={acceptedMimeTypesToUpload.join(',')}
            inputContent={__('Nahrať excel dokument')}
            canCancel={false}
            initialFiles={[]}
            styles={{
              inputLabel: { color: '#ee6500' },
            }}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showDownloadDialog}
          small
          message={__('Sťiahnúť súbor')}
          onClose={() => this.setState({ showDownloadDialog: false })}
        >
          <ConfirmDialog
            message={__('Želáte si stiahnúť zvolený súbor ?')}
            onDismiss={() => this.setState({ showDownloadDialog: false })}
            onConfirm={() => this.downloadFileWithId()}
          />
        </PopUpWrapper>

        <PopUpWrapper
          small
          display={productVariantsToShow}
          onClose={() =>
            this.setState({
              productVariantsToShow: undefined,
            })
          }
          message={`${__('Varianty produktu')} ${
            productVariantsToShow &&
            products.find((p) => p._id === productVariantsToShow).name
          }`}
          onEscClose={() =>
            this.setState({
              productVariantsToShow: undefined,
            })
          }
        >
          <VariantsWrapper>
            {products.find((p) => p._id === productVariantsToShow) &&
              products
                .find((p) => p._id === productVariantsToShow)
                .variants.map((v) => (
                  <LabeledCheckboxWrapperPopup>
                    <LabeledCheckbox
                      onChange={(e) =>
                        this.handleVariantCheckboxClick(
                          productVariantsToShow,
                          v._id,
                          e.target.checked,
                        )
                      }
                      labelText={v.name}
                      checked={
                        (v.supplier_on_stock &&
                          v.supplier_on_stock.on_stock === true) ||
                        false
                      }
                    />
                  </LabeledCheckboxWrapperPopup>
                ))}
          </VariantsWrapper>
          <PopupButtonWrapper>
            <Button
              onClick={() => {
                this.handleSaveButton();
                this.setState({
                  productVariantsToShow: undefined,
                });
              }}
              primary
            >
              {__('Uložiť a pokračovať')}
            </Button>
          </PopupButtonWrapper>
        </PopUpWrapper>

        <ControllBar
          history={history}
          name={__('Produkty skladom u dodávateľa')}
        >
          <ControlBarButton
            small
            onClick={() =>
              this.setState({
                showImportDialog: true,
              })
            }
            icon="plus"
          >
            {__('Importovať')}
          </ControlBarButton>
        </ControllBar>

        <Wrapper>
          {error && <Message error message={error} />}
          {success && <Message message={success} />}

          <TableWrapper>
            <ReactTableFixedColumns
              columns={this.useCustomheader(this.createColumns())}
              resizable={false}
              data={products || []}
              pageSize={50}
              showPageSizeOptions={false}
              pageText={__('Strana')}
              ofText={__('z')}
              previousText={__('Späť')}
              nextText={__('Ďalej')}
              getTdProps={() => {
                return { style: { padding: 0, margin: 'auto' } };
              }}
            />
          </TableWrapper>
          <PopupButtonWrapper>
            <Button
              loading={saveLoading}
              onClick={() => this.handleSaveButton()}
              primary
            >
              {__('Uložiť')}
            </Button>
            <Space />
            <Button
              loading={exportLoading}
              onClick={() => this.handleExportButton()}
              primary
            >
              {__('Exportovať')}
            </Button>
          </PopupButtonWrapper>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(OnExternalStock);
