import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Label,
  Loader,
  Message,
  Select,
  Textarea,
} from 'oxyrion-ui/lib';
import { __, getIdFromProps } from '../../Utils';
import { connect } from '../../Store';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';

import ControllBar from '../../Components/ControllBar';
import API2 from '../../API2';
import { rem } from 'polished';
import GreyBox from '../../Components/GreyBox';
import { fetchGraphics } from '../../Utils/ApiSearchFunctions';
import SearchableSelect from '../../Components/SearchableSelect';
import LanguageSwitch from '../../Components/LanguageSwitch';

const photoBasePath = `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/0x0`;

const types = [
  {
    name: __('dni'),
    value: 'days',
  },
  {
    name: __('mesiace'),
    value: 'months',
  },
];

class BrandDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      newContact: '',
      activeLanguage: 'SK',
    };
  }

  async componentDidMount() {
    const id = getIdFromProps(this.props);
    await this.fetchBrand(id);
  }

  async fetchBrand(id) {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getBrandAction(this.props.firm, id);

      this.setState({
        data: result,
        loading: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie značiek nemáte potrebné práva.'),
            loading: false,
          });
          break;
        case 404:
          this.setState({
            error: __('Dáta sa nenašli'),
            loading: false,
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
            loading: false,
          });
          break;
      }
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar
        history={history}
        name={`${__('Značka')} ${this.state.data ? this.state.data.brand : ''}`}
      />
    );
  }

  getBrandLogoSrc(multimedium) {
    if (!multimedium) {
      return '';
    }

    const main = multimedium.files[0];

    if (!main) {
      return '';
    }

    return `${photoBasePath}/${main.value.fullPath}`;
  }

  onDeliveryTermsChange(value, field) {
    const { data } = this.state;

    if (!data.delivery_terms) {
      data.delivery_terms = {};
    }

    data.delivery_terms[field] = value;

    this.setState({ data });
  }

  handleMultimediaChange(e) {
    const { data } = this.state;

    const newData = Object.assign(data, {
      multimedia_id: e,
    });

    this.setState({
      data: newData,
    });
  }

  handleContactChange(value, index) {
    const { data, activeLanguage } = this.state;

    if (index >= 0) {
      data.contacts.find((c) => c.lang === activeLanguage).value[index] = value;
      data.contacts.find((c) => c.lang === activeLanguage).value = data.contacts
        .find((c) => c.lang === activeLanguage)
        .value.filter((c) => c.length > 0);

      this.setState({ data });
    } else {
      this.setState({
        newContact: value,
      });
    }
  }

  saveNewContact() {
    const { data, newContact, activeLanguage } = this.state;

    if (newContact.length > 0) {
      if (!data.contacts) {
        data.contacts = [];
      }

      if (!data.contacts.find((c) => c.lang === activeLanguage)) {
        data.contacts.push({
          lang: activeLanguage,
          value: [],
        });
      }

      data.contacts
        .find((c) => c.lang === activeLanguage)
        .value.push(newContact);

      this.setState({
        data,
        newContact: '',
      });
    }
  }

  async saveBrand() {
    const { data } = this.state;

    try {
      this.setState({
        saveLoading: true,
      });

      const dataToSave = {
        delivery_terms: data.delivery_terms && {
          value: Number(data.delivery_terms.value),
          type: data.delivery_terms.type,
        },
        contacts: data.contacts || [],
        multimedia_id: data.multimedia_id && data.multimedia_id.value,
      };

      console.log('dataToSave---', dataToSave);

      await API2.putBrandAction(this.props.firm, data._id, {
        body: dataToSave,
      });

      this.setState({
        saveLoading: false,
        success: __('Dáta boli úspešne uložené'),
      });
    } catch (e) {
      this.setState({
        saveLoading: false,
        error: __('Lehotu dodania sa nepodarilo uložiť'),
      });
    }
  }

  changeLanguage(id) {
    this.setState({
      activeLanguage: id,
    });
  }

  render() {
    const {
      loading,
      error,
      data,
      newContact,
      saveLoading,
      success,
      activeLanguage,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        <Wrapper>
          <GreyBox title={__('Základné nastavenia')}>
            <div className="row">
              <div className="value-wrapper">
                <Label>{__('Názov značky')}</Label>
                <div className="input-wrapper">
                  <Input value={data.brand} onChange={() => {}} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="value-wrapper">
                <Label>{__('Logo')}</Label>
                <SearchableSelect
                  loadOptions={(query) => {
                    return fetchGraphics(query);
                  }}
                  handleOnChange={(e) => this.handleMultimediaChange(e)}
                  value={data.multimedia_id}
                  placeholder={__('Logo značky')}
                />
              </div>
              {data.multimedia_id && (
                <div className="image-wrapper">
                  <BrandLogo
                    src={this.getBrandLogoSrc(data.multimedia_id.raw)}
                  ></BrandLogo>
                </div>
              )}
            </div>

            <div className="row">
              <div className="value-wrapper">
                <Label>{__('Lehoty dodania')}</Label>
                <div className="row">
                  <SubWrapper>
                    <StyledSelect
                      size="s"
                      onChange={(e) =>
                        this.onDeliveryTermsChange(e.target.value, 'type')
                      }
                    >
                      {types.map((s) => (
                        <option
                          selected={
                            data.delivery_terms &&
                            s.value === data.delivery_terms.type
                          }
                          value={s.value}
                        >
                          {s.name}
                        </option>
                      ))}

                      <option disabled selected={!data.delivery_terms}>
                        -
                      </option>
                    </StyledSelect>
                  </SubWrapper>
                  <SubWrapper>
                    <Input
                      style={{ width: rem(50) }}
                      min="1"
                      type="number"
                      disabled={!data.delivery_terms}
                      onChange={(e) =>
                        this.onDeliveryTermsChange(e.target.value, 'value')
                      }
                      value={
                        (data.delivery_terms && data.delivery_terms.value) || 0
                      }
                    />
                  </SubWrapper>
                </div>
              </div>
            </div>

            <div className="row">
              <LanguageSwitch
                onChange={(id) => {
                  return this.changeLanguage(id);
                }}
                activeId={activeLanguage}
              />
            </div>

            <div className="row">
              <div className="value-wrapper">
                <Label>{__('Kontakt na výrobcu')}</Label>
                {data.contacts &&
                  data.contacts.find((c) => c.lang === activeLanguage) &&
                  data.contacts
                    .find((c) => c.lang === activeLanguage)
                    .value.map((c, index) => {
                      return (
                        <div className="input-wrapper">
                          <Textarea
                            value={c}
                            onChange={(e) => {
                              this.handleContactChange(e.target.value, index);
                            }}
                          />
                        </div>
                      );
                    })}
                <div className="input-wrapper">
                  <Textarea
                    placeholder={__('Zadajte nový kontakt')}
                    value={newContact}
                    onBlur={() => this.saveNewContact()}
                    onChange={(e) => {
                      this.handleContactChange(e.target.value, -1);
                    }}
                  />
                </div>
              </div>
            </div>
          </GreyBox>

          <SaveButtonWrapper>
            <Button
              onClick={() => this.saveBrand()}
              small
              loading={saveLoading}
              primary
            >
              {__('Uložiť')}
            </Button>
          </SaveButtonWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${rem(12)};
`;

const SubWrapper = styled.div`
  width: 100%;
  padding: ${rem(3)};

  ${({ theme }) => theme.media.m`
  width: 50%;
  `};
`;

const BrandLogo = styled.img`
  width: ${rem(200)};
`;

const Wrapper = styled.div`
  padding: ${rem(12)};
  max-width: ${rem(600)};

  .image-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-left: ${rem(6)};
    align-items: center;
    padding: ${rem(4)};
    border: 1px solid black;
  }

  .label {
    font-weight: bold;
    margin-bottom: ${rem(12)};
    font-size: ${rem(16)};
  }

  .row {
    display: flex;
    margin-bottom: ${rem(8)};
  }

  .input-wrapper {
    max-width: 95%;
  }

  .value-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  width: calc(100% - 10px);
`;

BrandDetail.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
BrandDetail.defaultProps = {
  firm: '',
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BrandDetail);
