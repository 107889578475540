import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import Label from 'oxyrion-ui/lib/Label/Label';
import Input from 'oxyrion-ui/lib/Input';
import Textarea from 'oxyrion-ui/lib/Textarea';
import Button from 'oxyrion-ui/lib/Button';
import Icon from 'oxyrion-ui/lib/Icon';
import { AnimatedFormMessageWrapper } from '../../ReusableComponents';
import { __ } from '../../../Utils';
import SearchableSelect from '../../SearchableSelect';
import AdminAPI from '../../../AdminAPI';
import MultipleInput from '../../MultipleInput';
import Checkbox from 'oxyrion-ui/lib/Checkbox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${rem(1)} solid #eee;
`;

const MainLabel = styled.div`
  height: ${rem(50)};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #eee;

  :hover {
    text-decoration: underline;
  }

  .name {
    font-weight: bold;
    margin-left: ${rem(12)};
  }

  .icon {
    transform: ${(props) => (props.open ? `rotate(-45deg)` : `rotate(45deg)`)};
    margin-right: ${rem(12)};
  }
`;

const MainContentWrapper = styled.div`
  padding: ${rem(24)};

  .row {
    display: flex;
  }

  .inputWrapper {
    width: ${rem(300)};
    margin-right: ${rem(32)};
  }

  .inputWrapperFull {
    width: 100%;
  }

  .inputWrapperSubtype {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: ${rem(8)};
  }

  .inputWrapperWide {
    width: ${rem(450)};
    margin-right: ${rem(32)};
  }

  .buttonWrapper {
    width: ${rem(125)};
    height: ${rem(40)};
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${rem(12)};
  }
`;

class FeeRow extends React.Component {
  state = {
    open: false,
    fee: undefined,
    newSubtype: this.initSubtype(),
  };

  initSubtype() {
    return {
      _id: '',
      name: '',
      allowed_swifts: [],
    };
  }

  async fetchFees() {
    try {
      return AdminAPI.getServiceFeesAction({
        type: 'payment',
        lang: this.props.lang,
      }).then((res) => {
        return res.fees.map((item) => ({
          value: item._id,
          label: item.label,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchCustomerTypes(q) {
    try {
      return AdminAPI.getCustomerTypesAction({
        q,
      }).then((res) => {
        return res.items.map((item) => ({
          value: item._id,
          label: `${item.name} (${item.type})`,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  handeTextDataChange(field, value) {
    const { fee: propsFee } = this.props;
    let { fee } = this.state;

    if (!fee) {
      fee = Object.assign({}, propsFee);
    }

    if (field === 'maxAmountMessage' && value === '') {
      fee.maxAmountMessage = null;
    } else {
      fee[field] = value;
    }

    this.setState({
      fee,
    });
  }

  handleOnChange(field, value) {
    const { fee: propsFee } = this.props;
    let { fee } = this.state;

    if (!fee) {
      fee = Object.assign({}, propsFee);
    }

    fee[field] = value;

    this.setState({
      fee,
    });
  }

  handleNewSubtypeChange(field, value) {
    const { newSubtype } = this.state;

    newSubtype[field] = value;

    this.setState({
      newSubtype,
    });
  }

  handleAddNewSubtype() {
    const { fee: propsFee } = this.props;
    let { fee } = this.state;
    const { newSubtype } = this.state;

    if (!fee) {
      fee = Object.assign({}, propsFee);
    }

    fee.subtypes.push(newSubtype);

    this.setState({
      fee,
      newSubtype: this.initSubtype(),
    });
  }

  handleRemoveSubtype(_id) {
    const { fee: propsFee } = this.props;
    let { fee } = this.state;

    if (!fee) {
      fee = Object.assign({}, propsFee);
    }

    fee.subtypes = fee.subtypes.filter((subtype) => {
      return subtype._id !== _id;
    });

    this.setState({
      fee,
    });
  }

  handleCustomerTypeChange(e) {
    const { fee: propsFee } = this.props;
    let { fee } = this.state;

    if (!fee) {
      fee = Object.assign({}, propsFee);
    }

    fee.customer_types = e;

    this.setState({
      fee,
    });
  }

  handlePaymentsChange(e) {
    const { fee: propsFee } = this.props;
    let { fee } = this.state;

    if (!fee) {
      fee = Object.assign({}, propsFee);
    }

    fee.payments = e;

    this.setState({
      fee,
    });
  }

  handeNumberDataChange(field, value) {
    const { fee: propsFee } = this.props;
    let { fee } = this.state;

    if (!fee) {
      fee = Object.assign({}, propsFee);
    }

    if (isNaN(Number(value.replace(',', '.'))) && value !== '') {
      return;
    }

    if (field === 'unit_price.amount_vat_excl') {
      fee.unit_price.amount_vat_excl = Number(value.replace(',', '.'));
      fee.unit_price.amount_vat_excl_wd = Number(value.replace(',', '.'));
    } else if (field === 'unit_price.amount') {
      fee.unit_price.amount = Number(value.replace(',', '.'));
      fee.unit_price.amount_wd = Number(value.replace(',', '.'));
    } else {
      fee[field] = value;
    }

    this.setState({
      fee,
    });
  }

  render() {
    const {
      fee: propFee,
      onSave,
      onDelete,
      showAddPayments,
      saveLoading,
      type,
    } = this.props;
    const { open: opeState, fee: localFee, newSubtype } = this.state;

    const fee = localFee || propFee;
    const open = opeState || !fee._id;

    return (
      <Wrapper>
        <MainLabel
          open={open}
          onClick={() =>
            this.setState({
              open: !open,
            })
          }
        >
          <div className="name">{propFee.label}</div>
          <div className="icon">
            <Icon name="arrow-right" />
          </div>
        </MainLabel>

        <AnimatedFormMessageWrapper display={open}>
          <MainContentWrapper>
            <div className="content">
              <div className="row">
                <div className="inputWrapper">
                  <Label>{__('Názov')}</Label>
                  <Input
                    value={fee.label}
                    placeholder={__('Názov')}
                    onChange={(e) =>
                      this.handeTextDataChange('label', e.target.value)
                    }
                  />
                </div>

                {type === 'delivery' && fee.shop_id && (
                  <div className="inputWrapper">
                    <Label>{__('Názov predajne pre mailovú šablónu')}</Label>
                    <Input
                      value={fee.shop_id}
                      placeholder={__('Názov predajne pre mailovú šablónu')}
                      disabled
                      onChange={(e) =>
                        this.handeTextDataChange('shop_id', e.target.value)
                      }
                    />
                  </div>
                )}

                <div className="inputWrapper">
                  <Label>{__('Kód')}</Label>
                  <Input
                    value={fee.name}
                    placeholder={__('Kód')}
                    disabled
                    onChange={(e) =>
                      this.handeTextDataChange('name', e.target.value)
                    }
                  />
                </div>

                <div className="inputWrapper">
                  <Label>{__('Identifikátor')}</Label>
                  <Input
                    value={fee.item_no}
                    placeholder={__('Identifikátor')}
                    onChange={(e) =>
                      this.handeTextDataChange('name', e.target.value)
                    }
                  />
                </div>

                <div className="inputWrapper">
                  <Label>{__('Publikované')}</Label>

                  <Checkbox
                    checked={fee.activated}
                    onChange={() =>
                      this.handleOnChange('activated', !fee.activated)
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputWrapper">
                  <Label>{__('Cena s DPH')}</Label>
                  <Input
                    value={fee.unit_price.amount}
                    placeholder={__('Cena')}
                    onChange={(e) =>
                      this.handeNumberDataChange(
                        'unit_price.amount',
                        e.target.value,
                      )
                    }
                  />
                </div>

                <div className="inputWrapper">
                  <Label>{__('Cena bez DPH')}</Label>
                  <Input
                    value={fee.unit_price.amount_vat_excl}
                    placeholder={__('Cena')}
                    onChange={(e) =>
                      this.handeNumberDataChange(
                        'unit_price.amount_vat_excl',
                        e.target.value,
                      )
                    }
                  />
                </div>

                {type === 'payment' && (
                  <div className="inputWrapper">
                    <Label>{__('Automatické zasielanie faktúry')}</Label>

                    <Checkbox
                      checked={fee.send_invoice_automatically}
                      onChange={() =>
                        this.handleOnChange(
                          'send_invoice_automatically',
                          !fee.send_invoice_automatically,
                        )
                      }
                    />
                  </div>
                )}
              </div>

              <div className="row">
                <div className="inputWrapper">
                  <Label>{__('Informácia k platbe')}</Label>
                  <Textarea
                    style={{ height: rem(103) }}
                    value={fee.under_info}
                    onChange={(e) =>
                      this.handeTextDataChange('under_info', e.target.value)
                    }
                  />
                </div>

                <div className="inputWrapper">
                  <Label>{__('Typ zákazníka')}</Label>
                  <SearchableSelect
                    value={fee.customer_types}
                    isMulti
                    loadOptions={(query) => {
                      return this.fetchCustomerTypes(query);
                    }}
                    placeholder={fee.customer_types}
                    handleOnChange={(e) => this.handleCustomerTypeChange(e)}
                  />
                </div>
              </div>

              {showAddPayments && (
                <div className="row">
                  <div className="inputWrapper">
                    <Label>{__('Platobné metódy')}</Label>
                    <SearchableSelect
                      value={fee.payments}
                      isMulti
                      placeholder={__('Platobné metódy')}
                      loadOptions={() => {
                        return this.fetchFees();
                      }}
                      handleOnChange={(e) => this.handlePaymentsChange(e)}
                    />
                  </div>
                </div>
              )}

              <div className="row">
                <div className="inputWrapper">
                  <Label>{__('Maximálna možná cena')}</Label>
                  <Input
                    type="number"
                    value={fee.maxAmount}
                    placeholder={__('Maximálna možná cena')}
                    onChange={(e) =>
                      this.handeNumberDataChange('maxAmount', e.target.value)
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="inputWrapper">
                  <Label>{__('Maximálna možná cena - informácia')}</Label>
                  <Textarea
                    type="number"
                    style={{ width: rem(400), height: rem(103) }}
                    value={fee.maxAmountMessage}
                    placeholder={__('Maximálna možná cena - informácia')}
                    onChange={(e) =>
                      this.handeTextDataChange(
                        'maxAmountMessage',
                        e.target.value,
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="inputWrapperFull">
                <Label>{__('Podtypy platby')}</Label>
                {fee &&
                  fee.subtypes &&
                  fee.subtypes.map((subtype) => {
                    return (
                      <div className="inputWrapperSubtype">
                        <div className="inputWrapper">
                          <Input value={subtype.name} disabled />
                        </div>
                        <div className="inputWrapper">
                          <Input value={subtype._id} disabled />
                        </div>
                        <div className="inputWrapperWide">
                          <Input value={subtype.allowed_swifts} disabled />
                        </div>
                        <Button
                          small
                          danger
                          onClick={() => this.handleRemoveSubtype(subtype._id)}
                        >
                          {__('Odstrániť')}
                        </Button>
                      </div>
                    );
                  })}
                <div className="inputWrapperSubtype">
                  <div className="inputWrapper">
                    <Input
                      value={newSubtype.name}
                      placeholder={__('Názov')}
                      onChange={(e) =>
                        this.handleNewSubtypeChange('name', e.target.value)
                      }
                    />
                  </div>
                  <div className="inputWrapper">
                    <Input
                      value={newSubtype._id}
                      placeholder="_id"
                      onChange={(e) =>
                        this.handleNewSubtypeChange('_id', e.target.value)
                      }
                    />
                  </div>
                  <div className="inputWrapperWide">
                    <MultipleInput
                      onChange={(values) =>
                        this.handleNewSubtypeChange('allowed_swifts', values)
                      }
                      placeholder="Povolený swift kód"
                      value={newSubtype.allowed_swifts}
                    />
                  </div>

                  <div className="buttonWrapper">
                    <Button
                      small
                      primary
                      onClick={() => this.handleAddNewSubtype()}
                      disabled={
                        !newSubtype.name ||
                        !newSubtype._id ||
                        !newSubtype.allowed_swifts.length > 0
                      }
                    >
                      {__('Pridať podtyp')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="buttons">
              <Button small danger onClick={() => onDelete(fee._id)}>
                {__('Odstrániť')}
              </Button>
              <Button
                loading={saveLoading}
                small
                primary
                onClick={() => onSave(fee)}
              >
                {fee._id ? __('Uložiť zmeny') : __('Vytvoriť')}
              </Button>
            </div>
          </MainContentWrapper>
        </AnimatedFormMessageWrapper>
      </Wrapper>
    );
  }
}

FeeRow.propTypes = {
  fee: PropTypes.shape({}),
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  lang: PropTypes.string,
  showAddPayments: PropTypes.bool,
};

FeeRow.defaultProps = {
  fee: [],
  lang: 'SK',
  onSave: () => console.error('onSave not implemented'),
  onDelete: () => console.error('onDelete not implemented'),
  showAddPayments: false,
};

export default FeeRow;
