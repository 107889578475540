import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import UserMenu from '../../Components/NavBar/UserMenu';
import ControlMenu from '../../Components/NavBar/ControlMenu';
import Search from '../../Components/NavBar/Search';
import { actions } from '../../Store';
import { __, removeFirmFromUrl, getContactFullName } from '../../Utils';
import SearchResults from '../../Components/NavBar/SearchResults';
import API2 from '../../API2';

const Nav = styled.nav`
  top: 0;
  z-index: 5;
  position: sticky;
  border-bottom: solid ${rem(1)} ${({ theme }) => theme.separatorColor};
  width: 100%;
  height: ${rem(50)};
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.color.defaultBg || 'white'};
  margin: auto;
`;

const Logo = styled.div`
  display: none;
  margin: 0 ${rem(10)}
    ${({ theme }) => theme.media.l`
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    const content = this.props.content.map((element) => ({
      name: element.name,
      checkAccess: element.checkAccess,
      expanded: element.expanded || false,
      dropdown: element.dropdown,
    }));

    this.defaultSerachErrorMessage = __(
      'Pri načítavaní dát sa vyskytla chyba.',
    );

    this.state = {
      content,
      search: false,
      wasSearched: false,
      showCreateActivity: false,
      showCreateTask: false,
      showCreateContact: false,
      showCreateLead: false,
      customers: {
        header: __('Aktuálni zákazníci'),
        path: `/${props.firm}/customers`,
        data: [],
      },
      contacts: {
        header: __('Kontakty'),
        path: `/${props.firm}/contacts`,
        data: [],
      },
      leads: {
        header: __('Potenciálni zákazníci'),
        path: `/${props.firm}/leads`,
        data: [],
      },
      competitors: {
        header: __('Konkurenti'),
        path: `/${props.firm}/competitors`,
        data: [],
      },
      opportunities: {
        header: __('Príležitosti'),
        path: `/${props.firm}/opportunities`,
        data: [],
      },
      products: {
        header: __('Produkty'),
        path: `/${props.firm}/products`,
        data: [],
      },
      users: {
        header: __('Používatelia'),
        path: `/${props.firm}/users`,
        data: [],
      },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', (e) => this.handleOutsideClick(e));
    document.addEventListener('keydown', (e) => this.handleKeyDown(e));
  }

  onPlusClick(category) {
    switch (category) {
      case 'activity':
        this.toogleCreateActivitiy();
        break;
      case 'task':
        this.toogleCreateTask();
        break;
      case 'contact':
        this.toogleCreateContact();
        break;
      case 'lead':
        this.toogleCreateLead();
        break;
      default:
        break;
    }
  }

  handleOutsideClick(event) {
    if (this.searchWrapper && !this.searchWrapper.contains(event.target)) {
      this.closeSearch();
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.closeSearch();
    }
  }

  handleItemOnclick(index) {
    const { content } = this.state;
    content[index].expanded = !content[index].expanded;
    this.setState({ content });
  }

  handleSearch() {
    this.setState({
      search: !this.state.search,
      displaySearch: this.state.searchValue && this.state.searchValue.length,
      wasSearched: this.state.search,
    });
  }

  handleLogOut() {
    actions.setLoggingOut(true);
    actions.loggout();
  }

  async handleOnCoutryClick(value) {
    actions.setFirm(value);

    try {
      const vatResult = await API2.getVatInfoAction(value);
      actions.setVatRate(vatResult.vat_rate);
    } catch (e) {
      throw new Error(
        __('Pri nastavení štartovacej stránky sa vyskytla chyba.'),
      );
    }
  }

  handleOnYearClick(value) {
    actions.setYear(value);
    window.location.reload();
  }

  handleInputOnChange(value) {
    window.clearTimeout(this.currentFetchTimeout);
    this.currentFetchTimeout = window.setTimeout(() => {
      this.setState({ displaySearch: true, searchValue: value });
      this.search(value);
    }, 500);
  }

  toogleCreateActivitiy() {
    this.setState({ showCreateActivity: !this.state.showCreateActivity });
  }

  toogleCreateTask() {
    this.setState({ showCreateTask: !this.state.showCreateTask });
  }

  toogleCreateContact() {
    this.setState({ showCreateContact: !this.state.showCreateContact });
  }

  toogleCreateLead() {
    this.setState({ showCreateLead: !this.state.showCreateLead });
  }

  closeSearch() {
    this.setState({ search: false, displaySearch: false });
  }

  async pinPage() {
    try {
      const { settings } = this.props;
      const pathName = window.location.pathname;
      const url = removeFirmFromUrl(pathName);

      await API2.postUserSettingAction({
        body: {
          name: 'startPageAdmin',
          value: url,
        },
      });

      actions.setSettings({ ...settings, startPageAdmin: url });
    } catch (e) {
      throw new Error(
        __('Pri nastavení štartovacej stránky sa vyskytla chyba.'),
      );
    }
  }

  async search(value) {
    const {
      customers,
      contacts,
      leads,
      competitors,
      opportunities,
      products,
      users,
    } = this.state;
    this.setState({
      customers: { ...customers, loading: true, data: [], error: '' },
      contacts: { ...contacts, loading: true, data: [], error: '' },
      leads: { ...leads, loading: true, data: [], error: '' },
      competitors: { ...competitors, loading: true, data: [], error: '' },
      opportunities: { ...opportunities, loading: true, data: [], error: '' },
      products: { ...products, loading: true, data: [], error: '' },
      users: { ...users, loading: true, data: [], error: '' },
    });

    this.fetchCustomers(value);
    this.fetchContacts(value);
    this.fetchLeads(value);
    this.fetchCompetitors(value);
    this.fetchOpportunities(value);
    this.fetchProducts(value);
    this.fetchUsers(value);
  }

  async fetchCustomers(value) {
    try {
      const { customers } = this.state;
      const { firm } = this.props;
      const result = await API2.getCustomersAction(firm, {
        limit: 5,
        q: value,
      });
      this.setState({
        customers: {
          ...customers,
          data: result.items.map((item) => ({
            name: item.name,
            id: item._id,
          })),
          loading: false,
          path: `/${firm}/customers`,
        },
      });
    } catch (e) {
      const { customers } = this.state;
      const errorMessage = this.defaultSerachErrorMessage;
      if (e.response) {
        switch (e.response.status) {
          default:
            break;
        }
      }
      this.setState({
        customers: { ...customers, error: errorMessage, loading: false },
      });
    }
  }

  async fetchContacts(value) {
    try {
      const { contacts } = this.state;
      const { firm } = this.props;
      const result = await API2.getContactsAction(firm, {
        limit: 5,
        q: value,
      });
      this.setState({
        contacts: {
          ...contacts,
          data: result.items.map((item) => ({
            name: getContactFullName(item),
            id: item._id,
          })),
          loading: false,
          path: `/${firm}/contacts`,
        },
      });
    } catch (e) {
      const { contacts } = this.state;
      const errorMessage = this.defaultSerachErrorMessage;
      if (e.response) {
        switch (e.response.status) {
          default:
            break;
        }
      }
      this.setState({
        contacts: { ...contacts, error: errorMessage, loading: false },
      });
    }
  }

  async fetchUsers(value) {
    try {
      const { users } = this.state;
      const { firm } = this.props;
      const result = await API2.getAllUsersAction({
        limit: 5,
        q: value,
      });
      this.setState({
        users: {
          ...users,
          data: result.users.map((item) => ({
            name: item.display_name,
            id: item._id,
          })),
          loading: false,
          path: `/${firm}/users`,
        },
      });
    } catch (e) {
      const { users } = this.state;
      const errorMessage = this.defaultSerachErrorMessage;
      if (e.response) {
        switch (e.response.status) {
          default:
            break;
        }
      }
      this.setState({
        users: { ...users, error: errorMessage, loading: false },
      });
    }
  }

  async fetchLeads(value) {
    try {
      const { leads } = this.state;
      const { firm } = this.props;
      const result = await API2.getLeadsAction(firm, {
        limit: 5,
        q: value,
      });
      this.setState({
        leads: {
          ...leads,
          data: result.items.map((item) => ({
            name: item.name,
            id: item._id,
          })),
          loading: false,
          path: `/${firm}/leads`,
        },
      });
    } catch (e) {
      const { leads } = this.state;
      const errorMessage = this.defaultSerachErrorMessage;
      if (e.response) {
        switch (e.response.status) {
          default:
            break;
        }
      }
      this.setState({
        leads: { ...leads, error: errorMessage, loading: false },
      });
    }
  }

  async fetchCompetitors(value) {
    try {
      const { competitors } = this.state;
      const { firm } = this.props;
      const result = await API2.getCompetitorsAction(firm, {
        limit: 5,
        q: value,
      });
      this.setState({
        competitors: {
          ...competitors,
          data: result.competitors.map((item) => ({
            name: item.name,
            id: item._id,
          })),
          loading: false,
          path: `/${firm}/competitors`,
        },
      });
    } catch (e) {
      const { competitors } = this.state;
      const errorMessage = this.defaultSerachErrorMessage;
      if (e.response) {
        switch (e.response.status) {
          default:
            break;
        }
      }
      this.setState({
        competitors: { ...competitors, error: errorMessage, loading: false },
      });
    }
  }

  async fetchOpportunities(value) {
    try {
      const { opportunities } = this.state;
      const { firm } = this.props;
      const result = await API2.getOpportunitiesAction(firm, {
        limit: 5,
        q: value,
      });
      this.setState({
        opportunities: {
          ...opportunities,
          data: result.items.map((item) => ({
            name: item.topic,
            id: item._id,
          })),
          loading: false,
          path: `/${firm}/opportunities`,
        },
      });
    } catch (e) {
      const { opportunities } = this.state;
      const errorMessage = this.defaultSerachErrorMessage;
      if (e.response) {
        switch (e.response.status) {
          default:
            break;
        }
      }
      this.setState({
        opportunities: {
          ...opportunities,
          error: errorMessage,
          loading: false,
        },
      });
    }
  }

  async fetchProducts(value) {
    try {
      const { products } = this.state;
      const { firm } = this.props;
      const result = await API2.getProductsAction(firm, {
        limit: 5,
        q: value,
      });
      this.setState({
        products: {
          ...products,
          data: result.products.map((item) => ({
            name: item.Description,
            id: item._id,
          })),
          loading: false,
          path: `/${firm}/products`,
        },
      });
    } catch (e) {
      const { opportunities } = this.state;
      const errorMessage = this.defaultSerachErrorMessage;
      if (e.response) {
        switch (e.response.status) {
          default:
            break;
        }
      }
      this.setState({
        opportunities: {
          ...opportunities,
          error: errorMessage,
          loading: false,
        },
      });
    }
  }

  renderForms() {
    return <React.Fragment />;
  }

  render() {
    const { content, top, match, firm, user, settings, history, year } =
      this.props;
    const {
      search,
      wasSearched,
      displaySearch,
      customers,
      contacts,
      leads,
      competitors,
      opportunities,
      products,
      users,
    } = this.state;
    return (
      <React.Fragment>
        <Nav
          innerRef={(ref) => {
            this.searchWrapper = ref;
          }}
        >
          <Logo>
            <a href={`/${firm}${settings.startPageAdmin}`}>
              <img
                src="/images/Login/crm.svg"
                alt="Oxyrion logo"
                height="40px"
              />
            </a>
          </Logo>
          <ControlMenu
            content={content}
            top={top}
            search={search}
            wasSearched={wasSearched}
            match={match}
          />
          <Search
            clicked={search}
            onSearchClick={() => this.handleSearch()}
            onClose={() => this.closeSearch()}
            onInputChange={(value) => this.handleInputOnChange(value)}
          />
          <UserMenu
            display={!search}
            top={top}
            userName={user.display_name}
            firm={firm}
            match={match}
            onLogOutClick={() => this.handleLogOut()}
            onRealoadClick={() => {}}
            onPlusClick={(category) => this.onPlusClick(category)}
            onCountryClick={(value) => this.handleOnCoutryClick(value)}
            defaultPage={
              removeFirmFromUrl(window.location.pathname) ===
              settings.startPageAdmin
            }
            pinPage={() => this.pinPage()}
            history={history}
            year={year}
            onYearClick={(value) => this.handleOnYearClick(value)}
          />
          <SearchResults
            display={displaySearch}
            data={[
              customers,
              contacts,
              leads,
              competitors,
              opportunities,
              products,
              users,
            ]}
            onItemClick={() => this.closeSearch()}
          />
          {this.renderForms()}
        </Nav>
      </React.Fragment>
    );
  }
}

NavBar.propTypes = {
  top: PropTypes.string,
  user: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    forename: PropTypes.string.isRequired,
    mobile: PropTypes.string,
    role: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }).isRequired,
  firm: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired, //eslint-disable-line
  content: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      dropdown: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  settings: PropTypes.shape(PropTypes.object),
  year: PropTypes.number.isRequired,
};

NavBar.defaultProps = {
  top: rem(50),
  settings: {},
};

export default NavBar;
