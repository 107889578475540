import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import voucherCodes from 'voucher-code-generator';
import { Toggle, ToggleItem } from 'oxyrion-ui/lib/Toggle';
import {
  Input,
  Button,
  Select,
  Label,
  Icon,
  Message,
  Loader,
} from 'oxyrion-ui/lib';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import uniqid from 'uniqid';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import {
  __,
  formatPriceForApi,
  formatDate,
  FORMAT_HUMANE_DATETIME,
  translateCampaignStatus,
  getCurrencyByFirm,
  getPriceWithVat,
  getPriceWithoutVat,
} from '../../Utils';
import {
  fetchShops,
  isEmptyArray,
  searchMarketingLists,
} from '../../Utils/ApiSearchFunctions';

import { connect } from '../../Store';
import {
  AnimatedFormMessageWrapper,
  FlexBox,
  FlexBoxStart,
} from '../../Components/ReusableComponents';
import CustomerTypesLabels from '../../ContentConfig/customerTypes';
import SearchableSelect from '../../Components/SearchableSelect';
import API2 from '../../API2';
import AdminAPI from '../../AdminAPI';
import DatePicker from '../../Components/DatePicker';
import campaignLabels from '../../ContentConfig/campaingsLabels';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import GreyBox from '../../Components/GreyBox';
import MarketingListsContacts from '../MarketingListsContacts';
import CustomersContactsList from '../CustomersContactsList';
import CalatalogListExtended from '../../Containers/ProductCatalog/CatalogListExtended';
import NplusOneCampaign from '../../Components/CampaignTypes/n+1';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 29% 0;
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const CustomFlexBoxStart = styled(FlexBox)`
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  width: ${rem(20)};
  margin-right: ${rem(10)};
`;

// const Wrapper = styled.div``;

const Content = styled.div`
  padding: ${rem(10)};
  width: calc(100% - 20px);
  padding-bottom: ${rem(200)};
`;

const SectionWrapper = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
  max-width: ${rem(800)};
`;

const CampaignMWarraper = styled.div`
  max-width: ${rem(535)};
`;

const InsideContentWrapper = styled.div`
  display: flex;
`;

const StyledDatePicker = styled(DatePicker)`
  max-width: 90%;
`;

const StyledLabel = styled(Label)`
  min-width: ${rem(80)};
`;

const UnderlinedLabel = styled(Label)`
  border-bottom: #eee 2px solid;
`;

const StyledCustomFlexBoxStart = styled.div`
  justify-content: start;
  display: flex;
`;

const Text = styled.div`
  color: #555555;
`;

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  max-width: ${rem(100)};
  margin-right: ${rem(10)};
`;

const Percent = styled.div`
  margin-left: ${rem(20)};
  font-size: ${rem(15)};
`;

const DeleteLine = styled(Td)`
  :last-of-type {
    width: 1% !important;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  padding: ${rem(15)};
`;

const StyledCustomSelect = styled(CustomSelect)`
  width: ${rem(200)};
  margin-right: ${rem(10)};
`;

const StyledSearchableSelect = styled(SearchableSelect)``;

const SearchableSelectWrapper = styled.div`
  width: 100%;
  padding-right: ${rem(10)};
  padding-left: ${rem(10)};
`;

const DatePickerWrapper = styled.div`
  max-width: ${rem(150)};
  margin-right: ${rem(10)};
`;

const StyledButton = styled(Button)`
  float: left;
  margin-top: ${rem(10)};
`;

const DownloadButton = styled(Button)`
  margin-top: ${rem(20)};
  margin-left: ${rem(10)};
`;

const TextSmall = styled.div`
  color: #676565;
  font-size: 14px;
  margin-left: 5px;
`;

const StyledLink = styled.a`
  top: ${rem(-3)};
  position: relative;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 2.5rem;
  outline: 0;
  border: none;
  background: #ee6500;
  color: #fff;
  vertical-align: baseline;
  padding: 0 2.0625rem;
  text-transform: none;
  text-shadow: none;
  font-weight: 500;
  line-height: 1em;
  font-style: normal;
  font-size: 0.875rem;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 0.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Roboto', sans-serif;
  -webkit-transition:
    opacity 0.1s ease,
    background-color 0.1s ease,
    color 0.1s ease,
    box-shadow 0.1s ease,
    background 0.1s ease;
  transition:
    opacity 0.1s ease,
    background-color 0.1s ease,
    color 0.1s ease,
    box-shadow 0.1s ease,
    background 0.1s ease;
  box-shadow: 0 0.1875rem 0.75rem #ee650066;
  min-height: 1.875rem;
`;

const StyledButtonRight = styled(Button)`
  float: right;
  margin-top: ${rem(10)};
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const Hint = styled.div`
  font-size: 11px;
  color: #929292;
`;

const CatalogListWrapper = styled.div`
  width: 100%;
  min-height: 1300px;
  background: #fff;
`;

const SmallNumberInput = styled(Input)`
  width: 60px;
`;

const CustomMessage = styled.div`
  background: #ffff95;
  color: #ff7700;
  width: 100%;
  height: ${rem(50)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CampaignVoucherRow = styled.div`
  display: flex;
  margin-bottom: ${rem(12)};

  .input-wrapper {
    width: ${rem(100)};
    margin-right: ${rem(24)};
  }

  .generate-voucher {
    font-size: ${rem(12)};
    margin-top: ${rem(4)};
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.color.primary};
  }
`;

class AddCampaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      showConfirmDialog: false,
      customerTypes: [],
      loading: true,
      customers: [],
      shops: [],
      status: __('Na schválenie'),
      campaignName: {
        name: '',
        error: false,
      },
      validFrom: {
        error: false,
        value: moment(),
      },
      publishedFrom: { error: false, value: moment() },
      validTo: {
        value: undefined,
      },
      campaignO: {
        usage: 'one-time',
        voucher: '',
      },
      campaignP: {
        usage: 'one-time',
        vouchers: [
          {
            voucher: '',
            discount: '',
            amount_from: '',
          },
        ],
      },
      campaignM: {
        usage: 'one-time',
        codeGenerated: false,
        codeError: false,
        code: '',
        discountType: 'flat',
        discountWithVat: true,
        discount: 0,
        discounts: [
          {
            valueFrom: '',
            discountPercent: '',
          },
        ],
      },
      alt1: {
        all: {
          value: 0,
        },
        items: [
          {
            product: {},
            brand: {},
            replace: false,
            value: 0,
          },
        ],
      },
      alt2: {
        items: [
          {
            product: {},
            freeProduct: {},
            freeProductPrice: 0,
            freeProductDiscount: 0,
          },
        ],
      },
      alt3: {
        minValue: 0,
        items: [
          {
            product: {},
            productPrice: 0,
            productDiscount: 0,
          },
        ],
      },
      tempCustomerType: '',
      tempCustomerSubType: '',
      tempCustomer: undefined,
      selectedCampaign: {
        type: '',
      },
      newsletter: '',
      showDownloadPopup: false,
      pdfLoader: false,
      selectedType: 'customer',
      marketingList: null,
      refreshTable: false,
      increaseBaseDiscount: false,
    };
  }

  componentDidMount() {
    const { campaignId } = this.props;

    if (campaignId && campaignId !== 'new') {
      this.fetchCampaignData(campaignId);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchProduct(ids) {
    const { firm } = this.props;

    if (ids.length > 0) {
      try {
        const { products } = await API2.getProductsAction(firm, {
          productIds: ids,
          limit: ids.length,
        });

        return products.map((product) => ({
          product,
          label: product.Description,
          value: product._id,
          code: {
            label: product.No,
            value: product._id,
          },
        }));
      } catch (e) {
        switch (e.response.status) {
          case 403:
            this.setState({
              error: __('Na zobrazenie kampane nemáte potrebné práva.'),
            });
            break;
          case 404:
            this.setState({
              error: __('Produkt kampane sa nenašiel'),
            });
            break;
          default:
            this.setState({
              error: __('Pri načítavaní dát sa vyskytla chyba'),
            });
            break;
        }

        return [];
      } finally {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });

      return [];
    }
  }

  async fetchCampaignData(id) {
    const { firm } = this.props;
    const { selectedCampaign, alt1, alt2, alt3 } = this.state;
    let { shops } = this.state;

    try {
      const campaign = await API2.getCampaignAction(firm, id);

      selectedCampaign.type = campaign.campaign_alternative;

      let customers;
      let items;
      let campaignM;
      let campaignN;
      let campaignO;
      let campaignP;
      let customerTypes = [];
      const validFrom = {
        error: false,
        value: undefined,
      };
      const validTo = {
        value: undefined,
      };

      const publishedFrom = {
        error: false,
        value: undefined,
      };

      const campaignName = {
        name: campaign.name,
      };

      let publishedTo;

      if (campaign.customer_ids) {
        customers = await Promise.all(
          campaign.customer_ids.map((customerId) =>
            API2.getCustomerAction(firm, customerId),
          ),
        );
      }

      if (campaign.shop_ids) {
        const { items: shopItems } = await AdminAPI.getShopsAction();

        shops = campaign.shop_ids.map((shopId) => {
          const fullShop = shopItems.find((i) => i._id === shopId);

          return {
            value: shopId,
            label: fullShop.name,
          };
        });
      }

      if (campaign.customer_types) {
        customerTypes = campaign.customer_types.map((type) => {
          return {
            customerType: type.type,
            customerSubType: type.subtype,
          };
        });
      }

      if (
        campaign.data &&
        campaign.data.products &&
        campaign.campaign_alternative !== 'N'
      ) {
        const fullProducts = await this.fetchProduct(
          campaign.data.products
            .filter((product) => product.product_id)
            .map((product) => product.product_id),
        );

        const fullFreeProducts = await this.fetchProduct(
          campaign.data.products
            .filter((product) => product.product && product.product.product_id)
            .map((product) => product.product.product_id),
        );

        const fullCategories = await this.getFullCategories(
          campaign.data.products
            .filter((product) => product.category_id)
            .map((product) => product.category_id),
        );

        items = campaign.data.products.map((product) => {
          return {
            product: product.product_id
              ? fullProducts.find((f) => f.value === product.product_id)
              : {},
            brand: product.brand_id
              ? {
                  value: product.brand_id,
                  label: product.brand_id,
                  brand: product.brand_id,
                }
              : {},
            category: product.category_id
              ? fullCategories.find((c) => c.value === product.category_id)
              : {},
            freeProduct:
              product.product &&
              fullFreeProducts.find(
                (f) => f.value === product.product.product_id,
              ),
            freeProductPrice:
              product.product && product.product.discount_price.amount,
            freeProductDiscount:
              product.product && product.product.discount_percent,
            replace:
              product.replace_general_discount &&
              product.replace_general_discount,
            value: product.discount_percent && product.discount_percent,
            minValue: product.purchase_value && product.purchase_value.amount,
            productPrice:
              product.discount_price && product.discount_price.amount,
            productDiscount:
              product.discount_percent && product.discount_percent,
          };
        });
      }

      customers = customers.map((customer) => {
        return {
          customer,
          label: customer.name,
          value: customer._id,
        };
      });

      if (campaign.campaign_alternative === 'D') {
        alt1.all.value = campaign.discount;
      } else if (
        campaign.campaign_alternative === 'F' ||
        campaign.campaign_alternative === 'K'
      ) {
        alt1.items = items;
      } else if (
        campaign.campaign_alternative === 'L' ||
        campaign.campaign_alternative === 'H' ||
        campaign.campaign_alternative === 'G'
      ) {
        alt2.items = items;
      } else if (
        campaign.campaign_alternative === 'I' ||
        campaign.campaign_alternative === 'J'
      ) {
        alt3.items = items;
        alt3.minValue = campaign.data.purchase_value.amount;
      } else if (campaign.campaign_alternative === 'M') {
        campaignM = campaign.data;
      } else if (campaign.campaign_alternative === 'N') {
        campaignN = campaign.data;
      } else if (campaign.campaign_alternative === 'O') {
        campaignO = campaign.data;
      } else if (campaign.campaign_alternative === 'P') {
        campaignP = campaign.data;
      }

      if (campaign.valid_from) {
        validFrom.value = moment(campaign.valid_from);
      }

      if (campaign.valid_to) {
        validTo.value = moment(campaign.valid_to);
      }

      if (campaign.publish_from) {
        publishedFrom.value = moment(campaign.publish_from);
      }

      if (campaign.publish_to) {
        publishedTo = moment(campaign.publish_to);
      }

      this.setState({
        selectedCampaign,
        customerTypes,
        customers,
        alt1,
        alt2,
        campaignM,
        campaignN,
        campaignO,
        campaignP,
        alt3,
        validFrom,
        validTo,
        shops,
        publishedFrom,
        publishedTo,
        campaignName,
        increaseBaseDiscount: campaign.increaseBaseDiscount,
        newsletter: campaign.newsletter,
        marketingList: campaign.marketingList,
        selectedType: this.getSelectedTypeByCampaing(campaign),
      });
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie kampane nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Kampan sa nenašla'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  getSelectedTypeByCampaing(campaign) {
    if (campaign.marketingList) {
      return 'marketingList';
    }

    if (campaign.shop_ids && !isEmptyArray(campaign.shop_ids)) {
      return 'shop';
    }

    if (campaign.xpaint_campaign) {
      return 'xpaint';
    }

    return 'customer';
  }

  togglePopUp() {
    this.props.closePopup();
    this.setState({
      success: false,
      showConfirmDialog: false,
      customerTypes: [],
      loading: false,
      customers: [],
      campaignName: {
        name: '',
        error: false,
      },
      validFrom: {
        error: false,
        value: moment(),
      },
      publishedFrom: { error: false, value: moment() },
      validTo: {
        value: undefined,
      },
      alt1: {
        all: {
          value: 0,
        },
        items: [
          {
            product: {},
            brand: {},
            replace: false,
            value: 0,
          },
        ],
      },
      alt2: {
        items: [
          {
            product: {},
            freeProduct: {},
            freeProductPrice: 0,
            freeProductDiscount: 0,
          },
        ],
      },
      alt3: {
        minValue: 0,
        items: [
          {
            product: {},
            productPrice: 0,
            productDiscount: 0,
          },
        ],
      },
      tempCustomerType: '',
      tempCustomerSubType: '',
      tempCustomer: undefined,
      selectedCampaign: {
        type: '',
      },
    });
  }

  getTypeByName(name) {
    if (name === 'Predajne') {
      return 'shop';
    }

    if (name === 'Zákazníci') {
      return 'customer';
    }

    if (name === 'Eshop - xfarby') {
      return 'xpaint';
    }

    return 'marketingList';
  }

  handleTypeChange(newSelectedType) {
    this.setState({
      selectedType: newSelectedType,
    });
  }

  handleCampaignMChange(field, value) {
    const { campaignM } = this.state;

    campaignM[field] = value;

    if (field === 'codeGenerated' && value === true) {
      campaignM.code = '';
    }

    if (field === 'discountType' && value === 'progressive') {
      campaignM.discounts = [
        {
          discountPercent: '',
          valueFrom: '',
        },
      ];
    }
    this.setState({ campaignM });
  }

  handleCampaignOChange(field, value) {
    const { campaignO } = this.state;

    campaignO[field] = value;

    if (field === 'codeGenerated' && value === true) {
      campaignO.code = '';
    }

    if (field === 'discountType' && value === 'progressive') {
      campaignO.discounts = [
        {
          discountPercent: '',
          valueFrom: '',
        },
      ];
    }
    this.setState({ campaignO });
  }

  handleCampaignPChange(field, value) {
    const { campaignP } = this.state;

    campaignP[field] = value;

    if (field === 'codeGenerated' && value === true) {
      campaignP.code = '';
    }

    if (field === 'discountType' && value === 'progressive') {
      campaignP.discounts = [
        {
          discountPercent: '',
          valueFrom: '',
        },
      ];
    }
    this.setState({ campaignP });
  }

  handleCampaignPVoucherChange(index, field, value) {
    const { campaignP } = this.state;

    campaignP.vouchers[index][field] = value;

    if (
      field === 'voucher' &&
      value.length > 0 &&
      !campaignP.vouchers[index + 1]
    ) {
      campaignP.vouchers.push({
        voucher: '',
        discount: '',
        amount_from: '',
      });
    }
    this.setState(campaignP);
  }

  handleProgresiveDiscountChange(field, value, index) {
    const { campaignM } = this.state;

    campaignM.discounts[index][field] = value;

    if (index > 0) {
      if (
        Number(campaignM.discounts[index].valueFrom) <=
        Number(campaignM.discounts[index - 1].valueFrom)
      ) {
        campaignM.discounts[index].error = true;
      } else {
        campaignM.discounts[index].error = false;
      }
    } else {
      campaignM.discounts[index].error = false;
    }

    if (
      campaignM.discounts[index].valueFrom > 0 &&
      campaignM.discounts[index].discountPercent > 0 &&
      !campaignM.discounts[index + 1] &&
      !campaignM.discounts[index].error
    ) {
      campaignM.discounts.push({
        valueFrom: '',
        discountPercent: '',
      });
    }

    this.setState({
      campaignM,
    });
  }

  handleChange(field, index, value) {
    const {
      customerTypes,
      customers,
      alt1,
      alt2,
      alt3,
      campaignName,
      selectedCampaign,
      selectedCampaignTemp,
      validFrom,
      validTo,
      publishedFrom,
      tempCustomer,
    } = this.state;
    let {
      publishedTo,
      tempCustomerType,
      tempCustomerSubType,
      newsletter,
      marketingList,
      refreshTable,
      selectedType,
      shops,
    } = this.state;
    if (field === 'customerTypes') {
      tempCustomerType = value.target.value;
    } else if (field === 'campaignName') {
      campaignName.name = value.target.value;
    } else if (field === 'customerSubTypes') {
      tempCustomerSubType = value.target.value;

      customerTypes.push({
        customerType: tempCustomerType,
        customerSubType: tempCustomerSubType,
      });

      tempCustomerType = '';
      tempCustomerSubType = '';
    } else if (field === 'customer') {
      // tempCustomer = value;
      customers.push(value);
    } else if (field === 'shop') {
      // tempCustomer = value;
      shops = value;
    } else if (field === 'validFrom') {
      validFrom.value = value;
    } else if (field === 'validTo') {
      validTo.value = value;
    } else if (field === 'publishedFrom') {
      publishedFrom.value = value;
    } else if (field === 'publishedTo') {
      publishedTo = value;
    } else if (field === 'selectedCampaign') {
      // if (selectedCampaign.type !== '') {
      //   this.toggleChangeType();
      //   selectedCampaignTemp = value.target.value;
      // } else {
      selectedCampaign.type = value.target.value;

      if (value.target.value === 'M') {
        selectedType = 'marketingList';
      }
    } else if (field === 'newsletter') {
      newsletter = value;
    } else if (field === 'alt1.all.value') {
      alt1.all.value = value.target.value;
    } else if (field === 'alt1.all.replace') {
      alt1.all.replace = !alt1.all.replace;
    } else if (field === 'alt1.product') {
      alt1.items[index].product = value;
    } else if (field === 'alt1.product.value') {
      alt1.items[index].value = value.target.value;
    } else if (field === 'alt1.brand') {
      alt1.items[index].brand = value;
    } else if (field === 'alt1.category') {
      alt1.items[index].category = value;
    } else if (field === 'alt1.product.replace') {
      alt1.items[index].replace = !alt1.items[index].replace;
    } else if (field === 'alt2.product') {
      alt2.items[index].product = {
        ...value,
        label: value ? value.product.Description : '',
        value: value ? value.product._id : '',
      };
      alt2.items[index].product.code = {
        label: value ? value.product.No : '',
        value: value ? value.value._id : '',
      };
    } else if (field === 'alt2.freeProduct') {
      alt2.items[index].freeProduct = {
        ...value,
        label: value ? value.product.Description : '',
        value: value ? value.product._id : '',
      };
      alt2.items[index].freeProduct.code = {
        label: value ? value.product.No : '',
        value: value ? value.value._id : '',
      };
    } else if (field === 'alt2.freeProduct.price') {
      alt2.items[index].freeProductPrice = value.target.value;
    } else if (field === 'alt2.freeProduct.price_vat') {
      alt2.items[index].freeProductPrice = getPriceWithoutVat(
        this.props.vatRate,
        Number(value.target.value),
      );
    } else if (field === 'alt2.freeProduct.discount') {
      alt2.items[index].freeProductDiscount = value.target.value;
    } else if (field === 'alt3.product') {
      alt3.items[index].product = {
        ...value,
        label: value ? value.product.Description : '',
        value: value ? value.product._id : '',
      };
      alt3.items[index].product.code = {
        label: value ? value.product.No : '',
        value: value ? value.value._id : '',
      };
    } else if (field === 'alt3.product.price') {
      alt3.items[index].productPrice = value.target.value;
    } else if (field === 'alt3.product.discount') {
      alt3.items[index].productDiscount = value.target.value;
    } else if (field === 'alt3.minValue') {
      alt3.minValue = value.target.value;
    } else if (field === 'marketingList') {
      marketingList = value;
      refreshTable = !refreshTable;
    }

    this.setState({
      customers,
      shops,
      customerTypes,
      validFrom,
      validTo,
      publishedTo,
      publishedFrom,
      alt1,
      tempCustomer,
      tempCustomerType,
      tempCustomerSubType,
      selectedCampaign,
      campaignName,
      selectedCampaignTemp,
      newsletter,
      marketingList,
      refreshTable,
      selectedType,
    });
  }

  fetchCustomers(query) {
    try {
      return API2.getCustomersAction(this.props.firm, { q: query }).then(
        (res) => {
          return res.items.map((item) => ({
            value: item._id,
            label: item.name,
            customer: item,
          }));
        },
      );
    } catch (e) {
      return [];
    }
  }

  fetchProducts(query) {
    try {
      return API2.getProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then((res) => {
        return res.products.map((item) => ({
          value: item._id,
          label: `${item.Description} - ${item._id}`,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchProductsByEAN(query) {
    try {
      return API2.getProductsAction(this.props.firm, { q: query }).then(
        (res) => {
          return res.products.map((item) => ({
            value: item.No,
            label: item._id,
            product: item,
          }));
        },
      );
    } catch (e) {
      return [];
    }
  }

  async getFullCategories(ids = []) {
    if (ids.length === 0) {
      return [];
    }

    try {
      const { categories } = await AdminAPI.getCategoriesAction('xFarby', {
        lang: this.props.firm,
      });
      return categories.map((c) => ({
        value: c._id,
        label: c.full_path_name,
        category: c,
      }));
    } catch (e) {
      return [];
    }
  }

  async fetchCategories(query) {
    try {
      return AdminAPI.getCategoriesAction('xFarby', {
        q: query,
        lang: this.props.firm,
      }).then((res) => {
        return res.categories.map((item) => ({
          value: item._id,
          label: item.full_path_name,
          category: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchBrand(query) {
    if (query && query.length > 2) {
      try {
        return API2.getBrandsAction(this.props.firm, { q: query }).then(
          (res) => {
            return res.brands.map((item) => ({
              value: item,
              label: item,
              brand: item,
            }));
          },
        );
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  async fetchNewsletter(query) {
    try {
      return AdminAPI.getNewslettersAction(this.props.firm, { q: query }).then(
        (res) => {
          return res.items.map((item) => ({
            value: item._id,
            label: item.name,
          }));
        },
      );
    } catch (e) {
      return [];
    }
  }

  addCustomerGroup() {
    const { customerTypes } = this.state;
    const { tempCustomerType, tempCustomerSubType } = this.state;

    customerTypes.push({
      customerType: tempCustomerType,
      customerSubType: tempCustomerSubType,
    });

    this.setState({
      customerTypes,
      tempCustomerType: '',
      tempCustomerSubType: '',
    });
  }

  addCustomer() {
    const { customers } = this.state;
    let { tempCustomer } = this.state;
    if (tempCustomer.value) {
      customers.push(tempCustomer);
      tempCustomer = undefined;
      this.setState({
        customers,
        tempCustomer,
      });
    }
  }

  addProduct(alt) {
    if (alt === 1) {
      const { alt1 } = this.state;
      alt1.items.push({
        product: {},
        brand: {},
        replace: false,
        value: 0,
      });
      this.setState({
        alt1,
      });
    } else if (alt === 2) {
      const { alt2 } = this.state;
      alt2.items.push({
        product: {},
        freeProduct: {},
        freeProductPrice: 0,
        freeProductDiscount: 0,
      });
      this.setState({
        alt2,
      });
    } else if (alt === 3) {
      const { alt3 } = this.state;
      alt3.items.push({
        product: {},
      });
      this.setState({
        alt3,
      });
    }
  }

  deleteProduct(alt, index) {
    if (alt === 1) {
      const { alt1 } = this.state;
      alt1.items.splice(index, 1);
      this.setState({
        alt1,
      });
    } else if (alt === 2) {
      const { alt2 } = this.state;
      alt2.items.splice(index, 1);
      this.setState({
        alt2,
      });
    } else if (alt === 3) {
      const { alt3 } = this.state;
      alt3.items.splice(index, 1);
      this.setState({
        alt3,
      });
    }
  }

  deleteCustomer(index) {
    const { customers } = this.state;
    customers.splice(index, 1);
    this.setState({
      customers,
    });
  }

  deleteCustomerType(index) {
    const { customerTypes } = this.state;
    customerTypes.splice(index, 1);
    this.setState({
      customerTypes,
    });
  }

  toggleChangeType() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  changeCampaingType() {
    const { selectedCampaign, selectedCampaignTemp } = this.state;

    selectedCampaign.type = selectedCampaignTemp;

    this.setState({
      selectedCampaign,
      showConfirmDialog: false,
      alt1: {
        all: {
          value: 4,
          replace: false,
        },
        products: [{}],
      },
      alt2: {
        items: [
          {
            product: {},
            freeProduct: {},
          },
        ],
      },
      alt3: {
        minValue: 0,
        items: [
          {
            product: {},
          },
        ],
      },
    });
  }

  saveDataNew(data) {
    const { campaignId, status } = this.props;

    if (campaignId !== 'new' && status === 'approved') {
      this.setState({
        showSaveDialog: true,
        dataToSave: data,
      });
    } else {
      this.sendDataV2(data);
    }
  }

  sendData() {
    this.setState({
      loading: true,
      success: false,
      successMessage: '',
    });

    if (this.checkFieldsValidation() && this.actualTypeCheck()) {
      if (this.props.campaignId !== 'new') {
        this.updateCampaign();
      } else {
        this.createCampaign();
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      this.setState({
        loading: false,
      });
    }
  }

  // sluzi pre nove typy kampani - postupne prerobime aj stare
  sendDataV2(data) {
    this.setState({
      saveLoading: true,
      success: false,
      successMessage: '',
    });

    if (this.checkFieldsValidation()) {
      if (this.props.campaignId !== 'new') {
        this.updateCampaign(data);
      } else {
        this.createCampaign(data);
      }
    } else {
      this.setState({
        saveLoading: false,
      });
    }
  }

  updateCampaign(data) {
    API2.putCampaignAction(this.props.firm, this.props.campaignId, {
      body: this.prepareData(data),
    })
      .then((result) => {
        let successMessage = __('Kampaň bola upravená');

        if (result.newVouchers && result.newVouchers > 0) {
          let vouchers = '';
          if (result.newVouchers === 1) {
            vouchers = __('voucher vytvorený');
          } else if (result.newVouchers <= 4) {
            vouchers = __('vouchere vytvorené');
          } else {
            vouchers = __('voucherov vytvorených');
          }

          successMessage = `${__('Kampaň bola upravená')}, ${
            result.newVouchers
          } ${vouchers}`;
        }

        this.setState(
          {
            success: true,
            successMessage,
            showSaveDialog: false,
          },
          () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            this.props.callback();
            setTimeout(() => {
              this.setState({
                success: false,
              });
            }, 2000);
          },
        );
      })
      .catch((e) => {
        switch (e.response.status) {
          case 403:
            this.setState({
              error: __('Nemáte potrebné práva pre upravovanie kampaní'),
            });
            break;
          case 404:
            this.setState({
              error: __('Služba sa nenašla'),
            });
            break;
          default:
            this.setState({
              error: __('Pri odosielaní dát nastala chyba'),
              showSaveDialog: false,
            });
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
          saveLoading: false,
        });
      });
  }

  createCampaign(data) {
    API2.postCampaignAction(this.props.firm, { body: this.prepareData(data) })
      .then((result) => {
        this.props.history.push(`/${this.props.firm}/campaigns/${result._id}`);
        let successMessage = __('Kampaň bola vytvorená');

        if (result.newVouchers && result.newVouchers > 0) {
          let vouchers = '';
          if (result.newVouchers === 1) {
            vouchers = __('voucher vytvorený');
          } else if (result.newVouchers <= 4) {
            vouchers = __('vouchere vytvorené');
          } else {
            vouchers = __('voucherov vytvorených');
          }
          successMessage = `${__('Kampaň bola vytvorená')}, ${
            result.newVouchers
          } ${vouchers}`;
        }

        this.setState(
          {
            success: true,
            successMessage,
            showSaveDialog: false,
          },
          () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            this.props.callback();
            setTimeout(() => {
              this.setState({
                success: false,
              });
            }, 2000);
          },
        );
      })
      .catch((e) => {
        switch (e.response.status) {
          case 403:
            this.setState({
              error: __('Nemáte potrebné práva pre pridávanie kampaní'),
            });
            break;
          case 404:
            this.setState({
              error: __('Služba sa nenašla.'),
            });
            break;
          default:
            this.setState({
              error: __('Pri odosielaní dát nastala chyba.'),
            });
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
          saveLoading: false,
        });
      });
  }

  insertVariants(data) {
    const { showProductsSelector, alt1, alt2, alt3 } = this.state;

    if (showProductsSelector && showProductsSelector.alt === 'alt1.product') {
      alt1.items = alt1.items
        .filter((a) => a.product.label || a.brand.label)
        .concat(
          data
            .filter(
              (d) =>
                alt1.items.filter((i) => i.product.value === d.value).length ===
                0,
            )
            .map((d) => ({ product: d, brand: {} })),
        );
      this.setState({ alt1 });
      return;
    }

    if (showProductsSelector && showProductsSelector.alt === 'alt2.product') {
      alt2.items[showProductsSelector.index].product = data;
      this.setState({ alt2, showProductsSelector: false });
      return;
    }

    if (
      showProductsSelector &&
      showProductsSelector.alt === 'alt2.freeProduct'
    ) {
      alt2.items[showProductsSelector.index].freeProduct = data;
      this.setState({ alt2, showProductsSelector: false });
      return;
    }

    if (showProductsSelector && showProductsSelector.alt === 'alt3.product') {
      alt3.items = alt3.items
        .filter((a) => a.product.label)
        .concat(data.map((d) => ({ product: d, brand: {} })));
      this.setState({ alt3 });
    }
  }

  isMutlipleByAlt(alt) {
    return ['alt1.product', 'alt3.product'].includes(alt);
  }

  checkFieldsValidation() {
    const { campaignName, validFrom, publishedFrom } = this.state;
    let isValid = true;

    if (campaignName.name.length <= 0) {
      campaignName.error = true;
      isValid = false;
    } else {
      campaignName.error = false;
    }

    if (!validFrom.value) {
      validFrom.error = true;
      isValid = false;
    } else {
      validFrom.error = false;
    }

    if (!publishedFrom.value) {
      publishedFrom.error = true;
      isValid = false;
    } else {
      publishedFrom.error = false;
    }

    if (!isValid) {
      this.setState({
        campaignName,
        publishedFrom,
        validFrom,
        error: __('Nevyplnená hodnota'),
      });
    } else {
      this.setState({
        campaignName,
        publishedFrom,
        validFrom,
        error: undefined,
      });
    }

    return isValid;
  }

  handleIncreaseBaseDiscount(value) {
    let { increaseBaseDiscount } = this.state;
    increaseBaseDiscount = value;

    this.setState({
      increaseBaseDiscount,
    });
  }

  actualTypeCheck() {
    const {
      alt1,
      alt2,
      alt3,
      selectedCampaign,
      campaignM,
      campaignP,
      campaignO,
    } = this.state;

    let isValid = true;

    switch (selectedCampaign.type) {
      case 'D':
        if (alt1.all.value < 0) {
          isValid = false;
        }
        break;
      case 'F':
        alt1.items.forEach((item) => {
          if (
            (!item.brand.brand && !item.product.product && !item.category) ||
            item.value <= 0
          ) {
            isValid = false;
          }
        });

        break;
      case 'K':
        alt1.items.forEach((item) => {
          if (
            (!item.brand.brand && !item.product.product && !item.category) ||
            item.value <= 0
          ) {
            isValid = false;
          }
        });
        break;
      case 'G':
        alt2.items.forEach((item) => {
          if (
            !item.product.product ||
            !item.freeProduct.product ||
            item.freeProductPrice <= 0
          ) {
            isValid = false;
          }
        });
        break;
      case 'H':
        alt2.items.forEach((item) => {
          if (
            !item.product.product ||
            !item.freeProduct.product ||
            item.freeProductDiscount <= 0
          ) {
            isValid = false;
          }
        });
        break;
      case 'L':
        alt2.items.forEach((item) => {
          if (!item.product.product || !item.freeProduct.product) {
            isValid = false;
          }
        });
        break;
      case 'I':
        alt3.items.forEach((item) => {
          if (
            !item.product.product ||
            alt3.minValue <= 0 ||
            (item.productPrice <= 0 && item.productDiscount <= 0)
          ) {
            isValid = false;
          }
        });
        break;
      case 'J':
        alt3.items.forEach((item) => {
          if (!item.product.product || alt3.minValue <= 0) {
            isValid = false;
          }
        });
        break;
      case 'M':
        if (!campaignM.codeGenerated && campaignM.code.length <= 0) {
          isValid = false;
          break;
        }

        if (campaignM.discountType === 'flat') {
          if (Number(campaignM.discount) <= 0) {
            isValid = false;
            break;
          }
        } else if (
          campaignM.discounts.length === 0 &&
          (campaignM.discounts[0].valueFrom <= 0 ||
            campaignM.discounts[0].discountPercent)
        ) {
          isValid = false;
        }
        break;
      case 'P':
        if (
          !campaignP.vouchers ||
          campaignP.vouchers.filter(
            (v) =>
              !(
                (!v.voucher || v.voucher.length === 0) &&
                !(v.discount > 0) &&
                !(v.amount_from > 0)
              ),
          ).length === 0
        ) {
          isValid = false;
        }
        break;
      case 'O':
        if (
          !campaignO.voucher ||
          campaignO.voucher.length === 0 ||
          !campaignO.amount_from ||
          campaignO.amount_from < 0
        ) {
          isValid = false;
          break;
        }

        break;
      default:
    }

    if (!isValid) {
      this.setState({
        error: `${__('Hodnoty pre typ kampane:')} "${
          campaignLabels.find((c) => c.type === selectedCampaign.type).name
        }" ${__('sú vyplnené nesprávne.')}`,
      });
    }

    return isValid;
  }

  async downloadPDF(newsletterId) {
    try {
      this.setState({
        pdfLoader: true,
      });

      const result = await API2.getNewsletterPdfAction(
        this.props.firm,
        newsletterId,
      );
      window.location.href = result.url;

      this.setState({
        pdfLoader: false,
        showDownloadPopup: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  generateVoucher(campaing, index) {
    const { campaignO, campaignP } = this.state;

    const voucher = voucherCodes.generate({
      length: 6,
      count: 1,
    })[0];

    if (campaing === 'P') {
      campaignP.vouchers[index].voucher = voucher;

      campaignP.vouchers.push({
        voucher: '',
        discount: '',
        amount_from: '',
      });
    } else if (campaing === 'O') {
      campaignO.voucher = voucher;
    }

    this.setState({ campaignO, campaignP });
  }

  prepareData(extData) {
    const {
      campaignName,
      validFrom,
      validTo,
      publishedFrom,
      publishedTo,
      selectedCampaign,
      customers,
      shops,
      customerTypes,
      newsletter,
      alt1,
      alt2,
      alt3,
      marketingList,
      campaignM,
      campaignO,
      campaignP,
      increaseBaseDiscount,
      selectedType,
    } = this.state;

    const { name } = campaignName;
    const valid_from = moment(validFrom.value).toISOString();
    const valid_to = validTo.value && moment(validTo.value).toISOString();
    const publish_from = moment(publishedFrom.value).toISOString();
    const publish_to = publishedTo && moment(publishedTo).toISOString();
    const campaign_alternative = selectedCampaign.type;
    const customer_ids = customers.map((customer) => {
      return customer.customer._id;
    });
    const shop_ids = shops.map((s) => s.value);

    const type = 'campaign';

    let discount = 0;

    const data = {};

    const newsletterId = newsletter && newsletter.value;
    const marketingListId = marketingList && marketingList.value;

    const customer_types = customerTypes.map((t) => {
      return {
        type: t.customerType,
        subtype: t.customerSubType,
      };
    });

    switch (selectedCampaign.type) {
      case 'D':
        discount = Number(alt1.all.value);
        break;
      case 'F':
        data.products = alt1.items.map((item) => {
          return {
            brand_id: item.brand.brand ? item.brand.brand : '',
            product_id: item.product.product ? item.product.product._id : '',
            category_id:
              item.category && item.category.category
                ? item.category.category._id
                : '',
            discount_percent: Number(item.value),
            replace_generel_discount: item.replace,
          };
        });

        break;
      case 'K':
        data.products = alt1.items.map((item) => {
          return {
            brand_id: item.brand.brand ? item.brand.brand : '',
            product_id: item.product.product ? item.product.product._id : '',
            category_id:
              item.category && item.category.category
                ? item.category.category._id
                : '',
            discount_percent: Number(item.value),
            replace_generel_discount: item.replace,
          };
        });
        break;
      case 'G':
        data.products = alt2.items.map((item) => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            product: {
              product_id: item.freeProduct.product
                ? item.freeProduct.product._id
                : '',
              discount_price:
                item.freeProductPrice &&
                formatPriceForApi({
                  amount: item.freeProductPrice,
                  currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
                }),
            },
          };
        });
        break;
      case 'H':
        data.products = alt2.items.map((item) => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            product: {
              product_id: item.freeProduct.product
                ? item.freeProduct.product._id
                : '',
              discount_percent:
                item.freeProductDiscount && Number(item.freeProductDiscount),
            },
          };
        });
        break;
      case 'L':
        data.products = alt2.items.map((item) => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            product: {
              product_id: item.freeProduct.product
                ? item.freeProduct.product._id
                : '',
            },
          };
        });
        break;
      case 'I':
        data.purchase_value = formatPriceForApi({
          amount: alt3.minValue,
          currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
        });
        data.products = alt3.items.map((item) => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            discount_price:
              item.productPrice > 0
                ? {
                    amount: Number(item.productPrice),
                    currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
                  }
                : undefined,
            discount_percent:
              item.productDiscount && Number(item.productDiscount),
          };
        });
        break;
      case 'J':
        data.purchase_value = formatPriceForApi({
          amount: alt3.minValue,
          currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
        });
        data.products = alt3.items.map((item) => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
          };
        });
        break;
      case 'M':
        if (campaignM.discountType === 'flat') {
          Object.assign(data, {
            usage: campaignM.usage,
            code: !campaignM.codeGenerated ? campaignM.code : undefined,
            codeGenerated: campaignM.codeGenerated,
            discount: Number(campaignM.discount),
            discountType: campaignM.discountType,
          });
        } else {
          Object.assign(data, {
            usage: campaignM.usage,
            code: !campaignM.codeGenerated ? campaignM.code : undefined,
            codeGenerated: campaignM.codeGenerated,
            discounts: campaignM.discounts,
            discountWithVat: campaignM.discountWithVat,
            discountType: campaignM.discountType,
          });
        }

        break;
      case 'N':
        data.products = extData.map((p) => ({
          product_id: p.product.value,
          benefit_product_id: p.benefitProduct.value,
          quantity: p.quantity,
          price: Number(p.price),
        }));

        break;

      case 'O':
        Object.assign(data, {
          usage: campaignO.usage,
          voucher: campaignO.voucher,
          amount_from: campaignO.amount_from,
        });
        break;

      case 'P':
        Object.assign(data, {
          usage: campaignP.usage,
          vouchers: campaignP.vouchers,
        });
        break;
      default:
    }

    const finalData = {
      name,
      xpaint_campaign: selectedType === 'xpaint',
      valid_from,
      valid_to,
      publish_from,
      publish_to,
      type,
      campaign_alternative,
      customer_ids,
      shop_ids,
      customer_types,
      discount,
      data,
      newsletterId,
      marketingListId,
      increaseBaseDiscount,
    };

    return finalData;
  }

  render() {
    const {
      campaignName,
      customerTypes,
      customers,
      shops,
      validFrom,
      validTo,
      publishedFrom,
      publishedTo,
      selectedCampaign,
      alt1,
      alt2,
      alt3,
      campaignN,
      campaignO,
      campaignP,
      marketingList,
      tempCustomer,
      tempCustomerType,
      tempCustomerSubType,
      error,
      loading,
      success,
      successMessage,
      newsletter,
      showDownloadPopup,
      pdfLoader,
      selectedType,
      refreshTable,
      showSaveDialog,
      showProductsSelector,
      campaignM,
      saveLoading,
      dataToSave,
      increaseBaseDiscount,
    } = this.state;
    const { user, approved_by, approved_date, readOnly, status } = this.props;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Content>
        {success && <Message message={successMessage} />}
        {error && <Message error message={error} />}
        {readOnly && (
          <CustomMessage>{__('Nie sú možné žiadne úpravy')}</CustomMessage>
        )}

        <PopUpWrapper
          display={showProductsSelector}
          large
          titleZIndex={1000}
          customWidth={1200}
          message={__('Výber produktov zo zonamu')}
          onClose={() =>
            this.setState({
              showProductsSelector: false,
            })
          }
          onEscClose={() =>
            this.setState({
              showProductsSelector: false,
            })
          }
        >
          <CatalogListWrapper>
            <CalatalogListExtended
              history={this.props.history}
              // customerId={customerId}
              firm={this.props.firm}
              addProduct={(product) => this.addProductFromList(product)}
              insertVariants={(d) => this.insertVariants(d)}
              multiple={this.isMutlipleByAlt(
                showProductsSelector && showProductsSelector.alt,
              )}
            />
          </CatalogListWrapper>
        </PopUpWrapper>

        <PopUpWrapper
          display={showDownloadPopup}
          small
          onClose={() =>
            this.setState({
              showDownloadPopup: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Prajete si stiahnuť PDF newslettera?')}
            onDismiss={() =>
              this.setState({
                showDownloadPopup: false,
              })
            }
            loading={pdfLoader}
            onConfirm={() => this.downloadPDF(newsletter.value)}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showSaveDialog}
          small
          onClose={() =>
            this.setState({ showSaveDialog: false, dataToSave: null })
          }
          statusName={showSaveDialog}
          onEscClose={() =>
            this.setState({ showSaveDialog: false, dataToSave: null })
          }
        >
          <ConfirmDialog
            message={__(
              'Po uložení zmien bude opäť nutné požiadať o schvalenie kampane. Naozaj si želáte vykonať tieto zmeny?',
            )}
            onDismiss={() =>
              this.setState({
                showSaveDialog: false,
                dataToSave: null,
              })
            }
            onConfirm={() => {
              if (dataToSave) {
                this.sendDataV2(dataToSave);
              } else {
                this.sendData();
              }
            }}
          />
        </PopUpWrapper>

        <InsideContentWrapper>
          <SectionWrapper>
            <Label>{__('Názov kampane* ')}</Label>

            <Input
              type="text"
              placeholder={__('Názov')}
              value={campaignName.name}
              error={campaignName.error}
              onChange={(e) => {
                this.handleChange('campaignName', 0, e);
              }}
            />

            <Space />
            <GreyBox title={__('Typ kampane')}>
              <CustomSelect
                name="test"
                size="s"
                disabled={this.props.campaignId !== 'new'}
                onChange={(e) => this.handleChange('selectedCampaign', 0, e)}
              >
                {!selectedCampaign.type !== '' && (
                  <option key="" value="">
                    {__('Vyberte typ kampane')}
                  </option>
                )}
                {campaignLabels.map((type) => {
                  return (
                    <option
                      key={type.type}
                      value={type.type}
                      // disabled={type.onlyMarketingList && !marketingList}
                      selected={type.type === selectedCampaign.type}
                    >
                      {`${type.type}. ${type.name} ${
                        type.onlyMarketingList && !marketingList
                          ? `- ${__('iba pre marketingový zoznam')} `
                          : ''
                      }`}
                    </option>
                  );
                })}
              </CustomSelect>
            </GreyBox>
            {selectedCampaign.type && (
              <React.Fragment>
                <Space />
                {!['M', 'O', 'P'].includes(selectedCampaign.type) && (
                  <Toggle
                    name="type"
                    disabled={selectedCampaign.type === 'M'}
                    onClick={(e) => {
                      this.handleTypeChange(
                        this.getTypeByName(e.target.textContent),
                      );
                    }}
                  >
                    <ToggleItem
                      id="1"
                      key="1"
                      checked={selectedType === 'customer'}
                      value="customer"
                      label={__('Zákazníci')}
                    />

                    <ToggleItem
                      key="2"
                      id="2"
                      checked={selectedType === 'marketingList'}
                      value="marketingList"
                      label={__('Marketingový zoznam')}
                    />

                    {['F', 'I', 'J', 'N'].includes(selectedCampaign.type) ? (
                      <ToggleItem
                        key="3"
                        id="3"
                        checked={selectedType === 'shop'}
                        value="shop"
                        label={__('Predajne')}
                      />
                    ) : (
                      <React.Fragment />
                    )}

                    {['F', 'G', 'N'].includes(selectedCampaign.type) ? (
                      <ToggleItem
                        key="3"
                        id="3"
                        checked={selectedType === 'xpaint'}
                        value="xpaint"
                        label={__('Eshop - xfarby')}
                      />
                    ) : (
                      <React.Fragment />
                    )}
                  </Toggle>
                )}
                <Space />

                <AnimatedFormMessageWrapper
                  display={
                    selectedType === 'shop' &&
                    ['F', 'G', 'N'].includes(selectedCampaign.type)
                  }
                >
                  <GreyBox title={__('Predajne')}>
                    <SearchableSelect
                      loadOptions={() => {
                        return fetchShops();
                      }}
                      value={shops}
                      isMulti
                      placeholder={__('Obchod')}
                      handleOnChange={(e) => this.handleChange('shop', 0, e)}
                    />
                  </GreyBox>
                </AnimatedFormMessageWrapper>

                {/* <AnimatedFormMessageWrapper
                  display={
                    selectedType === 'shop' &&
                    ['F', 'I', 'J', 'N'].includes(selectedCampaign.type)
                  }
                >
                  <GreyBox title={__('Predajne')}>
                    <SearchableSelect
                      loadOptions={() => {
                        return fetchShops();
                      }}
                      value={shops}
                      isMulti
                      placeholder={__('Obchod')}
                      handleOnChange={e => this.handleChange('shop', 0, e)}
                    />
                  </GreyBox>
                </AnimatedFormMessageWrapper> */}

                <AnimatedFormMessageWrapper
                  display={
                    selectedType === 'customer' &&
                    !['M', 'O', 'P'].includes(selectedCampaign.type)
                  }
                >
                  <GreyBox title={__('Zákazník')}>
                    {customers &&
                      customers.map((item, index) => {
                        return (
                          item && (
                            <CustomFlexBoxStart key={uniqid()}>
                              <Text style={{ margin: rem(10) }}>
                                {item.label}
                              </Text>
                              {!readOnly && (
                                <IconWrapper
                                  onClick={() => this.deleteCustomer(index)}
                                >
                                  <Icon name="close" color="black" />
                                </IconWrapper>
                              )}
                            </CustomFlexBoxStart>
                          )
                        );
                      })}
                    <CustomFlexBoxStart>
                      <SearchableSelectWrapper>
                        <StyledSearchableSelect
                          value={tempCustomer}
                          loadOptions={(query) => this.fetchCustomers(query)}
                          name={tempCustomer}
                          placeholder={__('Vyhľadať zákazníka')}
                          handleOnChange={(e) =>
                            this.handleChange('customer', 0, e)
                          }
                        />
                      </SearchableSelectWrapper>
                      {tempCustomer && (
                        <div onClick={() => this.addCustomer()}>
                          <StyledIcon name="plus" color="#ee6500" />
                        </div>
                      )}
                    </CustomFlexBoxStart>

                    <UnderlinedLabel>{__('Skupina')}</UnderlinedLabel>
                    {customerTypes &&
                      customerTypes.map((item, index) => {
                        return (
                          <CustomFlexBoxStart key={uniqid()}>
                            <Text
                              style={{ margin: rem(10) }}
                            >{`${item.customerType} - ${item.customerSubType}`}</Text>
                            {!readOnly && (
                              <IconWrapper
                                onClick={() => this.deleteCustomerType(index)}
                              >
                                <Icon name="close" color="black" />
                              </IconWrapper>
                            )}
                          </CustomFlexBoxStart>
                        );
                      })}

                    <CustomFlexBoxStart>
                      <FlexBox>
                        <StyledCustomSelect
                          name="test"
                          size="s"
                          style={{ maxWidth: rem(300), display: 'flex' }}
                          onChange={(e) =>
                            this.handleChange('customerTypes', 0, e)
                          }
                        >
                          <option key="" value="">
                            {__('Zvoľte skupinu')}
                          </option>
                          {CustomerTypesLabels.map((type) => {
                            return (
                              <option
                                key={type.name}
                                value={type.name}
                                selected={type.name === tempCustomerType}
                              >
                                {type.name}
                              </option>
                            );
                          })}
                        </StyledCustomSelect>
                        {tempCustomerType.length > 0 && (
                          <StyledCustomSelect
                            style={{ maxWidth: rem(300), display: 'flex' }}
                            name="test"
                            size="s"
                            onChange={(e) =>
                              this.handleChange('customerSubTypes', 0, e)
                            }
                          >
                            <option key="" value="">
                              {'Zvoľte typ'}
                            </option>
                            {CustomerTypesLabels.find(
                              (c) => c.name === tempCustomerType,
                            ).subtypes.map((subType) => {
                              return (
                                <option key={subType} value={subType}>
                                  {subType}
                                </option>
                              );
                            })}
                          </StyledCustomSelect>
                        )}
                      </FlexBox>
                      {tempCustomerSubType.length > 0 && (
                        <div
                          style={{ width: '100%', display: 'flex' }}
                          onClick={() => this.addCustomerGroup()}
                        >
                          <StyledIcon name="plus" color="#ee6500" />
                        </div>
                      )}
                    </CustomFlexBoxStart>
                  </GreyBox>
                </AnimatedFormMessageWrapper>
                <AnimatedFormMessageWrapper
                  display={
                    selectedType === 'marketingList' &&
                    !['O', 'P'].includes(selectedCampaign.type)
                  }
                >
                  <GreyBox title={__('Marketingový zoznam')}>
                    <CustomFlexBoxStart>
                      <SearchableSelectWrapper>
                        <StyledSearchableSelect
                          value={marketingList}
                          loadOptions={(query) =>
                            searchMarketingLists(query, this.props.firm)
                          }
                          placeholder={__('Vyhľadať marketingový zoznam')}
                          handleOnChange={(e) =>
                            this.handleChange('marketingList', 0, e)
                          }
                        />
                      </SearchableSelectWrapper>
                    </CustomFlexBoxStart>
                  </GreyBox>
                </AnimatedFormMessageWrapper>
                <Space />
                <GreyBox title={__('Platnosť')}>
                  <StyledCustomFlexBoxStart>
                    <DatePickerWrapper>
                      <StyledDatePicker
                        placeholderText={__('Od')}
                        dateFormat="dd DD.MM.YYYY"
                        selected={validFrom.value}
                        error={validFrom.error}
                        onChange={(e) => {
                          this.handleChange('validFrom', 0, e);
                        }}
                      />
                    </DatePickerWrapper>
                    <DatePickerWrapper>
                      <StyledDatePicker
                        style={{ width: rem(50) }}
                        dateFormat="dd DD.MM.YYYY"
                        placeholderText={__('Do')}
                        selected={validTo.value}
                        onChange={(e) => {
                          this.handleChange('validTo', 0, e);
                        }}
                      />
                    </DatePickerWrapper>
                  </StyledCustomFlexBoxStart>
                </GreyBox>
                <Space />
                <GreyBox title={__('Zverejnenie')}>
                  <StyledCustomFlexBoxStart>
                    <DatePickerWrapper>
                      <StyledDatePicker
                        placeholderText={__('Od')}
                        dateFormat="dd DD.MM.YYYY"
                        selected={publishedFrom.value}
                        error={publishedFrom.error}
                        onChange={(e) => {
                          this.handleChange('publishedFrom', 0, e);
                        }}
                      />
                    </DatePickerWrapper>
                    <DatePickerWrapper>
                      <StyledDatePicker
                        style={{ width: rem(50) }}
                        dateFormat="dd DD.MM.YYYY"
                        placeholderText={__('Do')}
                        selected={publishedTo}
                        onChange={(e) => {
                          this.handleChange('publishedTo', 0, e);
                        }}
                      />
                    </DatePickerWrapper>
                  </StyledCustomFlexBoxStart>
                </GreyBox>
                <Space />
                <GreyBox title={__('Vlastník a schválenie')}>
                  <StyledCustomFlexBoxStart>
                    <DatePickerWrapper>
                      <Input
                        type="text"
                        value={user.display_name ? user.display_name : ''}
                        disabled
                        onChange={() => {}}
                      />
                    </DatePickerWrapper>
                  </StyledCustomFlexBoxStart>

                  {!this.props.copy && (
                    <StyledCustomFlexBoxStart style={{ marginTop: rem(10) }}>
                      <DatePickerWrapper>
                        <Input
                          type="text"
                          value={translateCampaignStatus(
                            status,
                            validTo.value || '',
                          )}
                          disabled
                          onChange={() => {}}
                        />
                      </DatePickerWrapper>
                    </StyledCustomFlexBoxStart>
                  )}
                  <StyledCustomFlexBoxStart style={{ marginTop: rem(10) }}>
                    {approved_by && approved_by.display_name && (
                      <TextSmall>{`${__('Schválená používateľom')} ${
                        approved_by.display_name
                      } dňa ${formatDate(
                        moment(approved_date),
                        FORMAT_HUMANE_DATETIME,
                      )}.`}</TextSmall>
                    )}
                  </StyledCustomFlexBoxStart>
                </GreyBox>
                <Space />
                <Space />
                <GreyBox title={__('Newsletter')}>
                  <CustomFlexBoxStart>
                    <SearchableSelectWrapper>
                      <StyledSearchableSelect
                        value={newsletter}
                        loadOptions={(query) => this.fetchNewsletter(query)}
                        name={newsletter}
                        placeholder={__('Vyhľadať a priradiť newsletter')}
                        handleOnChange={(e) =>
                          this.handleChange('newsletter', 0, e)
                        }
                      />
                    </SearchableSelectWrapper>
                  </CustomFlexBoxStart>
                  {newsletter && newsletter.value && (
                    <React.Fragment>
                      <StyledLink
                        target="_blank"
                        href={`/${this.props.firm}/newsletter/${newsletter.value}`}
                      >
                        {__('Otvoriť newsletter')}
                      </StyledLink>
                      <DownloadButton
                        primary
                        small
                        icon="download"
                        onClick={() => {
                          this.setState({
                            showDownloadPopup: true,
                          });
                        }}
                      >
                        {__('Stiahnuť PDF')}
                      </DownloadButton>
                    </React.Fragment>
                  )}
                </GreyBox>
              </React.Fragment>
            )}
          </SectionWrapper>
          <Space /> <Space />
          <Space />
          {marketingList &&
            selectedType === 'marketingList' &&
            !this.props.copy && (
              <MarketingListsContacts
                showPager
                small
                showUpdateButton={false}
                readonly
                limit={27}
                history={this.props.history}
                // saveConditions={() => this.saveData()}
                id={marketingList.value}
                refreshTable={refreshTable}
                title={__('Zoznam kontaktov')}
              />
            )}
          {selectedType === 'customer' &&
            (customers.length > 0 || customerTypes.length > 0) &&
            !this.props.copy && (
              <CustomersContactsList
                showPager
                small
                limit={27}
                showUpdateButton={false}
                readonly
                history={this.props.history}
                customersSubtypes={customerTypes.map((c) => c.customerSubType)}
                customersIds={customers.map((c) => c.value)}
                title={__('Zoznam kontaktov')}
              />
            )}
        </InsideContentWrapper>

        {selectedCampaign.type === 'F' && (
          <LabeledCheckbox
            checked={increaseBaseDiscount}
            labelText={__('Navýši základnu zľavu')}
            onChange={(e) => this.handleIncreaseBaseDiscount(e.target.checked)}
          />
        )}

        {/* -------------------------------------------------------------------------ALTERNATIVA 1--------------------------------------------------------------------- */}
        {selectedCampaign.type === campaignLabels[0].type && (
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Výrobok')}</Th>
                <Th>{__('Zľava')}</Th>
                {/* <Th>{__('Nahrádza základnú zľavu')}</Th> */}
                <Th />
              </Tr>
            </Thead>

            <Tbody>
              <Tr>
                <Td style={{ minWidth: rem(200) }}>{__('Všetky')}</Td>
                <Td style={{ minWidth: rem(200) }}>
                  <ValueInputWrapper>
                    <StyledInput
                      type="number"
                      placeholder={__('Hodnota')}
                      value={alt1.all.value}
                      onChange={(e) => {
                        this.handleChange('alt1.all.value', 0, e);
                      }}
                    />
                    <Percent>%</Percent>
                  </ValueInputWrapper>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}

        {selectedCampaign.type === campaignLabels[1].type && (
          <div>
            {alt1.items.length > 0 && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Kategória')}</Th>
                    <Th>{__('Značka')}</Th>
                    <Th>{__('Výrobok')}</Th>
                    <Th>
                      <StyledInput
                        placeholder={__('Zľava')}
                        type="number"
                        min={0}
                        max={100}
                        onChange={(e) => {
                          alt1.items.forEach((item, index) => {
                            this.handleChange('alt1.product.value', index, e);
                          });
                        }}
                      />
                    </Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {alt1.items.map((item, index) => {
                    return (
                      <Tr key={`${item.product._id}`}>
                        <Td style={{ minWidth: rem(300) }}>
                          <SearchableSelect
                            autoload={false}
                            value={
                              item.category &&
                              item.category !== null &&
                              item.category.label &&
                              item.category
                            }
                            keyProps={
                              item.category &&
                              item.category !== null &&
                              item.category.value
                            }
                            loadOptions={(query) => this.fetchCategories(query)}
                            placeholder={__('Kategória')}
                            name={item.category}
                            disabled={
                              item.product === null
                                ? false
                                : item.product.product
                            }
                            handleOnChange={(e) =>
                              this.handleChange('alt1.category', index, e)
                            }
                          />
                        </Td>
                        <Td style={{ minWidth: rem(200) }}>
                          <SearchableSelect
                            autoload={false}
                            value={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.label &&
                              item.brand
                            }
                            keyProps={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.value
                            }
                            loadOptions={(query) => this.fetchBrand(query)}
                            placeholder={__('Značka')}
                            name={item.brand}
                            disabled={
                              (item.product && item.product.label) ||
                              (item.category && item.category.label)
                            }
                            handleOnChange={(e) =>
                              this.handleChange('alt1.brand', index, e)
                            }
                          />
                        </Td>
                        <Td
                          style={{ minWidth: rem(450) }}
                          onClick={() =>
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt1.product',
                              },
                            })
                          }
                        >
                          <SearchableSelect
                            value={
                              item.product !== null &&
                              item.product.label &&
                              item.product
                            }
                            loadOptions={() => {}}
                            placeholder={__('Názov')}
                            keyProps={
                              item.product !== null && item.product.value
                            }
                            disabled={
                              (item.brand && item.brand.label) ||
                              (item.category && item.category.label)
                            }
                          />
                        </Td>
                        <Td style={{ minWidth: rem(200) }}>
                          <ValueInputWrapper>
                            <StyledInput
                              type="number"
                              placeholder={__('Hodnota')}
                              value={item.value}
                              onChange={(e) => {
                                this.handleChange(
                                  'alt1.product.value',
                                  index,
                                  e,
                                );
                              }}
                            />
                            <Percent>%</Percent>
                          </ValueInputWrapper>
                        </Td>
                        <Td>
                          {!readOnly && (
                            <DeleteLine>
                              <IconWrapper
                                onClick={() => this.deleteProduct(1, index)}
                              >
                                <Icon name="close" color="black" />
                              </IconWrapper>
                            </DeleteLine>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}

            <StyledButton
              primary
              small
              icon="plus"
              onClick={() => this.addProduct(1)}
            >
              {__('Pridať')}
            </StyledButton>
          </div>
        )}

        {selectedCampaign.type === campaignLabels[2].type && (
          <div>
            {alt1.items.length > 0 && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Kategória')}</Th>
                    <Th>{__('Značka')}</Th>
                    <Th>{__('Výrobok')}</Th>
                    <Th>{__('Zľava')}</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {alt1.items.map((item, index) => {
                    return (
                      <Tr key={uniqid()}>
                        <Td style={{ minWidth: rem(300) }}>
                          <SearchableSelect
                            autoload={false}
                            value={
                              item.category &&
                              item.category !== null &&
                              item.category.label &&
                              item.category
                            }
                            keyProps={
                              item.category &&
                              item.category !== null &&
                              item.category.value
                            }
                            loadOptions={(query) => this.fetchCategories(query)}
                            placeholder={__('Kategória')}
                            name={item.category}
                            disabled={
                              item.product === null
                                ? false
                                : item.product.product
                            }
                            handleOnChange={(e) =>
                              this.handleChange('alt1.category', index, e)
                            }
                          />
                        </Td>
                        <Td style={{ minWidth: rem(200) }}>
                          <SearchableSelect
                            autoload={false}
                            value={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.label &&
                              item.brand
                            }
                            loadOptions={(query) => this.fetchBrand(query)}
                            placeholder={__('Značka')}
                            keyProps={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.value
                            }
                            name={item.brand}
                            disabled={
                              (item.product && item.product.label) ||
                              (item.category && item.category.label)
                            }
                            handleOnChange={(e) =>
                              this.handleChange('alt1.brand', index, e)
                            }
                          />
                        </Td>
                        <Td
                          style={{ minWidth: rem(450) }}
                          onClick={() =>
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt1.product',
                              },
                            })
                          }
                        >
                          <SearchableSelect
                            value={
                              item.product !== null &&
                              item.product.label &&
                              item.product
                            }
                            loadOptions={() => {}}
                            placeholder={__('Názov')}
                            keyProps={
                              item.product !== null && item.product.value
                            }
                            disabled={item.brand && item.brand.label}
                          />
                        </Td>
                        <Td style={{ minWidth: rem(200) }}>
                          <ValueInputWrapper>
                            <StyledInput
                              type="number"
                              placeholder={__('Hodnota')}
                              value={item.value}
                              onChange={(e) => {
                                this.handleChange(
                                  'alt1.product.value',
                                  index,
                                  e,
                                );
                              }}
                            />
                            <Percent> %</Percent>
                          </ValueInputWrapper>
                        </Td>
                        <Td>
                          {!readOnly && (
                            <DeleteLine>
                              <IconWrapper
                                onClick={() => this.deleteProduct(1, index)}
                              >
                                <Icon name="close" color="black" />
                              </IconWrapper>
                            </DeleteLine>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}

            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(1)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {/* -------------------------------------------------------------------------ALTERNATIVA 2--------------------------------------------------------------------- */}

        {selectedCampaign.type === campaignLabels[5].type && (
          <div>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  {/* <Th>{__('Kód výrobku(benefit)')}</Th> */}
                  <Th>{__('Názov/Kód výrobku(benefit)')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt2.items.map((item, index) => {
                  return (
                    <Tr key={uniqid()}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          isClearable={false}
                        />
                      </Td>

                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.freeProduct',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.freeProduct.product && item.freeProduct}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          isClearable={false}
                        />
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(2, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(2)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[3].type && (
          <div>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  {/* <Th>{__('Kód výrobku(benefit)')}</Th> */}
                  <Th>{__('Názov/Kód výrobku(benefit)')}</Th>
                  <Th>{__('Cena bez DPH')}</Th>
                  <Th>{__('Cena s DPH')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt2.items.map((item, index) => {
                  return (
                    <Tr key={uniqid()}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          isClearable={false}
                          name={item.product}
                        />
                      </Td>

                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.freeProduct',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.freeProduct.product && item.freeProduct}
                          loadOptions={() => {}}
                          isClearable={false}
                          placeholder={__('Názov')}
                        />
                      </Td>
                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Cena bez DPH')}
                            value={item.freeProductPrice}
                            onChange={(e) => {
                              this.handleChange(
                                'alt2.freeProduct.price',
                                index,
                                e,
                              );
                            }}
                          />
                          <Percent>
                            {this.props.firm === 'SK' ? '€' : 'CZK'}
                          </Percent>
                        </ValueInputWrapper>
                      </Td>

                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Cena s DPH')}
                            value={getPriceWithVat(
                              this.props.vatRate,
                              Number(item.freeProductPrice),
                            )}
                            onChange={(e) => {
                              this.handleChange(
                                'alt2.freeProduct.price_vat',
                                index,
                                e,
                              );
                            }}
                          />
                          <Percent>
                            {this.props.firm === 'SK' ? '€' : 'CZK'}
                          </Percent>
                        </ValueInputWrapper>
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(2, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(2)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[4].type && (
          <div>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  {/* <Th>{__('Kód výrobku(benefit)')}</Th> */}
                  <Th>{__('Názov/Kód výrobku(benefit)')}</Th>
                  <Th>{__('Zľava')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt2.items.map((item, index) => {
                  return (
                    <Tr key={uniqid()}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          name={item.product}
                          isClearable={false}
                        />
                      </Td>

                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.freeProduct',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.freeProduct.product && item.freeProduct}
                          loadOptions={() => {}}
                          name={item.freeProduct}
                          isClearable={false}
                          placeholder={__('Názov')}
                        />
                      </Td>
                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Zľava')}
                            value={item.freeProductDiscount}
                            onChange={(e) => {
                              this.handleChange(
                                'alt2.freeProduct.discount',
                                index,
                                e,
                              );
                            }}
                          />
                          <Percent>%</Percent>
                        </ValueInputWrapper>
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(2, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(2)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {/* -------------------------------------------------------------------------ALTERNATIVA 3--------------------------------------------------------------------- */}
        {selectedCampaign.type === campaignLabels[6].type && (
          <div>
            <StyledCustomFlexBoxStart>
              <StyledLabel>{__('Minimálna cena: ')}</StyledLabel>
              <ValueInputWrapper>
                <StyledInput
                  type="number"
                  placeholder={__('Hodnota')}
                  value={alt3.minValue}
                  onChange={(e) => {
                    this.handleChange('alt3.minValue', 0, e);
                  }}
                />
                <Percent>{this.props.firm === 'SK' ? '€' : 'CZK'}</Percent>
              </ValueInputWrapper>
            </StyledCustomFlexBoxStart>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  <Th>{__('Cena')}</Th>
                  <Th>{__('Zľava')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt3.items.map((item, index) => {
                  return (
                    <Tr key={uniqid()}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt3.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          isClearable={false}
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                        />
                      </Td>

                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Hodnota')}
                            value={item.productPrice}
                            disabled={item.productDiscount > 0}
                            onChange={(e) => {
                              this.handleChange('alt3.product.price', index, e);
                            }}
                          />
                          <Percent>
                            {this.props.firm === 'SK' ? '€' : 'CZK'}
                          </Percent>
                        </ValueInputWrapper>
                      </Td>

                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Hodnota')}
                            disabled={item.productPrice > 0}
                            value={item.productDiscount}
                            onChange={(e) => {
                              this.handleChange(
                                'alt3.product.discount',
                                index,
                                e,
                              );
                            }}
                          />
                          <Percent> %</Percent>
                        </ValueInputWrapper>
                      </Td>
                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(3, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => {
                  if (!readOnly) {
                    this.setState({
                      showProductsSelector: {
                        alt: 'alt3.product',
                      },
                    });
                  }
                }}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[7].type && (
          <div>
            <StyledCustomFlexBoxStart>
              <StyledLabel>{__('Minimálna cena: ')}</StyledLabel>
              <ValueInputWrapper>
                <StyledInput
                  type="number"
                  placeholder={__('Hodnota')}
                  value={alt3.minValue}
                  onChange={(e) => {
                    this.handleChange('alt3.minValue', 0, e);
                  }}
                />
                <Percent>{this.props.firm === 'SK' ? '€' : 'CZK'}</Percent>
              </ValueInputWrapper>
            </StyledCustomFlexBoxStart>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt3.items.map((item, index) => {
                  return (
                    <Tr key={uniqid()}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt3.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          isClearable={false}
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                        />
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(3, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => {
                  if (!readOnly) {
                    this.setState({
                      showProductsSelector: {
                        alt: 'alt3.product',
                      },
                    });
                  }
                }}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[8].type && (
          <CampaignMWarraper>
            <GreyBox title={__('Základné nastavenia')}>
              <Label>{__('Použitie')}</Label>
              <FlexBoxStart>
                <LabeledCheckbox
                  checked={campaignM.usage === 'one-time'}
                  labelText={__('Jednorázová')}
                  onChange={() =>
                    this.handleCampaignMChange('usage', 'one-time')
                  }
                />
                <Space />
                <LabeledCheckbox
                  checked={campaignM.usage === 'multiple-time'}
                  labelText={__('Viacnásobná')}
                  onChange={() =>
                    this.handleCampaignMChange('usage', 'multiple-time')
                  }
                />
              </FlexBoxStart>
              <Space />
              <Label>{__('Kód kupónu')}</Label>
              <LabeledCheckbox
                checked={campaignM.codeGenerated}
                error={campaignM.codeError}
                labelText={__('Vygenerovať systémom')}
                onChange={(e) =>
                  this.handleCampaignMChange('codeGenerated', e.target.checked)
                }
              />
              <Space style={{ height: rem(8) }} />
              <Input
                style={{
                  width: rem(150),
                }}
                type="text"
                placeholder={__('Zadajte kód kupónu')}
                value={campaignM.code}
                disabled={campaignM.codeGenerated}
                onChange={(e) =>
                  this.handleCampaignMChange('code', e.target.value)
                }
              />
              {campaignM.codeGenerated && (
                <Hint>{__('Každý zákazník dostane jedinečný kupón')}</Hint>
              )}
            </GreyBox>
            <Space />
            <GreyBox title={__('Zľava')}>
              <Label>{__('Typ zľavy')}</Label>
              <FlexBoxStart>
                <LabeledCheckbox
                  labelText={__('Plošná')}
                  checked={campaignM.discountType === 'flat'}
                  onChange={() =>
                    this.handleCampaignMChange('discountType', 'flat')
                  }
                />
                <Space />
                <LabeledCheckbox
                  checked={campaignM.discountType === 'progressive'}
                  labelText={__('Progresívna')}
                  onChange={() =>
                    this.handleCampaignMChange('discountType', 'progressive')
                  }
                />
              </FlexBoxStart>
              <Space />
              {campaignM.discountType === 'flat' ? (
                <React.Fragment>
                  <Label>{__('Percentuálna zľava')}</Label>
                  <Input
                    style={{
                      width: rem(150),
                    }}
                    type="number"
                    placeholder={__('Výška % zľavy')}
                    value={campaignM.discount}
                    onChange={(e) =>
                      this.handleCampaignMChange('discount', e.target.value)
                    }
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Label>{__('Tabuľka zliav')}</Label>
                  <FlexBoxStart>
                    <LabeledCheckbox
                      labelText={__('S DPH')}
                      checked={campaignM.discountWithVat}
                      onChange={(e) =>
                        this.handleCampaignMChange(
                          'discountWithVat',
                          e.target.checked,
                        )
                      }
                    />
                    <Space />
                    <LabeledCheckbox
                      checked={!campaignM.discountWithVat}
                      labelText={__('Bez DPH')}
                      onChange={(e) =>
                        this.handleCampaignMChange(
                          'discountWithVat',
                          !e.target.checked,
                        )
                      }
                    />
                  </FlexBoxStart>
                  <Space />

                  {campaignM.discounts.map((d, index) => (
                    <FlexBoxStart>
                      <SmallNumberInput
                        type="number"
                        placeholder={`${__('Od')} (${getCurrencyByFirm(
                          this.props.firm,
                        )})`}
                        value={d.valueFrom}
                        error={d.error}
                        onChange={(e) =>
                          this.handleProgresiveDiscountChange(
                            'valueFrom',
                            e.target.value,
                            index,
                          )
                        }
                      />
                      <Space /> <Space />
                      <SmallNumberInput
                        type="number"
                        placeholder={__('%')}
                        value={d.discountPercent}
                        onChange={(e) =>
                          this.handleProgresiveDiscountChange(
                            'discountPercent',
                            e.target.value,
                            index,
                          )
                        }
                      />
                    </FlexBoxStart>
                  ))}
                </React.Fragment>
              )}
            </GreyBox>
          </CampaignMWarraper>
        )}

        {selectedCampaign.type === campaignLabels[10].type && (
          <CampaignMWarraper>
            <GreyBox title={__('Použitie')}>
              <FlexBoxStart>
                <LabeledCheckbox
                  checked={campaignO.usage === 'one-time'}
                  labelText={__('Jednorázová')}
                  onChange={() =>
                    this.handleCampaignOChange('usage', 'one-time')
                  }
                />
                <Space />
                <LabeledCheckbox
                  checked={campaignO.usage === 'multiple-time'}
                  labelText={__('Viacnásobná')}
                  onChange={() =>
                    this.handleCampaignOChange('usage', 'multiple-time')
                  }
                />
              </FlexBoxStart>
            </GreyBox>
            <Space />
            <GreyBox title={__('Zľava')}>
              <CampaignVoucherRow>
                <div className="input-wrapper">
                  <Input
                    type="text"
                    placeholder={__('Kupón')}
                    value={campaignO.voucher}
                    onChange={(e) =>
                      this.handleCampaignOChange('voucher', e.target.value)
                    }
                  />

                  <div
                    className="generate-voucher"
                    onClick={() => this.generateVoucher('O')}
                  >
                    {__('Vygenerovať')}
                  </div>
                </div>

                <div className="input-wrapper">
                  <Input
                    type="number"
                    placeholder={`${__('Platí od')} ${getCurrencyByFirm(
                      this.props.firm,
                    )}`}
                    onChange={(e) =>
                      this.handleCampaignOChange('amount_from', e.target.value)
                    }
                    value={campaignO.amount_from}
                  />
                </div>
              </CampaignVoucherRow>
            </GreyBox>
          </CampaignMWarraper>
        )}

        {selectedCampaign.type === campaignLabels[11].type && (
          <CampaignMWarraper>
            <GreyBox title={__('Použitie')}>
              <FlexBoxStart>
                <LabeledCheckbox
                  checked={campaignP.usage === 'one-time'}
                  labelText={__('Jednorázová')}
                  onChange={() =>
                    this.handleCampaignPChange('usage', 'one-time')
                  }
                />
                <Space />
                <LabeledCheckbox
                  checked={campaignP.usage === 'multiple-time'}
                  labelText={__('Viacnásobná')}
                  onChange={() =>
                    this.handleCampaignPChange('usage', 'multiple-time')
                  }
                />
              </FlexBoxStart>
            </GreyBox>
            <Space />
            <GreyBox title={__('Zľava')}>
              {(campaignP.vouchers || []).map((v, index) => (
                <CampaignVoucherRow>
                  <div className="input-wrapper">
                    <Input
                      type="text"
                      placeholder={__('Kupón')}
                      value={v.voucher}
                      onChange={(e) =>
                        this.handleCampaignPVoucherChange(
                          index,
                          'voucher',
                          e.target.value,
                        )
                      }
                    />
                    {v.voucher.length === 0 && (
                      <div
                        className="generate-voucher"
                        onClick={() => this.generateVoucher('P', index)}
                      >
                        {__('Vygenerovať')}
                      </div>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <Input
                      type="number"
                      placeholder={__('Zľava %')}
                      value={v.discount}
                      onChange={(e) =>
                        this.handleCampaignPVoucherChange(
                          index,
                          'discount',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                  <div className="input-wrapper">
                    <Input
                      type="number"
                      placeholder={`${__('Platí od')} ${getCurrencyByFirm(
                        this.props.firm,
                      )}`}
                      onChange={(e) =>
                        this.handleCampaignPVoucherChange(
                          index,
                          'amount_from',
                          e.target.value,
                        )
                      }
                      value={v.amount_from}
                    />
                  </div>
                </CampaignVoucherRow>
              ))}
            </GreyBox>
          </CampaignMWarraper>
        )}

        {selectedCampaign.type === campaignLabels[9].type && (
          <div>
            <NplusOneCampaign
              onSaveClicked={(data) => this.saveDataNew(data)}
              defaultData={campaignN && campaignN.products}
              loading={saveLoading}
              readOnly={readOnly}
            />
          </div>
        )}

        {!readOnly && selectedCampaign.type !== campaignLabels[9].type && (
          <StyledButtonRight
            primary
            loading={loading}
            disabled={
              selectedCampaign.type !== 'O' &&
              selectedCampaign.type !== 'P' &&
              selectedType !== 'xpaint' &&
              (isEmptyArray(selectedCampaign.type) ||
                (isEmptyArray(customers) &&
                  isEmptyArray(customerTypes) &&
                  selectedType !== 'xpaint' &&
                  !marketingList &&
                  isEmptyArray(shops)))
            }
            onClick={() =>
              this.props.campaignId !== 'new' && status === 'approved'
                ? this.setState({
                    showSaveDialog: true,
                  })
                : this.sendData()
            }
          >
            {this.props.campaignId !== 'new' && !this.props.copy
              ? __('Upraviť')
              : __('Vytvoriť')}
          </StyledButtonRight>
        )}
      </Content>
    );
  }
}

AddCampaigns.propTypes = {
  campaignId: PropTypes.number,
  firm: PropTypes.string,
  closePopup: PropTypes.func,
};

AddCampaigns.defaultProps = {
  campaignId: null,
  firm: 'SK',
  closePopup: () => {},
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(AddCampaigns);
