import React from 'react';
import { Loader } from 'oxyrion-ui/lib';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import SynchronizationLogs from '../SynchronizationLogs';
import {
  __,
  getIdFromProps,
  formatDate,
  getSyncNameType,
  getSyncNameStatus,
  getSyncNameByType,
  getDurationFromDates,
} from '../../Utils';
import { connect } from '../../Store';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import ControllBar from '../../Components/ControllBar';
import API2 from '../../API2';

const Wrapper = styled.div``;

const SyncWrapper = styled.div`
  margin: 0;
  padding: 0;
  /* margin: ${rem(16)}; */
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SyncName = styled.h3`
  font-weight: normal;
  font-weight: bold;
  margin-right: ${rem(20)};
  font-size: ${rem(16)};
  padding: ${rem(20)};
`;

const SyncDetailsWrapper = styled.div`
  margin: ${rem(16)};
`;

const SyncDetailInfo = styled.span`
  font-weight: bold;
  margin-right: ${rem(8)};
`;

const SyncDetailValue = styled.span`
  margin-right: ${rem(20)};
`;

class Synchronization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      syncDetail: {},
    };
  }

  async componentDidMount() {
    const { firm } = this.props;
    const id = getIdFromProps(this.props);
    const syncDetail = await API2.getSynchronizationAction(firm, id);
    this.setState({
      loading: false,
      syncDetail,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Synchronizácia')} />;
  }
  render() {
    const { loading, syncDetail } = this.state;
    const { firm, history } = this.props;
    const syncId = getIdFromProps(this.props);

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <Wrapper>
          <SyncWrapper>
            <SyncName>{getSyncNameByType(syncDetail.type)}</SyncName>
            <SyncDetailsWrapper>
              <SyncDetailInfo>{__('Typ synchronizácie')}:</SyncDetailInfo>
              <SyncDetailValue>
                {getSyncNameType(syncDetail.type)}
              </SyncDetailValue>
            </SyncDetailsWrapper>
            <SyncDetailsWrapper>
              <SyncDetailInfo>{__('Aktuálny stav')}:</SyncDetailInfo>
              <SyncDetailValue>
                {getSyncNameStatus(syncDetail.status)}
              </SyncDetailValue>
            </SyncDetailsWrapper>
            <SyncDetailsWrapper>
              <SyncDetailInfo>{__('Dátum vytvorenia')}:</SyncDetailInfo>
              <SyncDetailValue>
                {formatDate(syncDetail.start_date_time)}
              </SyncDetailValue>
            </SyncDetailsWrapper>
            <SyncDetailsWrapper>
              <SyncDetailInfo>{__('Trvanie')}:</SyncDetailInfo>
              <SyncDetailValue>
                {getDurationFromDates(
                  syncDetail.start_date_time,
                  syncDetail.end_date_time,
                )}
              </SyncDetailValue>
            </SyncDetailsWrapper>
          </SyncWrapper>
        </Wrapper>
        <SynchronizationLogs firm={firm} syncId={syncId} history={history} />
      </React.Fragment>
    );
  }
}

Synchronization.propTypes = {
  firm: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
Synchronization.defaultProps = {
  firm: '',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Synchronization);
