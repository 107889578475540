import React from 'react';
import BaseTable from '../BaseTable';
import tableHeaders from '../../ContentConfig/synchornizations';
import {
  __,
  renderLink,
  formatDate,
  getSyncNameType,
  getSyncNameStatus,
  getSyncNameByType,
  getDurationFromDates,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../Components/ReusableComponents';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

class Synchronizations extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: '-start_date_time' });
    this.headers = tableHeaders;
    this.segmentName = __('Synchronizacie');
    this.accessors = ['name', 'type', 'status', 'start_date_time', 'duration'];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('-start_date_time');
    this.state = {
      ...this.state,
      type: 'Všetky typy',
      syncStatus: 'Všetky statusy',
    };
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, syncStatus, type } = this.state;
      this.setState({
        loading: true,
      });
      const params = Object.assign({}, parameters, { sorter });
      switch (syncStatus) {
        case 'Bežiace':
          params.status = 'running';
          break;
        case 'Úspešné':
          params.status = 'success';
          break;
        case 'Neúspešné':
          params.status = 'failed';
          break;
        default:
          params.status = undefined;
      }

      switch (type) {
        case 'Zákazníci':
          params.type = 'customers';
          break;
        case 'Produkty':
          params.type = 'products';
          break;
        case 'Názvy produktov':
          params.type = 'products-names';
          break;
        case 'Obraty':
          params.type = 'turnoverAndPlan';
          break;
        case 'Color Centrum Faktúry':
          params.type = 'colorCentrumInvoices';
          break;

        case 'Google shopping feed':
          params.type = 'google-shopping-products';
          break;

        case 'Katalogové produkty':
          params.type = 'xpaint_catalog_products';
          break;

        case 'Stavy objednávok':
          params.type = 'eshopOrdersStatus';
          break;

        case 'Heuréka feed':
          params.type = 'heureka-products';
          break;

        case 'Meta pixel feed':
          params.type = 'meta-pixel-products';
          break;

        case 'Reklamácie a vrátenia notifkácie':
          params.type = 'warratnyClaimsCheck';
          break;

        case 'Algolia - Rady a tipy':
          params.type = 'algolia-news';
          break;

        case 'Algolia - Inšpirácie':
          params.type = 'algolia-inspirations';
          break;

        case 'Algolia - Kategórie':
          params.type = 'algolia-categories';
          break;

        case 'Algolia - Produkty':
          params.type = 'algolia-products';
          break;

        case 'Katalóg xFarby':
          params.type = 'xpaint_catalog_products';
          break;

        case 'Algolia - Flugger':
          params.type = 'flugger-products';
          break;
        default:
          params.type = undefined;
      }
      const newData = await API2.getSynchronizationsAction(
        this.props.firm,
        params,
      );

      this.setState({
        content: this.normalizeColumns(newData.items),
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  handleSyncOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/synchronizations/${id}`);
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(
            `./synchronizations/${item._id}`,
            `${getSyncNameByType(item.type, item.start_date_time, item.firm) ||
              '-'}`,
            () => this.handleSyncOnClick(item._id),
          ),
        ),
        type: _shapeData(getSyncNameType(item.type)),
        status: _shapeData(getSyncNameStatus(item.status)),
        start_date_time: _shapeData(
          formatDate(item.start_date_time, FORMAT_HUMANE_DATETIME),
        ),
        duration: _shapeData(
          getDurationFromDates(item.start_date_time, item.end_date_time),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleStatusChange(status, statusMessage) {
    this.setState(
      {
        active: status,
        sorter: 'start_date_time',
        syncStatus: __(statusMessage),
      },
      () => {
        this.setSorter('start_date_time');
        this.fetchData();
      },
    );
  }
  handleTypeChange(status, typeMessage) {
    this.setState(
      { active: status, sorter: 'start_date_time', type: __(typeMessage) },
      () => {
        this.setSorter('start_date_time');
        this.fetchData();
      },
    );
  }

  async syncTurnover(firm) {
    try {
      if (firm === 'SK') {
        this.setState({
          syncTurnoverLoadingSK: true,
        });
      } else {
        this.setState({
          syncTurnoverLoadingCZ: true,
        });
      }

      await API2.navisionSyncCustomersTurnoverAndBudgetEntryAction(firm);

      this.setState({
        syncTurnoverLoadingSK: false,
        syncTurnoverLoadingCZ: false,
        success: __('Obraty boli úspešne presynchronizované'),
      });
    } catch (e) {
      this.setState({
        syncTurnoverLoadingSK: false,
        syncTurnoverLoadingCZ: false,
        error: __('Obraty sa nepodarilo presynchronizovať'),
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const {
      type,
      syncStatus,
      syncTurnoverLoadingSK,
      syncTurnoverLoadingCZ,
    } = this.state;
    return (
      <ControllBar history={history} name={__('Synchronizacie')}>
        <ControlBarButton
          loading={syncTurnoverLoadingSK}
          disabled={syncTurnoverLoadingSK}
          primary
          showIcon={false}
          onClick={() => this.syncTurnover('SK')}
        >
          {__('Synchronizovať obraty SK')}
        </ControlBarButton>

        <ControlBarButton
          loading={syncTurnoverLoadingCZ}
          disabled={syncTurnoverLoadingCZ}
          showIcon={false}
          primary
          onClick={() => this.syncTurnover('CZ')}
        >
          {__('Synchronizovať obraty CZ')}
        </ControlBarButton>

        <ControlButton name={syncStatus}>
          <LinkWrap>
            <div
              onClick={e => this.handleStatusChange(true, e.target.innerHTML)}
            >
              {__('Všetky statusy')}
            </div>
            <div
              onClick={e => this.handleStatusChange(true, e.target.innerHTML)}
            >
              {__('Bežiace')}
            </div>
            <div
              onClick={e => this.handleStatusChange(true, e.target.innerHTML)}
            >
              {__('Úspešné')}
            </div>
            <div
              onClick={e => this.handleStatusChange(true, e.target.innerHTML)}
            >
              {__('Neúspešné')}
            </div>
          </LinkWrap>
        </ControlButton>
        <ControlButton name={type}>
          <LinkWrap>
            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Všetky typy')}
            </div>
            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Zákazníci')}
            </div>
            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Produkty')}
            </div>
            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Obraty')}
            </div>
            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Color Centrum Faktúry')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Stavy objednávok')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Heuréka feed')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Meta pixel feed')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Reklamácie a vrátenia notifkácie')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Algolia - Produkty')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Katalóg xFarby')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Algolia - Kategórie')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Algolia - Inšpirácie')}
            </div>

            <div onClick={e => this.handleTypeChange(true, e.target.innerHTML)}>
              {__('Algolia - Rady a tipy')}
            </div>
          </LinkWrap>
        </ControlButton>
      </ControllBar>
    );
  }
  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Synchronizations);
