import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { rem } from 'polished';
import campaignsHeaders from '../../ContentConfig/campaignsHeaders';
import {
  __,
  renderLink,
  formatDate,
  translateCampaignStatus,
} from '../../Utils';
import { connect } from '../../Store';
import BaseTable from '../BaseTable';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import FileViewDownload from '../../Components/FileViewDownload';
import CheckAccess from '../../ACL/CheckAccess';
import API2 from '../../API2';

const BulkWrapper = styled.div`
  max-width: ${({ display }) => (display ? rem(200) : '0')};
  opacity: ${({ display }) => (display ? '1' : '0')};
  display: ${({ display }) => (display ? 'block' : 'none')}
  transition: max-width 0.5s, opacity 0.5s;
`;

const localFilters = [
  {
    name: 'Filter podľa stavu',
    filterValue: 'state',
    values: [
      {
        name: __('Všetky'),
        value: 'all',
      },
      {
        name: __('Aktívne'),
        value: 'active',
      },
      {
        name: __('Na schvalenie'),
        value: 'for_approval',
      },
      {
        name: __('Čaká na schvalenie'),
        value: 'pending',
      },
      {
        name: __('Schválené'),
        value: 'aprroved',
      },
      {
        name: __('Ukončené'),
        value: 'finished',
      },
    ],
    multiple: false,
    default: 'all',
  },
];

class Campaing extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = campaignsHeaders;
    this.segmentName = __('Kampane');
    this.showBulkOperations = true;
    this.accessors = [
      'name',
      'firm',
      'type',
      'status',
      'customers_count',
      'validFrom',
      'validTo',
      'publishFrom',
      'publishTo',
      'newsletter',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      showBulkOperations: true,
      addCampaignShowPopup: false,
      selectedFilter: localFilters.map(f => ({
        filterValue: f.filterValue,
        selected: f.default,
      })),
    };

    this.filters = localFilters;

    this.toggleAddCampaign = this.toggleAddCampaign.bind(this);
  }

  getCampaignTypeByData(campaign) {
    if (campaign.shop_ids && campaign.shop_ids.length > 0) {
      return __('Predajne');
    }

    if (campaign.xpaint_campaign) {
      return __('Xfarby');
    }

    return __('B2B');
  }

  toggleAddCampaign() {
    // if (this.state.addCampaignShowPopup) {
    //   this.fetchData();
    //   // refresh list on popup close
    // }

    // this.setState({
    //   addCampaignShowPopup: !this.state.addCampaignShowPopup,
    // });
    const { history, firm } = this.props;

    history.push(`/${firm}/campaigns/new`);
  }

  buildParamsByFilter(selectedFilter) {
    const state = selectedFilter.find(s => s.filterValue === 'state');

    if (state.selected === 'active') {
      return {
        state: 'active',
      };
    }

    if (state.selected === 'for_approval') {
      return {
        state: 'for_approval',
      };
    }

    if (state.selected === 'pending') {
      return {
        state: 'pending',
      };
    }

    if (state.selected === 'aprroved') {
      return {
        state: 'approved',
      };
    }

    if (state.selected === 'finished') {
      return {
        state: 'finished',
      };
    }

    return {};
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, selectedFilter } = this.state;
      const { firm } = this.props;
      this.setState({ loading: true });

      const params = Object.assign(
        this.buildParamsByFilter(selectedFilter),
        parameters,
        { sorter },
      );

      const newData = await API2.getCampaignsAction(firm, params);
      const content = this.normalizeColumns(newData.campaigns);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      this.setState({
        error: e,
      });
    }
  }

  handleProductOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/campaigns/${id}`);
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  async campaignsDeactivation() {
    try {
      const { checkedRows } = this.state;
      const { firm } = this.props;
      await API2.putCampainsValidityAction(firm, {
        data: { campaign_ids: checkedRows },
      });
      this.fetchData();
      this.setState({
        checkedRows: [],
        allRoswChecked: false,
      });
    } catch (e) {
      this.setState({ error: __('Pri deaktivácii kampaní sa vyskytla chyba') });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null) => {
      return {
        value: value || '-',
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        name: _shapeData(
          renderLink(`./campaigns/${item._id}`, `${item.name}`),
          () => this.handleProductOnClick(item._id),
        ),
        firm: _shapeData(item.firm),
        type: _shapeData(this.getCampaignTypeByData(item)),
        // discount: _shapeData(item.discount),
        status: _shapeData(
          translateCampaignStatus(
            item.approval_request ? item.approval_request.status : ' - ',
            item.valid_to,
          ),
        ),
        customers_count: _shapeData(item.customers_count),
        validFrom: _shapeData(moment(item.valid_from).format('DD.MM.YYYY')),
        validTo: _shapeData(item.valid_to && formatDate(moment(item.valid_to))),
        publishFrom: _shapeData(
          item.publish_from && formatDate(moment(item.publish_from)),
        ),
        publishTo: _shapeData(
          item.publish_to && formatDate(moment(item.publish_to)),
        ),
        id: item._id,
        newsletter: {
          value: item.newsletter ? (
            <FileViewDownload
              name={item.newsletter.name}
              type={'pdf'}
              history={this.props.history}
              downloadFunction={async () => {
                return API2.getNewsletterPdfAction(
                  this.props.firm,
                  item.newsletter._id,
                );
              }}
              error={error => {
                this.setState({ error });
              }}
            />
          ) : (
            __('Bez newslettera')
          ),
        },
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { checkedRows, selectedFilter } = this.state;
    return (
      <ControllBar
        history={history}
        name={__('Kampane')}
        defaultValue={this.state.lastSearchValue}
        onChange={val => this.loadSearchRrsult(val)}
      >
        {this.renderConfigFilters(selectedFilter)}

        <CheckAccess
          operation="postCampaignAction"
          Component={
            <ControlBarButton
              primary
              small
              onClick={() => this.toggleAddCampaign()}
              icon="plus"
            >
              {__('Pridať kampaň')}
            </ControlBarButton>
          }
        />
        <CheckAccess
          operation="putCampaignAction"
          Component={
            <BulkWrapper display={checkedRows.length}>
              <ControlBarButton
                small
                primary
                basic
                onClick={() => this.campaignsDeactivation()}
              >
                {__('Deaktivovať vybrané')}
              </ControlBarButton>
            </BulkWrapper>
          }
        />
      </ControllBar>
    );
  }

  render() {
    return (
      <div>
        {super.render()}
        {/* <PopUpWrapper
          display={this.state.addCampaignShowPopup}
          message={__('Pridať Kampaň')}
          onClose={() => this.toggleAddCampaign()}
          noMinHeight
        >
          <AddCampaigns
            history={this.props.history}
            closePopup={this.toggleAddCampaign}
          />
        </PopUpWrapper> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Campaing);
