import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'react-image';
import Dropzone from 'react-dropzone';
import { rem } from 'polished';
import { Message, Loader, Input, Button, Label } from 'oxyrion-ui/lib';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import InfoTable from '../../Components/CustomerInfoTable/InfoTable';
import { connect } from '../../Store';
import API2 from '../../API2';
import { getIdFromProps, __, roleDecoder, formatDate } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  FlexInfoTableWrapper,
  FlexInfotableSubWrapper,
  ErrorWrapper,
  // WidgetFlexBox,
  LoaderWrapper,
  AnimatedFormMessageWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import Icon from 'oxyrion-ui/lib/Icon';
import GreyBox from '../../Components/GreyBox';
import DatePicker from '../../Components/DatePicker';
import moment from 'moment';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import CustomersOwnerShipMove from '../../Components/OwnerShip/CustomersOwnerShipMove';

const photoBasePath = `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/500x500`;

const CustomSubWrapper = styled(FlexInfotableSubWrapper)`
  margin: ${rem(10)} 0;
`;

const StyledInput = styled(Input)`
  max-width: ${rem(200)};
  margin-left: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const StyledLabel = styled(Label)`
  margin-left: ${rem(20)};
`;
const InputWrapper = styled.div`
  display: flex;
`;

const AddNew = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${rem(20)};
  margin-bottom: ${rem(24)};
  font-size: ${rem(14)};
  &:hover {
    text-decoration: underline;
  }
`;

const Row = styled.div`
  display: flex;
`;

const AppRow = styled(Row)`
  margin-bottom: ${rem(12)};
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const Col = styled.div`
  margin-right: ${rem(24)};
`;

const PhotoWrapper = styled.div`
  display: flex;
  // justify-content: center;
  // align-items: center;
`;

const Photo = styled(Img)`
  width: ${rem(300)};
  // border-radius: 100%;
`;

const ChangePhotoWrapper = styled.div``;

const ChangePhotoButton = styled(Dropzone)`
  display: flex;
  justify-content: flex-start;
  font-size: ${rem(14)};
  margin-top: ${rem(20)};
  margin-right: ${rem(12)};
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary};
  :hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  margin: ${rem(12)};
  max-width: ${rem(800)};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(8)};
`;

const defaultRoles = [
  {
    companyId: 'SK',
    name: '',
  },
  {
    companyId: 'CZ',
    name: '',
  },
];

const applications = ['CRM', 'BI', 'ADMIN', 'EOM', 'CRM2'];

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      loading: true,
      saveLoading: false,
      userPlanExportDate: {
        fromDate: moment().startOf('year'),
        toDate: moment().endOf('year'),
      },
      exportLoading: false,
      showDownloadDialog: false,
      downloadUrl: undefined,
      showOwnershipPopup: false,
      ownershipImpactCustomers: {
        total: 0,
        customers: [],
      },
      deactivateLoading: false,
      showDeactivationDialog: false,
    };
  }

  async componentWillMount() {
    await this.fetchData();
  }

  removeCode(index) {
    const { user } = this.state;

    user.seller_code.splice(index, 1);

    this.setState({ user, saveError: false, success: false });
  }

  removeCodePrevious(index) {
    const { user } = this.state;

    user.seller_code_previous.splice(index, 1);

    this.setState({ user, saveError: false, success: false });
  }

  addSellerCode() {
    const { user } = this.state;

    if (!user.seller_code) {
      user.seller_code = [];
    }

    user.seller_code.push('');

    this.setState({ user, saveError: false, success: false });
  }

  addSellerCodePrevious() {
    const { user } = this.state;

    if (!user.seller_code_previous) {
      user.seller_code_previous = [];
    }

    user.seller_code_previous.push('');

    this.setState({ user, saveError: false, success: false });
  }

  handleValuesChange(type, value, index) {
    const { user } = this.state;

    if (type === 'seller_code') {
      user.seller_code[index] = value;
    }

    if (type === 'seller_code_previous') {
      user.seller_code_previous[index] = value;
    }

    this.setState({ user, saveError: false, success: false });
  }

  async saveProfilePicture() {
    const { file, user } = this.state;

    try {
      this.setState({
        saveLoading: true,
      });

      await API2.putUserProfilePictureAction(user._id, {
        body: {
          image: file,
        },
      });

      this.setState({
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  async fetchData() {
    const id = getIdFromProps(this.props);
    try {
      const user = await API2.getUserAction(id);

      if (!user.crm_roles) {
        user.crm_roles = [];
      }

      defaultRoles.forEach((d) => {
        if (!user.crm_roles.find((r) => r.companyId === d.companyId)) {
          user.crm_roles.push(d);
        }
      });

      this.setState({
        user,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie používateľa nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Používatel sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleShowBuyPrice(value) {
    const { user } = this.state;
    user.show_purchase_prices = value;
    this.setState({
      user,
    });
  }

  handleShowBuyPriceCZ(value) {
    const { user } = this.state;
    user.show_purchase_prices_CZ = value;
    this.setState({
      user,
    });
  }

  handleAppPermisionChange(app) {
    const { user } = this.state;

    if (!user.apps_permisions) {
      user.apps_permisions = [];
    }

    if (user.apps_permisions.indexOf(app) === -1) {
      user.apps_permisions.push(app);
    } else {
      user.apps_permisions = user.apps_permisions.filter((a) => a !== app);
    }

    this.setState({
      user,
    });
  }

  async saveUser() {
    const { user } = this.state;
    this.setState({
      saveLoading: true,
    });
    try {
      const newUser = await API2.putUserAction(user._id, {
        body: {
          name: user.display_name,
          apps_permisions: user.apps_permisions,
          show_purchase_prices: user.show_purchase_prices,
          show_purchase_prices_CZ: user.show_purchase_prices_CZ,
          seller_code:
            user.seller_code && user.seller_code.filter((s) => s.length > 0),
          seller_code_previous:
            user.seller_code_previous &&
            user.seller_code_previous.filter((s) => s.length > 0),
          crm_roles: user.crm_roles || [],
        },
      });

      this.setState({
        user: newUser,
        saveLoading: false,
        success: __('Dáta úspešne uložené'),
      });
    } catch (e) {
      console.log(e);
      this.setState({
        saveError: __('Dáta sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  normalizeUserInfoData(user) {
    if (!user) {
      return [];
    }
    return [
      {
        name: __('Používateľské meno'),
        value: user.display_name,
      },
      {
        name: __('Meno'),
        value: user.forename,
      },
      {
        name: __('Priezvisko'),
        value: user.surname,
      },
      {
        name: __('Email'),
        value: user.email,
      },
      {
        name: __('Telefón'),
        value: user.mobile,
      },
      {
        name: __('Aktívne push notifikácie'),
        value: user.isNotificationEnabled ? __('Áno') : __('Nie'),
      },
    ];
  }

  normalizeCompanyData(user) {
    if (!user.companies) {
      return [];
    }

    const companies = user.companies.map((company) => {
      return {
        name: company.companyId,
        value: roleDecoder(company.role),
      };
    });
    return companies;
  }

  handleCRMRoleChange(firm, value) {
    const { user } = this.state;

    if (!user.crm_roles) {
      user.crm_roles = [];
    }

    if (!user.crm_roles.find((r) => r.companyId === firm)) {
      user.crm_roles.push({
        companyId: firm,
        name: '',
      });
    }

    user.crm_roles.find((r) => r.companyId === firm).name = value;

    this.setState({
      user,
    });
  }

  async exportToCSV() {
    const { userPlanExportDate, user } = this.state;

    this.setState({
      exportLoading: true,
    });

    try {
      const result = await API2.getUserPlanCsvAction(this.props.userData.firm, {
        sellerCodes: user.seller_code,
        fromDate: userPlanExportDate.fromDate,
        toDate: userPlanExportDate.toDate,
      });

      this.setState({
        showDownloadDialog: true,
        downloadUrl: result.url,
        exportLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Produkty sa nepodarilo vyexportovať'),
      });
    }
  }

  downloadFileWithId() {
    window.open(this.state.downloadUrl, '_blank');
    this.setState({
      downloadUrl: undefined,
      showDownloadDialog: false,
    });
  }

  handleExportDatePicker(value, e) {
    const { userPlanExportDate } = this.state;

    if (value === 'fromDate' || value === 'toDate') {
      userPlanExportDate[value] = e;
    } else {
      userPlanExportDate[value] = e.target.value;
    }

    this.setState({
      userPlanExportDate,
    });
  }

  crmRolesData(user) {
    const companies = user.crm_roles.map((role) => {
      return {
        name: role.companyId,
        value: (
          <StyledInput
            value={role.name}
            onChange={(e) =>
              this.handleCRMRoleChange(role.companyId, e.target.value)
            }
          />
        ),
      };
    });
    return companies;
  }

  onPhotochange(accepted) {
    const file = accepted[0];
    this.setState({ file });
  }

  async handleOwnershipCustomerChange() {
    const { ownershipLoading } = this.state;

    if (ownershipLoading) {
      return;
    }

    this.setState({ ownershipLoading: true });
    try {
      await API2.putCustomersOwnershipsAction(
        this.props.userData.firm,
        getIdFromProps(this.props),
        {
          force: false,
          body: {
            toUserId: '',
          },
        },
      );
      // this.setState({ ownershipLoading: false, showOwnershipPopup: true });
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({
          ownershipLoading: false,
          showOwnershipPopup: true,
          ownershipImpactCustomers: e.details.payload || {
            total: 0,
            customers: [],
          },
        });
      } else {
        this.setState({ ownershipLoading: false, showOwnershipPopup: false });
      }
    }
  }

  handleOnCloseOwnershipPopUp(state) {
    if (state) {
      this.setState({
        showOwnershipPopup: false,
        success: __('Zákazníci úspešne prenesení na nového použivateľa'),
      });
    } else {
      this.setState({ showOwnershipPopup: false });
    }
  }

  async handleUserDeactivate() {
    const { user } = this.state;
    try {
      this.setState({
        deactivateLoading: true,
      });

      await API2.putUserDeactivateAction(user._id, {
        body: {
          deactivated: true,
        },
      });

      this.setState({
        success: __('Používateľ bol deaktivovaný'),
        deactivateLoading: false,
        showDeactivationDialog: false,
      });
    } catch (e) {
      this.setState({
        error: __('Nepodarilo sa deaktivovať používateľa'),
        deactivateLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { ownershipLoading, deactivateLoading, user } = this.state;

    return (
      <React.Fragment>
        <ControllBar history={history} name={this.state.product.Description}>
          <ControlBarButton
            small
            primary
            basic
            icon="customer-qualify"
            loading={ownershipLoading}
            onClick={() => this.handleOwnershipCustomerChange()}
          >
            {__('Preniesť zákazníkov')}
          </ControlBarButton>
          <ControlBarButton
            small
            danger
            icon="close"
            disabled={user.deactivated}
            loading={deactivateLoading}
            onClick={() => this.setState({ showDeactivationDialog: true })}
          >
            {user.deactivated
              ? __('Používateľ je deaktivovaný')
              : __('Deaktivovať používateľa')}
          </ControlBarButton>
        </ControllBar>
      </React.Fragment>
    );
  }

  render() {
    const {
      error,
      loading,
      user,
      saveLoading,
      saveError,
      success,
      file,
      userPlanExportDate,
      exportLoading,
      showDownloadDialog,
      showOwnershipPopup,
      ownershipImpactCustomers,
      showDeactivationDialog,
      deactivateLoading,
    } = this.state;

    if (error) {
      return (
        <ErrorWrapper>
          <Message error message={error} />
        </ErrorWrapper>
      );
    }
    if (loading) {
      return (
        <React.Fragment>
          <LoaderWrapper>
            <Loader size="xl" />
          </LoaderWrapper>
        </React.Fragment>
      );
    }

    const url = file && URL.createObjectURL(file);

    const actualPhotoUrl =
      user && user.profile_picture && user.profile_picture.fullPath;

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <PopUpWrapper
          display={showDeactivationDialog}
          small
          onClose={() =>
            this.setState({
              showDeactivationDialog: false,
            })
          }
        >
          <ConfirmDialog
            message={__('Naozaj si želáte deaktivovať tohto používateľa?')}
            onDismiss={() =>
              this.setState({
                showDeactivationDialog: false,
              })
            }
            onConfirm={() => this.handleUserDeactivate()}
            dismissMessage={error}
            loading={deactivateLoading}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={saveError}>
          {saveError && <Message error message={saveError} />}
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          {success && <Message success message={success} />}
        </AnimatedFormMessageWrapper>

        <PopUpWrapper
          display={showDownloadDialog}
          small
          message={__('Sťiahnúť súbor')}
          onClose={() => this.setState({ showDownloadDialog: false })}
        >
          <ConfirmDialog
            message={__('Želáte si stiahnúť zvolený súbor ?')}
            onDismiss={() => this.setState({ showDownloadDialog: false })}
            onConfirm={() => this.downloadFileWithId()}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showOwnershipPopup}
          small
          message={__(`Presun vlastníctva všetkych zákaznikov`)}
          onClose={() => this.setState({ showOwnershipPopup: false })}
        >
          <CustomSubWrapper>
            <CustomersOwnerShipMove
              firm={this.props.userData.firm}
              data={ownershipImpactCustomers}
              fromUser={{
                value: getIdFromProps(this.props),
                label: `${user.surname} ${user.forename}`,
              }}
              onClose={(state) => this.handleOnCloseOwnershipPopUp(state)}
            />
          </CustomSubWrapper>
        </PopUpWrapper>

        <FlexInfoTableWrapper>
          <CustomSubWrapper>
            <InfoTable
              header={`${__('Používateľ - Základné info')} ${user.deactivated ? __('(Deaktivovaný používateľ)') : ''}`}
              data={this.normalizeUserInfoData(user)}
            />
          </CustomSubWrapper>
          <Space />
          <CustomSubWrapper>
            <InfoTable
              header={__('Používateľ - Role')}
              data={this.normalizeCompanyData(user)}
            />
          </CustomSubWrapper>
        </FlexInfoTableWrapper>
        <Wrapper>
          <GreyBox sidePadding={8} title={__('Profilová fotka')}>
            <PhotoWrapper>
              <Photo
                src={[
                  url,
                  `${photoBasePath}/${actualPhotoUrl}`,
                  '/images/icons/avatar.svg',
                ]}
              />
            </PhotoWrapper>
            <ChangePhotoWrapper>
              {!url ? (
                <ChangePhotoButton
                  onDrop={(accepted, rejected) =>
                    this.onPhotochange(accepted, rejected)
                  }
                >
                  {__('Nahrať novú profilovú fotku')}
                </ChangePhotoButton>
              ) : (
                <ButtonsWrapper>
                  <Button
                    danger
                    small
                    onClick={() =>
                      this.setState({
                        file: null,
                      })
                    }
                  >
                    {__('Zrušiť')}
                  </Button>
                  <Button
                    primary
                    small
                    loading={saveLoading}
                    onClick={() => this.saveProfilePicture()}
                  >
                    {__('Uložiť')}
                  </Button>
                </ButtonsWrapper>
              )}
            </ChangePhotoWrapper>
          </GreyBox>
          <Space />
          <Row>
            <GreyBox sidePadding={8} title={__('Kód(y) predajcu')}>
              <Row>
                <Col style={{ marginRight: rem(120) }}>
                  <Col>
                    {user.seller_code &&
                      user.seller_code.map((code, index) => (
                        <InputWrapper>
                          <StyledInput
                            value={code}
                            placeholder={__('Kód predajcu')}
                            onChange={(e) =>
                              this.handleValuesChange(
                                'seller_code',
                                e.target.value,
                                index,
                              )
                            }
                          />
                          <AddNew
                            style={{ marginLeft: rem(34) }}
                            onClick={() => this.removeCode(index)}
                          >
                            <Icon name="trash" color="red" />
                          </AddNew>
                        </InputWrapper>
                      ))}
                  </Col>

                  <AddNew onClick={() => this.addSellerCode()}>
                    {__('Pridať kód')}
                  </AddNew>
                </Col>
              </Row>
            </GreyBox>
            <Space />
            <GreyBox sidePadding={8} title={__('Kód(y) predajcov predchodcu')}>
              <Row>
                <Col style={{ marginRight: rem(120) }}>
                  <Col>
                    {user.seller_code_previous &&
                      user.seller_code_previous.map((code, index) => (
                        <InputWrapper>
                          <StyledInput
                            value={code}
                            placeholder={__('Kód predajcu')}
                            onChange={(e) =>
                              this.handleValuesChange(
                                'seller_code_previous',
                                e.target.value,
                                index,
                              )
                            }
                          />
                          <AddNew
                            style={{ marginLeft: rem(34) }}
                            onClick={() => this.removeCodePrevious(index)}
                          >
                            <Icon name="trash" color="red" />
                          </AddNew>
                        </InputWrapper>
                      ))}
                  </Col>

                  <AddNew onClick={() => this.addSellerCodePrevious()}>
                    {__('Pridať kód')}
                  </AddNew>
                </Col>
              </Row>
            </GreyBox>
          </Row>

          <Space />
          <Row>
            <GreyBox sidePadding={8} title={__('Prístupy do aplikácií')}>
              {/* <Row> */}
              {applications.map((app) => (
                <AppRow>
                  <LabeledCheckbox
                    checked={user.apps_permisions.indexOf(app) > -1}
                    labelText={app}
                    onChange={() => this.handleAppPermisionChange(app)}
                  />
                </AppRow>
              ))}
              {/* </Row> */}
            </GreyBox>
            <Space />
            <GreyBox sidePadding={8} title={__('Názvy roly')}>
              {user.crm_roles.map((role) => {
                return (
                  <Col>
                    <StyledLabel>{`Rola pre ${role.companyId}`}</StyledLabel>
                    <StyledInput
                      value={role.name}
                      onChange={(e) =>
                        this.handleCRMRoleChange(role.companyId, e.target.value)
                      }
                    />
                  </Col>
                );
              })}
            </GreyBox>
          </Row>
          <Space />
          <Row>
            <GreyBox sidePadding={8} title={__('Oprávnenia')}>
              <AppRow>
                <LabeledCheckbox
                  checked={user.show_purchase_prices}
                  labelText={__('Zobraziť nákupné ceny')}
                  onChange={(e) => this.handleShowBuyPrice(e.target.checked)}
                />
              </AppRow>

              <AppRow>
                <LabeledCheckbox
                  checked={user.show_purchase_prices_CZ}
                  labelText={__('Zobraziť nákupné ceny do ČR')}
                  onChange={(e) => this.handleShowBuyPriceCZ(e.target.checked)}
                />
              </AppRow>
            </GreyBox>

            <Space />
            <GreyBox sidePadding={8} title={__('Export plánov')}>
              <AppRow>
                <InputWrapper>
                  <StyledLabel>{__('od')}</StyledLabel>
                  <DatePicker
                    placeholder={__('Platnosť od')}
                    selected={moment(userPlanExportDate.fromDate)}
                    value={formatDate(userPlanExportDate.fromDate)}
                    onChange={(e) => {
                      this.handleExportDatePicker('fromDate', e);
                    }}
                    showMonthYearPicker
                  />
                </InputWrapper>
              </AppRow>
              <AppRow>
                <InputWrapper>
                  <StyledLabel>{__('do')}</StyledLabel>
                  <DatePicker
                    placeholder={__('Platnosť do')}
                    selected={moment(userPlanExportDate.toDate)}
                    value={formatDate(userPlanExportDate.toDate)}
                    onChange={(e) => {
                      this.handleExportDatePicker('toDate', e);
                    }}
                    showMonthYearPicker
                  />
                </InputWrapper>
              </AppRow>
              <Button
                style={{ marginTop: rem(16) }}
                primary
                loading={exportLoading}
                onClick={() => this.exportToCSV()}
                disabled={!(user.seller_code && user.seller_code.length)}
              >
                {__('Exportovať')}
              </Button>
            </GreyBox>
          </Row>
        </Wrapper>
        <ButtonRelativeWrapper>
          <Button
            style={{ marginRight: rem(16) }}
            primary
            loading={saveLoading}
            onClick={() => this.saveUser()}
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}
UserDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  userData: PropTypes.shape({}),
};

UserDetail.defaultProps = {
  userData: { firm: 'SK' },
};

const mapStateToProps = (state) => {
  return { userData: state };
};

export default connect((state) => mapStateToProps(state))(UserDetail);
