import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Button } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import AdminAPI from '../../AdminAPI';
import FeesSettings from '../../Components/FeesSettings';
import LanguageSwitch from '../../Components/LanguageSwitch';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';

const Wrapper = styled.div`
  padding: ${rem(15)};
  max-width: ${rem(1400)};

  .buttons-1 {
    margin-top: ${rem(16)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: ${rem(24)};
    bottom: ${rem(24)};
  }
`;

class EshopPaymentsMethodsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lang: 'SK',
      type: 'payment',
      fees: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const { loading, lang, type } = this.state;

    if (!loading) {
      this.setState({
        loading: true,
      });
    }

    try {
      const [{ fees: paymentFees }, { items: customerTypes }] =
        await Promise.all([
          AdminAPI.getServiceFeesAction({
            type,
            lang,
          }),
          AdminAPI.getCustomerTypesAction({}),
        ]);

      const normalizedFees = paymentFees.map((f) =>
        Object.assign(f, {
          customer_types:
            f.customer_types && f.customer_types.length
              ? f.customer_types.map((p) => {
                  const ct = customerTypes.find((f1) => f1._id === p);
                  return {
                    value: ct._id,
                    label: `${ct.name} (${ct.type})`,
                  };
                })
              : [],
        }),
      );

      this.setState({
        fees: normalizedFees || [],
        loading: false,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        error: __('Spôsob platby sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  addNewFee() {
    const { fees, lang } = this.state;

    fees.push({
      label: 'Nový spôsob platby',
      unit_price: {
        amount: 0,
        amount_vat_excl: 0,
        amount_wd: 0,
        amount_vat_excl_wd: 0,
        currency: lang === 'SK' ? 'EUR' : 'CZK',
        vat_rate: lang === 'SK' ? 20 : 21,
      },
    });

    this.setState({
      fees,
    });
  }

  deleteFee(id) {
    this.setState({
      feeToDelete: id || 'new',
    });
  }

  async deleteFeeAction(id) {
    if (id === 'new') {
      this.setState({
        fees: this.state.fees.filter((fee) => fee._id),
        feeToDelete: undefined,
      });
    }

    try {
      await AdminAPI.deleteFeeAction(id);

      this.setState({
        feeToDelete: undefined,
      });
    } catch (e) {
      this.setState({
        error: __('Spôsob platby metódu sa nepodarilo odstrániť'),
      });
    }
  }

  async saveFee(data) {
    try {
      this.setState({
        saveLoading: true,
      });

      const params = {};
      params.body = {
        name: data.name,
        label: data.label,
        unit_price: data.unit_price,
        item_no: data.item_no,
        under_info: data.under_info,
        maxAmount: Number(data.maxAmount) || null,
        maxAmountMessage: data.maxAmountMessage || null,
        payments: data.payments && data.payments.map((p) => p.value),
        subtypes: data.subtypes,
        send_invoice_automatically: data.send_invoice_automatically,
        customer_types:
          data.customer_types && data.customer_types.map((p) => p.value),
        activated: data.activated || false,
      };

      if (data._id) {
        await AdminAPI.putServiceFeeAction(data._id, params);
      } else {
        await AdminAPI.postServiceFeeAction(data._id, params);
      }

      this.setState({
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Spôsob platby metódu sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar history={history} name={__('Eshop - spôsoby platby')} />
    );
  }

  render() {
    const { loading, fees, lang, saveLoading, feeToDelete, type } = this.state;

    return (
      <React.Fragment>
        <PopUpWrapper
          display={feeToDelete}
          small
          onClose={() =>
            this.setState({
              feeToDelete: undefined,
            })
          }
        >
          <ConfirmDialog
            message={__('Naozaj si želáte odstrániť tento spôsob platby?')}
            onDismiss={() =>
              this.setState({
                feeToDelete: undefined,
              })
            }
            onConfirm={() => this.deleteFeeAction(feeToDelete)}
          />
        </PopUpWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <LanguageSwitch
            activeId={lang}
            onChange={(l) => {
              this.setState(
                {
                  lang: l,
                },
                () => this.fetchData(),
              );
              return true;
            }}
          />
          <FeesSettings
            onFeeDelete={(id) => this.deleteFee(id)}
            onFeeSave={(data) => this.saveFee(data)}
            fees={fees}
            loading={loading}
            lang={lang}
            saveLoading={saveLoading}
            type={type}
          />
          {fees.filter((f) => !f._id).length === 0 && (
            <div className="buttons-1">
              <Button disabled primary onClick={() => this.addNewFee()}>
                {__('Pridať spôsob platby')}
              </Button>
            </div>
          )}
        </Wrapper>
      </React.Fragment>
    );
  }
}

EshopPaymentsMethodsSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopPaymentsMethodsSettings);
