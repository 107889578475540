import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Loader, Button } from 'oxyrion-ui/lib';
import { connect } from '../../../Store';
import { formatColorToRGB, __ } from '../../../Utils';
import { LoaderWrapper } from '../../../Components/ReusableComponents';
import SearchableSelect from '../../../Components/SearchableSelect';
import PageNavigator from 'oxyrion-ui/lib/PageNavigator';
import Icon from 'oxyrion-ui/lib/Icon';

const MainWrapper = styled.div`
  min-width: ${rem(1300)};
  padding: ${rem(24)};
  padding-bottom: ${rem(500)};
`;

const ShadeRow = styled.div`
  margin-bottom: ${rem(12)};
  padding-bottom: ${rem(12)};
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;

  .selected-categories-wrapper {
    display: flex;
    margin-left: ${rem(24)};

    .category-wrapper {
      position: relative;
    }

    .close-wrapper {
      width: ${rem(20)};
      height: ${rem(20)};
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      left: -10px;
      border-radius: ${rem(20)};
      top: -10px;
      border: 1px solid white;
      cursor: pointer;
      background: red;
    }
  }

  .select-wrapper {
    width: ${rem(300)};
    margin-left: ${rem(24)};
    margin-bottom: ${rem(12)};
  }

  .color-holder {
    background: ${props => props.color};
    width: ${rem(200)};
    height: ${rem(100)};
    background-image: ${props => `url(${props.imageFullPath})`};
    border: 1px solid #eee;
  }
`;

const ColorCategoryLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${rem(12)};

  .color {
    background: ${props => props.color};
    width: ${rem(80)};
    height: ${rem(40)};
    margin-right: ${rem(8)};
    border: 1px solid #eee;
  }
`;

const ButtonsWrapper = styled.div`
  /* position: absolute; */
  right: ${rem(24)};
  bottom: ${rem(24)};
  display: flex;
  justify-content: flex-end;
`;

const StyledPageNavigator = styled.div`
  border-top-left-radius: ${rem(10)};
  border-top-right-radius: ${rem(10)};
  position: fixed;
  bottom: 0;
  right: 0;
  background: white;
  padding: ${rem(10)};
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
`;

class Categorization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shades: this.props.shades,
      changedColorCodes: [],
      currentPage: 1,
    };
  }

  componentDidMount() {
    this.setState({
      shades: this.props.shades,
      changedColorCodes: [],
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      shades: nextProps.shades,
      changedColorCodes: [],
    });
  }

  searchColorCategory(q, usedIds = []) {
    const { colorCategories } = this.props;

    return colorCategories
      .filter(
        c =>
          !usedIds.includes(c._id) &&
          c.name.toUpperCase().includes(q.toUpperCase()),
      )
      .map(c => ({
        value: c._id,
        label: (
          <ColorCategoryLabel color={formatColorToRGB(c.rgb)}>
            <div className="color" />
            <div className="name">{c.name}</div>
          </ColorCategoryLabel>
        ),
        color: c.rgb,
      }));
  }

  addShadeCategory(colorCode, category) {
    const { shades, changedColorCodes } = this.state;

    if (!shades.find(s => s.color_code === colorCode).categories) {
      shades.find(s => s.color_code === colorCode).categories = [];
    }

    shades.find(s => s.color_code === colorCode).categories.push(category);

    changedColorCodes.push(colorCode);

    this.setState({
      shades,
      changedColorCodes,
    });
  }

  removeShadeCategory(colorCode, categoryId) {
    const { changedColorCodes, shades } = this.state;

    changedColorCodes.push(colorCode);

    shades.find(s => s.color_code === colorCode).categories = shades
      .find(s => s.color_code === colorCode)
      .categories.filter(c => c.value !== categoryId);

    this.setState({
      shades,
      changedColorCodes,
    });
  }

  saveData(shades) {
    const { changedColorCodes } = this.state;

    const dataToSave = shades
      .filter(s => changedColorCodes.includes(s.color_code))
      .map(s => ({
        category_ids: s.categories ? s.categories.map(c => c.value) : [],
        color_code: s.color_code,
      }));

    this.props.saveData(dataToSave);

    this.setState({
      changedColorCodes: [],
    });
  }

  onPageClick(page) {
    this.setState({
      currentPage: page,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  onDecrease() {
    this.setState({
      currentPage: this.state.currentPage - 1,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  onIncrease() {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const { loading, shades, changedColorCodes, currentPage } = this.state;
    const { saveLoading } = this.props;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <MainWrapper>
        <ButtonsWrapper>
          <Button
            loading={saveLoading}
            disabled={changedColorCodes.length === 0}
            primary
            onClick={() => this.saveData(shades)}
          >
            {__('Uložiť')}
          </Button>
        </ButtonsWrapper>
        {shades.slice((currentPage - 1) * 50, currentPage * 50).map(s => (
          <ShadeRow
            color={formatColorToRGB(s.color)}
            imageFullPath={`${process.env.REACT_APP_IMAGES_BASE_PATH}/images/200x100/${s.image_path}`}
          >
            <div className="color-name-wrapper">
              <div className="name">{s.color_code}</div>
              <div className="color-holder" />
            </div>
            <div className="categories-wrapper">
              <div className="select-wrapper">
                <SearchableSelect
                  value={null}
                  loadOptions={query => {
                    return this.searchColorCategory(
                      query,
                      s.categories && s.categories.map(c => c.value),
                    );
                  }}
                  keyProps={`main_${JSON.stringify(s.categories)}`}
                  placeholder={__('Pridať farebnú skupinu')}
                  handleOnChange={e => this.addShadeCategory(s.color_code, e)}
                />
              </div>
              {s.categories && (
                <div className="selected-categories-wrapper">
                  {s.categories.map(c => (
                    <div className="category-wrapper">
                      <div
                        className="close-wrapper"
                        onClick={() =>
                          this.removeShadeCategory(s.color_code, c.value)
                        }
                      >
                        <Icon name="close" color="white" />
                      </div>
                      {c.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <StyledPageNavigator>
              <PageNavigator
                activePage={currentPage}
                end={shades.length / 50}
                onPageClick={page => this.onPageClick(page)}
                onDecrease={() => this.onDecrease()}
                onIncrease={() => this.onIncrease()}
              />
            </StyledPageNavigator>
          </ShadeRow>
        ))}
        <ButtonsWrapper>
          <Button
            loading={saveLoading}
            primary
            disabled={changedColorCodes.length === 0}
            onClick={() => this.saveData(shades)}
          >
            {__('Uložiť')}
          </Button>
        </ButtonsWrapper>
      </MainWrapper>
    );
  }
}

Categorization.propTypes = {
  shades: PropTypes.arrayOf({}),
  colorCategories: PropTypes.arrayOf({}),
  saveData: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  saveLoading: PropTypes.bool,
};

Categorization.defaultProps = {
  shades: [],
  colorCategories: [],
  saveLoading: false,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Categorization);
