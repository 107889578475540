import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import BrandsList from '../../../Containers/BrandsList';
import BrandDetail from '../../../Containers/BrandDetail';

function BrandsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={BrandDetail} />
      <Route path={`${match.url}`} component={BrandsList} />
    </Switch>
  );
}

BrandsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default BrandsCard;
