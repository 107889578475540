import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import Button from 'oxyrion-ui/lib/Button';
import { rem } from 'polished';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import React from 'react';
import styled from 'styled-components';
import ReactTable from 'react-table';
import AdminAPI from '../../../AdminAPI';
import { LoaderWrapper } from '../../../Components/ReusableComponents';
import { connect } from '../../../Store';
import { __ } from '../../../Utils';
import PopUpWrapper from '../../../Components/PopUpWrapper';
import ConfirmDialog from '../../../Components/ConfirmDialog';
import ExportProductsFilters from '../../../Components/ExportProductsFilters';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Wrapper = styled.div`
  overflow: auto;
  margin-top: ${rem(12)};
  margin-bottom: ${rem(12)};
`;

const StyledHeader = styled.div`
  display: flex;
  margin-left: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #434245;
  margin-top: 15px;
`;

const StyledTd = styled.div`
  display: flex;
  margin: 8px;
  margin: 8px;
  font-size: 11px;
`;

const CenteredStyledTd = styled(StyledTd)`
  justify-content: center;
  align-items: center;
`;

const TableWrapper = styled.div`
  min-width: ${rem(1600)};
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${rem(20)};
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

class MetaPixelProductsExport extends React.Component {
  state = {
    products: [],
    changedProducts: [],
    error: null,
    filters: {},
  };

  componentDidMount() {
    this.fetchProducts();
  }

  async fetchProducts(filters = {}) {
    this.setState({
      loading: true,
    });

    try {
      const { items: products } = await AdminAPI.getAllCatalogProductsAction({
        sorter: 'name',
        brand: filters.brand && filters.brand.label,
        catalogProductId:
          filters.catalogProductId && filters.catalogProductId.value,
        type: filters.type && filters.type.value,
      });
      this.setState({
        loading: false,
        products,
        filters,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: __('Produkty sa nepodarilo načítať'),
      });
    }
  }

  handleCheckboxClick(id, value) {
    const { products, changedProducts } = this.state;

    changedProducts.push(id);

    products.find((p) => p._id === id).exportable_meta_pixel = value;

    this.setState({
      products,
      changedProducts,
    });
  }

  async saveExport() {
    this.setState({
      saveLoading: true,
    });

    try {
      const { products, changedProducts } = this.state;

      const _products = products
        .filter((p) => changedProducts.includes(p._id))
        .map((p) => {
          return {
            product_id: p._id,
            value: p.exportable_meta_pixel || false,
          };
        });

      const params = {};

      params.body = {
        products: _products,
      };

      await AdminAPI.putProductsMetaPixelExportAction(params);

      this.setState({
        saveLoading: false,
        error: false,
        success: __('Dáta úspešne uložené'),
      });
    } catch (e) {
      this.setState({
        saveLoading: false,
        success: false,
        error: __('Dáta sa nepodarilo uložiť'),
      });
    }
  }

  async export(lang) {
    // const { exportLoadingSK, exportLoadingCZ } = this.state;
    // try {
    //   this.setState({
    //     exportLoadingSK: lang === 'SK' ? true : exportLoadingSK,
    //     exportLoadingCZ: lang === 'CZ' ? true : exportLoadingCZ,
    //   });
    //   await this.saveExport();
    //   const result = await API2.getMetaPixelFeedAction(lang);
    //   // akcia nie je prenesena do noveho API a je otazne ci to bude este niekedy treba kedze feedy sa exportuju automaticky
    //   this.setState({
    //     showDownloadDialog: true,
    //     downloadId: result.url,
    //     exportLoadingSK: lang === 'SK' ? false : exportLoadingSK,
    //     exportLoadingCZ: lang === 'CZ' ? false : exportLoadingCZ,
    //   });
    // } catch (e) {
    //   this.setState({
    //     error: __('Produkty sa nepodarilo vyexportovať'),
    //   });
    // }
  }

  downloadFileWithId() {
    window.location.href = this.state.downloadId;
    this.setState({
      downloadId: undefined,
      showDownloadDialog: false,
    });
  }

  createColumns() {
    const result = [
      {
        accessor: 'exportable_meta_pixel',
        Header: () => <StyledHeader>{__('Exportovať')}</StyledHeader>,
        maxWidth: 100,
        Cell: (props) => {
          return (
            <CenteredStyledTd>
              <Checkbox
                checked={props.value}
                onChange={(e) =>
                  this.handleCheckboxClick(props.original._id, e.target.checked)
                }
              />
            </CenteredStyledTd>
          );
        },
      },
      {
        accessor: 'name',
        Header: () => <StyledHeader>{__('Názov produktu')}</StyledHeader>,
        // maxWidth: 1600,
        Cell: (props) => {
          return <StyledTd>{props.value}</StyledTd>;
        },
      },
    ];

    return result;
  }

  useCustomheader(columns) {
    return columns.map((i) => ({
      Header: () => <div>{i.name}</div>,
      ...i,
    }));
  }

  render() {
    const {
      products,
      loading,
      error,
      success,
      saveLoading,
      showDownloadDialog,
      exportLoadingSK,
      exportLoadingCZ,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Wrapper>
        {error && <Message error message={error} />}
        {success && <Message message={success} />}

        <PopUpWrapper
          display={showDownloadDialog}
          small
          message={__('Sťiahnúť súbor(y)')}
          onClose={() => this.setState({ showDownloadDialog: false })}
        >
          <ConfirmDialog
            message={__('Želáte si stiahnúť zvolený súbor ?')}
            onDismiss={() => this.setState({ showDownloadDialog: false })}
            onConfirm={() => this.downloadFileWithId()}
          />
        </PopUpWrapper>

        <ExportProductsFilters
          firm={this.props.firm}
          filters={this.state.filters}
          onChange={(filter) => {
            this.fetchProducts(filter);
          }}
        />

        <TableWrapper>
          <ReactTableFixedColumns
            columns={this.useCustomheader(this.createColumns())}
            resizable={false}
            data={products || []}
            pageSize={50}
            showPageSizeOptions={false}
            pageText={__('Strana')}
            ofText={__('z')}
            previousText={__('Späť')}
            nextText={__('Ďalej')}
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
          />
        </TableWrapper>
        <PopupButtonWrapper>
          <Button
            loading={saveLoading}
            onClick={() => this.saveExport()}
            primary
          >
            {__('Uložiť')}
          </Button>
          <Space />
          <Button
            loading={exportLoadingSK}
            disabled
            onClick={() => this.export('SK')}
            primary
          >
            {__('Exportovať SK (nedostupné)')}
          </Button>
          <Space />
          <Button
            loading={exportLoadingCZ}
            disabled
            onClick={() => this.export('CZ')}
            primary
          >
            {__('Exportovať CZ (nedostupné)')}
          </Button>
        </PopupButtonWrapper>
      </Wrapper>
    );
  }
}

MetaPixelProductsExport.propTypes = {};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(MetaPixelProductsExport);
