import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from '../../Utils';
import {
  searchBrands,
  searchCatalogProducts,
  searchProductTypes,
} from '../../Utils/ApiSearchFunctions';
import SearchableSelect from '../SearchableSelect';
import { Button } from 'oxyrion-ui/lib';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${rem(0)};
  padding-bottom: ${rem(4)};
  align-self: flex-end;
`;

const FiltersRow = styled.div`
  padding: ${rem(24)};

  .label {
    color: ${(props) => props.theme.color.grey};
    font-weight: bold;
    font-size: ${rem(12)};
    margin-bottom: ${rem(12)};
  }

  .row {
    display: flex;
    align-items: center;
  }

  .space {
    width: ${rem(12)};
    height: ${rem(12)};
  }

  .search-select-wrapper {
    width: ${rem(300)};
  }
`;

class ExportProductsFilters extends Component {
  constructor(props) {
    super(props);
    this.state = { filters: this.props.filters || {} };
  }

  handleFilterOnChange(key, value) {
    const { filters } = this.state;

    filters[key] = value ? value : undefined;

    this.setState({ filters });
  }

  render() {
    const { filters } = this.state;

    return (
      <FiltersRow>
        <div className="row">
          <div className="search-select-wrapper">
            <div className="label">{__('Katalógový produkt')}</div>
            <SearchableSelect
              value={filters.catalogProductId}
              loadOptions={(query) => {
                return searchCatalogProducts(query);
              }}
              handleOnChange={(value) =>
                this.handleFilterOnChange('catalogProductId', value)
              }
            />
          </div>

          <div className="space" />

          <div className="search-select-wrapper">
            <div className="label">{__('Značka')}</div>
            <SearchableSelect
              value={filters.brand}
              loadOptions={(query) => {
                return searchBrands(query, this.props.firm);
              }}
              handleOnChange={(value) =>
                this.handleFilterOnChange('brand', value)
              }
            />
          </div>

          <div className="space" />

          <div className="search-select-wrapper">
            <div className="label">{__('Typ')}</div>
            <SearchableSelect
              value={filters.type}
              loadOptions={(query) => {
                return searchProductTypes(query);
              }}
              handleOnChange={(value) =>
                this.handleFilterOnChange('type', value)
              }
            />
          </div>

          <div className="space" />
          <ButtonWrapper>
            <Button
              primary
              small
              onClick={() => this.props.onChange(this.state.filters)}
            >
              {__('Aplikovať filter')}
            </Button>
          </ButtonWrapper>
        </div>
      </FiltersRow>
    );
  }
}

ExportProductsFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  firm: PropTypes.string.isRequired,
  filters: PropTypes.object,
};

export default ExportProductsFilters;
