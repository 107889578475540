import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { rem } from 'polished';
import Loader from 'oxyrion-ui/lib/Loader';
import Img from 'react-image';
import { LoaderWrapper } from '../ReusableComponents';
import pdf from './images/pdf.png';
import eps from './images/eps.png';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(12)};
  max-width: ${rem(450)};
`;

const StyledImage = styled(Img)`
  max-height: ${rem(400)};
  max-width: ${rem(450)};
`;

const StyledCustomImage = styled(Img)`
  height: ${rem(100)};
`;

const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;
const videoBasePath = `${process.env.REACT_APP_DOMAIN2}/video/stream`;

const images = [
  {
    type: 'application/pdf',
    path: pdf,
  },
  {
    type: 'application/postscript',
    path: eps,
  },
];

class FileViewer extends Component {
  getImageByFileType(type) {
    if (!type) {
      return null;
    }

    if (images.find(i => i.type === type)) {
      return images.find(i => i.type === type).path;
    }

    return null;
  }

  render() {
    const { loading, file } = this.props;

    if (loading) {
      return (
        <Wrapper>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </Wrapper>
      );
    }

    if (file && file.mimeType && file.mimeType.startsWith('image')) {
      return (
        <Wrapper>
          <StyledImage
            src={[`${baseURL}/images/400x0/${file.fullPath}`]}
            loader={
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            }
          />
        </Wrapper>
      );
    }

    if (file && file.mimeType && file.mimeType.startsWith('video')) {
      return (
        <Wrapper>
          <ReactPlayer
            url={`${videoBasePath}/${encodeURIComponent(file.fullPath)}`}
            playing={false}
            // forceVideo
            controls
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper {...this.props}>
        <a
          href={file && `${videoBasePath}/${file.fullPath}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledCustomImage
            src={this.getImageByFileType(file && file.mimeType)}
            loader={
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            }
          />
        </a>
      </Wrapper>
    );
  }
}

FileViewer.propTypes = {
  file: PropTypes.shape({
    fullPath: PropTypes.string,
    mimeType: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

FileViewer.defaultProps = {
  file: null,
  loading: false,
};

export default FileViewer;
