import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Loader, Input, Icon, Button } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import API2 from '../../API2';


const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  padding: ${rem(12)};
  margin-bottom: ${rem(100)};
`;

const Col = styled.div`
  margin-right: ${rem(24)};
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};
`;

const Space = styled.div`
  width: ${rem(32)};
  height: ${rem(16)};
`;

const DeleteWrapper = styled.div`
  width: ${rem(38)};
  height: ${rem(38)};
  margin-left: ${rem(25)};
  border-radius: ${rem(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #e84141;
`;

class StocksSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      softwares: [],
      newSoft: '',
      createLoading: false,
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const result = await API2.getWarehouseSoftwareAction(this.props.firm);
      this.setState({ loading: false, softwares: result.warehouseSoftware });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie značiek nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Dáta sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <ControllBar
        history={history}
        name={__('Skladový softvér - používaný zákaznikmi')}
      />
    );
  }

  async onDelete(id) {
    try {
      const result = await API2.deleteWarehouseSoftwareAction(
        this.props.firm,
        id,
      );

      this.setState({ softwares: result.warehouseSoftware });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie značiek nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Dáta sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    }
  }

  async addNew() {
    const { newSoft } = this.state;

    this.setState({
      createLoading: true,
    });

    try {
      const result = await API2.putWarehouseSoftwareAction(this.props.firm, {
        body: {
          _id: newSoft,
          name: newSoft,
        },
      });

      this.setState({
        softwares: result.warehouseSoftware,
        newSoft: '',
        createLoading: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie značiek nemáte potrebné práva.'),
            createLoading: false,
          });
          break;
        case 404:
          this.setState({
            error: __('Dáta sa nenašli'),
            createLoading: false,
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
            createLoading: false,
          });
          break;
      }
    }
  }

  render() {
    const {
      error,
      success,
      softwares,
      loading,
      newSoft,
      createLoading,
    } = this.state;

    console.log('softwares---', softwares);

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}
        <Wrapper>
          <Col>
            {softwares.map(s => (
              <Row>
                <Input disabled={true} value={s.name}></Input>
                <DeleteWrapper onClick={() => this.onDelete(s._id)}>
                  <Icon name="delete" color="white" />
                </DeleteWrapper>
              </Row>
            ))}
            <Row>
              <Input
                placeholder={__('Názov softvéru')}
                onChange={e =>
                  this.setState({
                    newSoft: e.target.value,
                  })
                }
                value={newSoft}
              />
              <Space />
              <Button
                loading={createLoading}
                small
                disabled={!newSoft.length > 0}
                primary
                onClick={() => this.addNew()}
              >
                {__('Vytvoriť')}
              </Button>
            </Row>
          </Col>
        </Wrapper>
      </React.Fragment>
    );
  }
}

StocksSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(StocksSettings);
