import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Loader, Message, Input, Label, Button } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __, getCurrencyByFirm } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  AnimatedFormMessageWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import LanguageSwitch from '../../Components/LanguageSwitch';
import API2 from '../../API2';

const InputWrapper = styled.div`
  width: ${rem(400)};
`;

const StyledInput = styled(Input)`
  width: ${rem(100)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const Wrapper = styled.div`
  padding: ${rem(20)};
`;

const Currency = styled.div`
  margin-left: 30px;
`;

const InputWithCurrency = styled.div`
  display: flex;
  align-items: center;
`;

class OrderSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      activeLanguage: 'SK',
      loading: true,
    };
  }

  async componentDidMount() {
    await this.fetchSettings();
  }

  async fetchSettings() {
    try {
      const settings = await API2.getOrderSettings();
      this.setState({
        settings,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Nastavenia sa nenašli'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  changeLanguage(lang) {
    this.setState({
      activeLanguage: lang,
    });

    return true;
  }

  handleSettingsChange(field, value) {
    const { settings, activeLanguage } = this.state;

    if (!settings[field]) {
      settings[field] = [
        {
          lang: 'SK',
          value: '',
        },
      ];
    }
    if (!settings[field].find((s) => s.lang === activeLanguage)) {
      settings[field].push({
        lang: activeLanguage,
        value: '',
      });
    }

    settings[field].find((s) => s.lang === activeLanguage).value =
      Number(value);
    this.setState({
      settings,
    });
  }

  async saveSettings() {
    try {
      this.setState({
        error: false,
        success: false,
        saveLoading: true,
      });

      const { settings } = this.state;
      const newSettings = await API2.putOrderSettings({ body: settings });

      this.setState({
        success: __('Nastavenia uložené'),
        settings: newSettings,
        saveLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Dáta sa nepodarilo uložiť'),
        saveLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Nastavenia objednávky')} />
      </React.Fragment>
    );
  }

  render() {
    const { loading, error, success, settings, activeLanguage, saveLoading } =
      this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <Wrapper>
          <InputWrapper>
            <LanguageSwitch
              onChange={(id) => {
                return this.changeLanguage(id);
              }}
              activeId={activeLanguage}
            />

            <StyledLabel>{__('Minimálna cena objednávky')}</StyledLabel>

            <InputWithCurrency>
              <StyledInput
                value={
                  settings.min_order &&
                  settings.min_order.find((s) => s.lang === activeLanguage)
                    ? settings.min_order.find((s) => s.lang === activeLanguage)
                        .value
                    : ''
                }
                type="number"
                placeholder={`${__('Cena pre')} ${activeLanguage}`}
                onChange={(e) =>
                  this.handleSettingsChange('min_order', e.target.value)
                }
              />

              <Currency>{getCurrencyByFirm(activeLanguage)}</Currency>
            </InputWithCurrency>
          </InputWrapper>
        </Wrapper>

        <ButtonRelativeWrapper>
          <Button
            loading={saveLoading}
            onClick={() => this.saveSettings()}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

OrderSettings.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(OrderSettings);
