import React from 'react';
import { __ } from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import TurnoverDiscount from '../TurnoverDiscount';

class TurnoverDiscounts extends React.Component {
  render() {
    const { history } = this.props;

    return (
      <div>
        <ControllBar history={history} name={__('Obratové zlavy')} />
        <TurnoverDiscount />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(TurnoverDiscounts);
