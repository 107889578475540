import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Icon } from 'oxyrion-ui/lib';
import BaseTable from '../../BaseTable';
// import AdminAPI from '../../AdminAPI';
import tableHeaders from '../../../ContentConfig/gpv';
import ControlBarButton from '../../../Components/ControllBar/ControlBarButton';
import ControlButton from '../../../Components/ReusableComponents/ControlButton';
import { RouteLinkWraper as LinkWrap } from '../../../Components/ReusableComponents';
import {
  __,
  renderLink,
  formatDate,
  FORMAT_HUMANE_DATETIME,
  formatBytes,
} from '../../../Utils';
import { connect } from '../../../Store';
import ControllBar from '../../../Components/ControllBar';
import AdminAPI from '../../../AdminAPI';
import PopUpWrapper from '../../../Components/PopUpWrapper';
import ConfirmDialog from '../../../Components/ConfirmDialog';
import API2 from '../../../API2';

const DownloadWrapper = styled.div`
  cursor: pointer;
`;

class Graphics extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Grafika');
    this.showBulkOperations = true;
    this.accessors = [
      'name',
      'category',
      'file_type',
      'file_size',
      'updated_date',
      'download',
    ];
    this.setSorter('name');
    this.state = {
      ...this.state,
      selectedCustomer: null,
      downloadSelectedLoading: false,
      commonMessage: { label: __('Všetky'), value: null },
      lastSearch: '',
      types: [],
    };
  }

  async downloadFile(id) {
    try {
      const result = await API2.getMultimediaDownloadAction(
        this.props.firm,
        id,
      );

      this.setState({
        showDownloadDialog: true,
        downloadId: result.url,
      });
    } catch (e) {
      this.setState({
        error: __('Súbor sa nepodarilo stiahnúť'),
      });
    }
  }

  async downloadSelected() {
    const { checkedRows } = this.state;

    this.setState({
      downloadSelectedLoading: true,
    });

    try {
      const result = await API2.getMultimediaDownloadZipAction(
        this.props.firm,
        {
          multimediaIds: checkedRows,
        },
      );

      this.setState({
        showDownloadDialog: true,
        downloadSelectedLoading: false,
        downloadId: result.url,
      });
    } catch (e) {
      this.setState({
        error: __('Súbor sa nepodarilo stiahnúť'),
        downloadSelectedLoading: false,
      });
    }
  }

  downloadFileWithId() {
    window.location.href = this.state.downloadId;
    this.setState({
      downloadId: undefined,
      showDownloadDialog: false,
    });
  }

  handleSystemOnClick(id) {
    const { history, firm } = this.props;
    history.push(`/${firm}/multimedia/${id}?type=graphic`);
  }

  async componentDidMount() {
    super.componentDidMount();

    const result = await AdminAPI.getCodelist('graphic_types');

    this.setState({
      types: result.codelist || [],
    });
  }

  handleCommonMessage(value, name) {
    this.setState({ commonMessage: { label: name, value } }, () => {
      this.fetchData({ category: value });
    });
  }

  async fetchData(parameters = {}) {
    try {
      const { sorter, active, lastSearch } = this.state;
      this.setState({ loading: true });
      let params;

      if (active === null) {
        params = Object.assign({}, parameters, { sorter });
      } else {
        params = Object.assign({}, parameters, { sorter, active });
      }
      if (!params.q) {
        params.q = lastSearch;
      }

      params.type = 'graphic';
      const newData = await AdminAPI.getGraphicsPrintsVideosAction(params);

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizeColumns(data) {
    const { types } = this.state;

    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: item._id,
        name: _shapeData(
          renderLink(`./multimedia/${item._id}`, `${item.name || '-'}`),
          () => this.handleSystemOnClick(item._id),
        ),
        category: _shapeData(
          types.find(t => t.value === item.category) &&
            types.find(t => t.value === item.category).label,
        ),
        file_type: _shapeData(item.file_type),
        file_size: _shapeData(formatBytes(item.file_size)),
        updated_date: _shapeData(
          formatDate(moment(item.updated_date), FORMAT_HUMANE_DATETIME),
        ),
        download: {
          value: (
            <DownloadWrapper onClick={() => this.downloadFile(item._id)}>
              <Icon size="l" name="download" />
            </DownloadWrapper>
          ),
        },
      });
      return acumulator;
    }, []);
    return result;
  }

  async loadSearchResult(value) {
    this.setState({ loading: true });
    const { loadResults } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
        lastSearchValue: value.toLowerCase(),
      });
    } else {
      const newData = await this.fetchData(
        value !== '' ? { q: value.toLowerCase() } : {},
      );
      loadResults[value] = newData;
      this.setState({ loadResults, lastSearchValue: value.toLowerCase() });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { checkedRows, types, commonMessage } = this.state;

    return (
      <ControllBar
        onChange={val => this.loadSearchResult(val)}
        history={history}
        name={__('Grafika')}
      >
        <ControlButton name={commonMessage.label}>
          <LinkWrap>
            {types.map(c => {
              return (
                <div onClick={() => this.handleCommonMessage(c.value, c.label)}>
                  {c.label}
                </div>
              );
            })}
          </LinkWrap>
        </ControlButton>
        {checkedRows && checkedRows.length ? (
          <ControlBarButton
            primary
            small
            loading={this.state.downloadSelectedLoading}
            disabled={this.state.downloadSelectedLoading}
            onClick={() =>
              !this.state.downloadSelectedLoading && this.downloadSelected()
            }
            icon="download"
          >
            {`${__('Stiahnúť označené')} (${checkedRows.length})`}
          </ControlBarButton>
        ) : (
          ''
        )}
        <ControlBarButton
          primary
          small
          onClick={() => this.handleSystemOnClick('new')}
          icon="plus"
        >
          {__('Pridať grafiku')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const { showDownloadDialog, downloadLoading } = this.state;

    return (
      <React.Fragment>
        <PopUpWrapper
          display={showDownloadDialog}
          small
          message={__('Sťiahnúť súbor(y)')}
          onClose={() => this.setState({ showDownloadDialog: false })}
        >
          <ConfirmDialog
            message={__('Želáte si stiahnúť zvolení/é súbor(y) ?')}
            onDismiss={() => this.setState({ showDownloadDialog: false })}
            onConfirm={() => this.downloadFileWithId()}
            loading={downloadLoading}
          />
        </PopUpWrapper>

        {super.render()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Graphics);
