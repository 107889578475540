/* eslint-disable react/no-unused-state */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Loader, Button } from 'oxyrion-ui/lib';
import Label from 'oxyrion-ui/lib/Label/Label';
import Icon from 'oxyrion-ui/lib/Icon';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import {
  AnimatedFormMessageWrapper,
  ButtonRelativeWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import { __ } from '../../Utils';
import SearchableSelect from '../../Components/SearchableSelect';
import AdminAPI from '../../AdminAPI';
import API2 from '../../API2';
import ReactDragListView from 'react-drag-listview';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
  /* overflow: auto; */
`;

const SearchableSelectWrapper = styled.div`
  width: ${rem(450)};
`;

const DragWrapper = styled.div`
  .drag_and_drop_wrapper {
    background: #f7f7f7;
    border-radius: ${rem(4)};
    padding: ${rem(8)};
    margin-bottom: ${rem(20)};
    overflow: auto;
  }

  .row {
    display: flex;
  }
`;

const Item = styled.div`
  border-radius: 4px;
  padding: ${rem(40)};
  background: white;
  margin-right: ${rem(12)};
  width: ${rem(150)};
  height: ${rem(150)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  position: relative;
  .image_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: ${rem(120)};
    max-height: ${rem(120)};
  }
  .product_image {
    max-width: ${rem(100)};
    max-height: ${rem(100)};
  }
  .product_name {
    margin-top: ${rem(12)};
    text-align: center;
  }

  .delete_wrapper {
    position: absolute;
    right: 10px;
    top: 10px;
    :hover {
      background: #eee;
    }
  }
`;

const baseURL = process.env.REACT_APP_IMAGES_BASE_PATH;

class FavoritProductsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  async componentDidMount() {
    try {
      const result = await AdminAPI.getFavoritProductsAction();

      this.setState({
        products: result.items.map((i) => ({
          value: i.catalog_product_id,
          label: i.name,
          image_src: i.image_src,
          id: i.order,
        })),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async saveData() {
    const { products } = this.state;

    this.setState({
      saveLoading: true,
    });

    try {
      const dataToSend = products.map((p, index) => ({
        catalog_product_id: p.value,
        order: index,
      }));

      const params = {};
      params.body = dataToSend;

      const result = await AdminAPI.putFavoritProductsAction(params);

      this.setState({
        saveLoading: false,
        success: __('Dáta úspešne uložené'),
        products: result.items.map((i) => ({
          value: i.catalog_product_id,
          label: i.name,
          image_src: i.image_src,
          id: i.order,
        })),
      });
    } catch (e) {
      this.setState({
        saveLoading: false,
        error: __('Dáta sa nepodarilo uložiť'),
      });
    }
  }

  async fetchProducts(query) {
    const { products } = this.state;

    try {
      const usedIds = products.map((p) => p.value);

      return API2.getUltraLiteCatalogProductsAction(this.props.firm, {
        q: query,
        limit: 20,
      }).then((res) => {
        return res.products
          .filter((item) => usedIds.indexOf(item._id) === -1)
          .map((item) => ({
            value: item._id,
            label: item.name,
            image_src: item.image_src,
          }));
      });
    } catch (e) {
      return [];
    }
  }

  handleDragEnd = (fromIndex, toIndex) => {
    const data = [...this.state.products];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);

    this.setState({
      products: data,
    });
  };

  deleteProduct(id) {
    let { products } = this.state;
    products = products.filter((p) => p.value !== id);
    this.setState({
      products,
    });
  }

  addProduct(e) {
    const { products } = this.state;

    products.push(
      Object.assign(e, {
        id: products.length,
      }),
    );

    this.setState({
      products,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    return <ControllBar history={history} name={__('Obľubené produkty')} />;
  }

  render() {
    const { error, success, loading, saveLoading, products } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>

        {this.renderControlBar()}

        <Wrapper>
          <AnimatedFormMessageWrapper display={products && products.length > 0}>
            <DragWrapper>
              <ReactDragListView
                lineClassName="drag_and_drop_wrapper"
                onDragEnd={this.handleDragEnd}
                nodeSelector={'div'}
                handleSelector={'.drag-handle'}
              >
                <div className="row">
                  {products.map((item, index) => (
                    <Item className="drag-handle" key={index}>
                      <div
                        className="delete_wrapper"
                        onClick={() => this.deleteProduct(item.value)}
                      >
                        <Icon name="close" size="l" />
                      </div>
                      <div className="image_wrapper">
                        <img
                          src={`${baseURL}/images/500x0/${item.image_src}`}
                          alt={item.label}
                          className="product_image"
                        />
                      </div>
                      <div className="product_name">{item.label}</div>
                    </Item>
                  ))}
                </div>
              </ReactDragListView>
            </DragWrapper>
          </AnimatedFormMessageWrapper>

          <SearchableSelectWrapper>
            <Label>{__('Vyhľadať a pridať produkt medzi obľubené')}</Label>
            <SearchableSelect
              loadOptions={(query) => {
                return this.fetchProducts(query);
              }}
              value={null}
              placeholder={__('Vyhľadať a pridať produkt medzi obľubené')}
              keyProps={JSON.stringify(products)}
              handleOnChange={(e) => this.addProduct(e)}
            />
          </SearchableSelectWrapper>

          <ButtonRelativeWrapper>
            <Button
              onClick={() => this.saveData()}
              primary
              loading={saveLoading}
            >
              {__('Uložiť')}
            </Button>
          </ButtonRelativeWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

FavoritProductsSettings.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FavoritProductsSettings);
