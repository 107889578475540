import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import 'react-dropzone-uploader/dist/styles.css';
import { rem } from 'polished';
import {
  Button,
  Message,
  Input,
  Label,
  Loader,
  Checkbox,
} from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  ButtonRelativeWrapper,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import PopUpWrapper from '../../Components/PopUpWrapper';
import LanguageSwitch from '../../Components/LanguageSwitch';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SettingsBox from '../../Components/SettingsBox';
import Dropzone from 'react-dropzone-uploader';
import Img from 'react-image';
import SearchableSelect from '../../Components/SearchableSelect';
import API2 from '../../API2';

const Wrapper = styled.div`
  padding: ${rem(15)};
`;

const ContentWrapper = styled.div`
  display: flex;
  padding-left: ${rem(20)};
  max-width: ${rem(500)};
  margin-bottom: ${rem(20)};
`;

const InputWrapper = styled.div`
  width: ${rem(500)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  // margin-right: ${rem(16)};
  display: flex;
`;

const StyledInput = styled(Input)`
  width: ${rem(330)};
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  // margin-right: 16px;
  align-items: center;
  justify-content: center;
`;

const Image = styled(Img)`
  max-width: ${rem(230)};
`;

const ImageRow = styled(Row)`
  border-bottom: ${rem(1)} solid #eee;
  padding: ${rem(12)};
`;

const COUNTRIES_TO_PUBLISH = ['SK', 'CZ', 'EN'];
const acceptedMimeTypesViewImage = ['image/*'];
const fileUploadURL = `${process.env.REACT_APP_ADMIN_DOMAIN}/admin/{companyId}/employers/{employerId}/image`;
class EmployerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saveLoading: false,
      success: false,
      activeLang: 'SK',
      data: {
        name: '',
        surname: '',
        phone_number: '',
        email: '',
        publish: [],
        profile_picture: {},
      },
      oxyrionUserSelect: null,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  getTranslatedData(data, lang) {
    if (!data) {
      return '';
    }

    const obj = data.find(d => d.lang === lang);
    return obj ? obj.value : '';
  }

  async loadData() {
    this.setState({
      loading: true,
    });

    const id = getIdFromProps(this.props);

    if (id !== 'new') {
      await Promise.all([this.fetchEmployerData(id)]);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  useQuery(props) {
    return queryString.parse(props.location.search);
  }

  async fetchEmployerData(id) {
    try {
      const data = await AdminAPI.getEmployerAction(this.props.firm, id);

      this.setState({
        data: Object.assign({ profile_picture: {} }, data),
      });
    } catch (e) {
      switch (e.response.status) {
        case 404:
          this.setState({
            error: __('Zamestnanec sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  checkData(data) {
    let isValid = true;

    if (!data.name || data.name.length === 0) {
      isValid = false;
    }

    if (!data.surname || data.surname.length === 0) {
      isValid = false;
    }

    if (!data.email || data.email.length === 0) {
      isValid = false;
    }

    if (!data.publish || data.publish.length === 0) {
      isValid = false;
    }

    if (!data.role || data.role.length === 0) {
      isValid = false;
    }

    return isValid;
  }

  async saveData(showSucces = true) {
    const { data, oxyrionUserSelect } = this.state;
    const { firm } = this.props;

    this.setState({
      saveLoading: true,
    });

    if (this.checkData(data)) {
      try {
        const params = {};
        params.body = Object.assign({
          name: data.name,
          surname: data.surname,
          email: data.email,
          phone_number: data.phone_number,
          publish: data.publish,
          role: data.role,
        });

        if (oxyrionUserSelect) {
          params.body.userId = oxyrionUserSelect.value;
        }

        if (getIdFromProps(this.props) === 'new') {
          const result = await AdminAPI.postEmployerAction(firm, params);
          this.props.history.push(`/${firm}/employers/${result._id}`);

          this.setState({
            success: showSucces && __('Dáta boli úspešne uložené'),
            saveLoading: false,
          });
        } else {
          const result = await AdminAPI.putEmployerAction(
            firm,
            getIdFromProps(this.props),
            params,
          );

          this.setState({
            data: result,
            success: showSucces && __('Dáta boli úspešne uložené'),
            saveLoading: false,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.setState({
          saveLoading: false,
        });
      }
    } else {
      this.setState({
        error: __('Nie sú vyplnené všetky potrebné hodnoty'),
        saveLoading: false,
      });
    }
  }

  getUploadParams = ({ file }) => {
    const headers = AdminAPI.appendAuthHeaders();

    const formData = new FormData();
    formData.append('image', file);

    return {
      url: fileUploadURL
        .replace('{companyId}', this.props.firm)
        .replace('{employerId}', this.state.data._id),
      headers: {
        Authorization: headers.get('Authorization'),
      },
      body: formData,
    };
  };

  handleChangeStatus = async ({ remove, restart, cancel }, status) => {
    if (status === 'done') {
      this.loadData();
    }
  };

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 15,
      }).then(res => {
        return res.users.map(item => ({
          value: item._id,
          label: item.display_name,
          raw: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  handleLocalChange(field, lang, value) {
    const { data } = this.state;

    if (!data[field]) {
      data[field] = [];
    }

    if (!data[field].find(d => d.lang === lang)) {
      data[field].push({
        lang,
        value,
      });
    } else {
      data[field].find(d => d.lang === lang).value = value;
    }

    this.setState({
      data,
      error: false,
    });
  }

  async handleChange(field, value) {
    const { data } = this.state;

    if (field === 'oxyrionUser') {
      this.setState({
        oxyrionUserSelect: value,
        showConfirmDialog: true,
      });
    } else if (field === 'publish') {
      if (data[field].includes(value)) {
        data[field].splice(data[field].indexOf(value), 1);
      } else {
        data[field].push(value);
      }

      data[field].find(p => p === value);
    } else {
      data[field] = value;
    }

    this.setState({ data, error: false });
  }

  handleChangeActiveLang(lang) {
    const { data } = this.state;

    this.setState({
      activeLang: lang,
      data,
    });

    return true;
  }

  rewriteData() {
    const { data, oxyrionUserSelect } = this.state;

    data.name = oxyrionUserSelect.raw.forename || '';
    data.surname = oxyrionUserSelect.raw.surname || '';
    data.phone_number = oxyrionUserSelect.raw.mobile || '';
    data.email = oxyrionUserSelect.raw.email || '';

    this.setState({
      data,
      showConfirmDialog: false,
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return (
      <ControllBar
        history={history}
        name={
          getIdFromProps(this.props) !== 'new'
            ? `${__('Detail zamestnanca')} ${data.name} ${data.surname}`
            : __('Detail nového zamestnanca')
        }
      />
    );
  }

  render() {
    const {
      loading,
      notValidData,
      deleteError,
      deleteLoading,
      showConfirmDialog,
      error,
      data,
      success,
      activeLang,
      uploading,
      saveLoading,
      oxyrionUserSelect,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showConfirmDialog}
          small
          onClose={() => this.setState({ showConfirmDialog: false })}
        >
          <ConfirmDialog
            message={__('Prajete vyplniť údaje zo zvoleného používateľa ?')}
            onDismiss={() => this.setState({ showConfirmDialog: false })}
            onConfirm={() => this.rewriteData()}
            error={deleteError}
            loading={deleteLoading}
          />
        </PopUpWrapper>

        <Wrapper>
          {notValidData && (
            <Message
              error
              message={__('Nie su vyplnené všetky potrebné hodnoty')}
            />
          )}
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát sa vyskytla chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
          {deleteError && <Message error message={deleteError} />}
        </Wrapper>

        <ContentWrapper>
          <Row>
            <SettingsBox addDisabled title={__('Základné informácie')}>
              <Row>
                <InputWrapper>
                  <StyledLabel>
                    {__('Vyhľadať oxyrion používateľa')}
                  </StyledLabel>
                  <SearchableSelect
                    value={oxyrionUserSelect}
                    loadOptions={query => this.fetchUsers(query)}
                    placeholder={__('Vyhľadať používateľa')}
                    handleOnChange={e => this.handleChange('oxyrionUser', e)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Meno*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.name}
                    placeholder={__('Meno')}
                    onChange={e => this.handleChange('name', e.target.value)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Priezvisko*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.surname}
                    placeholder={__('Priezvisko')}
                    onChange={e => this.handleChange('surname', e.target.value)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Telefónne číslo')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.phone_number}
                    placeholder={__('Telefónne číslo')}
                    onChange={e =>
                      this.handleChange('phone_number', e.target.value)
                    }
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Email*')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(430) }}
                    value={data.email}
                    placeholder={__('Email')}
                    onChange={e => this.handleChange('email', e.target.value)}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Krajiny pôsobenia*')}</StyledLabel>
                  <Row style={{ margin: 0 }}>
                    {COUNTRIES_TO_PUBLISH.map(c => (
                      <CheckboxWrapper>
                        <Checkbox
                          onChange={() => this.handleChange('publish', c)}
                          checked={
                            data.publish && data.publish
                              ? data.publish.includes(c)
                              : false
                          }
                        />
                        <Label>{c}</Label>
                        <Space />
                      </CheckboxWrapper>
                    ))}
                  </Row>
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Profilová fotka')}</StyledLabel>
                  <ImageRow>
                    {data && data.profile_picture && (
                      <Image
                        src={[
                          `${process.env.REACT_APP_DOMAIN2}/images/400x0/${data.profile_picture.fullPath}`,
                        ]}
                        loader={<Loader color="#70af00" />}
                      />
                    )}
                  </ImageRow>

                  <Dropzone
                    maxFiles={1}
                    disabled={getIdFromProps(this.props) === 'new'}
                    files={[data.profile_picture]}
                    getUploadParams={e => this.getUploadParams(e)}
                    onChangeStatus={this.handleChangeStatus}
                    accept={acceptedMimeTypesViewImage.join(',')}
                    inputContent={__('Nahrať obrázok')}
                    canCancel={false}
                    initialFiles={[data.profile_picture]}
                    styles={{
                      inputLabel: { color: '#ee6500' },
                    }}
                  />
                </InputWrapper>
              </Row>
            </SettingsBox>
            <Space />
          </Row>
          <div>
            <Row>
              <LanguageSwitch
                disabled={uploading}
                onChange={lang => this.handleChangeActiveLang(lang)}
                activeId={activeLang}
                values={COUNTRIES_TO_PUBLISH}
              />
            </Row>

            <Row>
              <SettingsBox addDisabled>
                <Row>
                  <InputWrapper>
                    <StyledLabel>{__('Názov funkcie*')}</StyledLabel>

                    <StyledInput
                      style={{ width: rem(430) }}
                      value={this.getTranslatedData(data.role, activeLang)}
                      placeholder={__('Názov funkcie (role)')}
                      onChange={e =>
                        this.handleLocalChange(
                          'role',
                          activeLang,
                          e.target.value,
                        )
                      }
                    />
                  </InputWrapper>
                </Row>
              </SettingsBox>
              <Space />
            </Row>
          </div>
        </ContentWrapper>

        <ButtonRelativeWrapper>
          <Button
            loading={saveLoading}
            onClick={() => this.saveData(true)}
            primary
          >
            {__('Uložiť')}
          </Button>
        </ButtonRelativeWrapper>
      </React.Fragment>
    );
  }
}

EmployerDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EmployerDetail);
