import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import TurnoverDiscounts from '../../../Containers/TurnoverDiscounts';

function TurnoverDiscountsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={TurnoverDiscounts} />
    </Switch>
  );
}

TurnoverDiscountsCard.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default TurnoverDiscountsCard;
